import React from "react"
import AImage from "../atoms/a-image"

const OImageBlock = props => {
  const aspectRatio = props.data.aspect_ratio || "16:9"
  const cssAspectRatio = aspectRatio.replace(":", "x")

  if (props.data.image_size === "m") {
    return (
      <section className="ef-content__block ef-content__block--image">
        <div className="ef-container ef-container--l">
          <div className="ef-container__narrow-m">
            <div className={`ef-media ef-media--${cssAspectRatio}`}>
              <AImage
                image={props.data.image}
                image_size={props.data.image_size}
                aspectRatio={aspectRatio}
              />
            </div>
          </div>
          {props.data.image.description && (
            <div className="ef-caption">{props.data.image.description}</div>
          )}
        </div>
      </section>
    )
  } else {
    return (
      <section className="ef-content__block ef-content__block--image">
        <div className="ef-container ef-container--l">
          <div className={`ef-media ef-media--${cssAspectRatio}`}>
            <AImage
              image={props.data.image}
              image_size={props.data.image_size}
              aspectRatio={aspectRatio}
            />
          </div>
          {props.data.image.description && (
            <div className="ef-caption">{props.data.image.description}</div>
          )}
        </div>
      </section>
    )
  }
}

export default OImageBlock
