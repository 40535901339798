import React from "react"
import { Link } from "gatsby"

import Slider from "react-slick"

import moment from "moment"

const OPerpetualCalendarSliderBlock = props => {
  let currentDayOfMonth = parseInt(props["currentDay"])
  let currentMonth = parseInt(props["currentMonth"]) - 1
  let base_path = props["base_path"]

  const months = moment.months()
  let numberOfDays = props["numberOfDays"]

  let allDays = []
  for (const [index] of months.entries()) {
    let children = []
    for (let i = 0; i < numberOfDays[index]; i++) {
      if (index === currentMonth && i + 1 === currentDayOfMonth) {
        children.push(
          <Link
            className="ef-perpetual-calendar-card__day --is-current"
            key={"link" + (currentMonth + 1) + "-" + (i + 1)}
            to={base_path + (index + 1) + "/" + (i + 1)}
          >
            {i + 1}
          </Link>
        )
      } else {
        children.push(
          <Link
            className="ef-perpetual-calendar-card__day"
            key={"link" + (currentMonth + 1) + "-" + (i + 1)}
            to={base_path + (index + 1) + "/" + (i + 1)}
          >
            {i + 1}
          </Link>
        )
      }
    }
    allDays.push(children)
  }

  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }
  const settings = {
    initialSlide: currentMonth,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <section className="ef-content__block ef-content__block--perpetual-calendar-slider">
      <Slider {...settings} className="ef-slider ef-perpetual-calendar-slider">
        {months.map((month, index) => (
          <div
            className="ef-slide ef-slide--gap-m"
            key={"ef-slide-" + month + "-" + index}
          >
            <div className="ef-perpetual-calendar-card">
              <header className="ef-perpetual-calendar-card__header">
                <h5 className="ef-heading ef-heading--5">{month}</h5>
              </header>
              <div className="ef-perpetual-calendar-card__days">
                {allDays[index]}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default OPerpetualCalendarSliderBlock
