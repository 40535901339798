import React from "react"

const OJobfinderBlock = () => {
  return (
    <section className="ef-content__block ef-content__block--jobfinder">
      <div className="ef-container ef-container--l">
        <div className="ef-jobfinder" data-label="Partner">
          <header className="ef-jobfinder__header">
            <div className="ef-jobfinder__header-logo">
              <a
                style={{ display: "block" }}
                href="http://www3.smartadserver.com/h/cc?imgid=25087137&tmstp=[timestamp]&tgt=[reference]"
                target="_blank"
                rel="noopener noreferrer"
                title="Jobsuche"
              >
                <svg
                  className="ef-jobfinder__header-logo-image"
                  viewBox="0 0 1009.39 392.4"
                >
                  <use xlinkHref="#ef-partner-indeed" />
                </svg>
              </a>
              <img
                style={{ width: "1px", height: "1px", opacity: 0 }}
                src="http://www3.smartadserver.com/imp?imgid=25087137&tmstp=[timestamp]&tgt=[targeting]"
              />
            </div>
          </header>
          <form
            className="ef-jobfinder__form"
            action="https://de.indeed.com/Jobs"
            target="_blank"
            rel="noopener noreferrer"
            method="GET"
          >
            <input type="hidden" name="indpubnum" value="8447920473197875" />
            <div className="ef-jobfinder__form-item">
              <label className="ef-jobfinder__form-item-label">
                <div className="ef-jobfinder__form-item-label-text">was</div>
                <div className="ef-jobfinder__form-item-label-subline">
                  Stichwort, Jobtitel oder Unternehmen
                </div>
              </label>
              <input
                className="ef-jobfinder__form-item-field ef-jobfinder__form-item-field--job"
                type="text"
                placeholder=""
                name="q"
              />
              <div className="ef-jobfinder__form-icon">
                <svg className="ef-icon" width={16} height={16}>
                  <use xlinkHref="#ef-icon--search-16" />
                </svg>
              </div>
            </div>
            <div className="ef-jobfinder__form-item">
              <label className="ef-jobfinder__form-item-label">
                <div className="ef-jobfinder__form-item-label-text">wo</div>
                <div className="ef-jobfinder__form-item-label-subline">
                  Ort, Bundesland oder Postleitzahl
                </div>
              </label>
              <input
                className="ef-jobfinder__form-item-field ef-jobfinder__form-item-field--city"
                type="text"
                placeholder="Frankfurt am Main"
                name="l"
              />
              <div className="ef-jobfinder__form-icon">
                <svg className="ef-icon" width={16} height={16}>
                  <use xlinkHref="#ef-icon--pin-16" />
                </svg>
              </div>
            </div>
            <div className="ef-jobfinder__cta">
              <input
                className="ef-jobfinder__cta-button"
                type="submit"
                value="Jobs Finden"
                onClick={
                  'ga("send","event","button","click","Jobs_finden_mit_indeed",{ transport: "beacon" });'
                }
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default OJobfinderBlock
