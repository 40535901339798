// import { Link } from "gatsby"
import React from "react"
import Blocks from "../_blocks.js"

const TContentBody = ({ content }) => {
  if (!content || !content.body) {
    return <></>
  }

  return content.body.map(block =>
    Blocks[block.component]
      ? React.createElement(Blocks[block.component], {
          key: block.uid,
          data: block,
          pageData: {},
        })
      : ""
  )
}

export default TContentBody
