import React from "react"

const AMatchHeaderExpansionToggle = props => {
  return (
    <div
      className="ef-match-header__expansion-toggle"
      onClick={() => props.setIsOpen(!props.isOpen)}
    >
      <div className="ef-match-header__expansion-toggle-button">
        <svg className="ef-icon" width="12" height="12">
          <use
            href="#ef-icon--chevron-down-12"
            xlinkHref="#ef-icon--chevron-down-12"
          ></use>
        </svg>
      </div>
    </div>
  )
}

export default AMatchHeaderExpansionToggle
