import React from "react"

const OExpansionBlock = props => {
  function CloseExpansion(e) {
    e.preventDefault()
    let triggers = document.querySelectorAll("[data-expansion-name]")
    triggers.forEach(function(item) {
      item.classList.remove("--is-active")
    })
    let targets = document.querySelectorAll("[data-expansion]")
    targets.forEach(function(item) {
      item.classList.remove("--is-expanded")
    })
  }
  return (
    <section className="ef-content__expansion">
      <div className="ef-content__expansion-close" onClick={CloseExpansion}>
        <svg className="ef-icon" width={12} height={12}>
          <use xlinkHref="#ef-icon--x-12" />
        </svg>
      </div>
      {props.data.card_items.map((item, index) => (
        <div
          className="ef-content__expansion-item"
          data-expansion={props.data.uid + "-" + index}
          key={index}
        >
          <div className="ef-content__expansion-content">
            <div
              className="ef-copy-block"
              dangerouslySetInnerHTML={{ __html: item.expansion_text }}
            ></div>
          </div>
        </div>
      ))}
    </section>
  )
}

export default OExpansionBlock
