import React from "react"
import { Link } from "gatsby"

import { concatStrings } from "../../utilities/utilities"
import { navigate } from "@reach/router"

const OSubmenu = ({
  subnavigation,
  subnavigationDropdown1 = [],
  subnavigationDropdown2 = [],
}) => {
  function gotoPage(e) {
    navigate(e.target.value)
  }

  return (
    <nav className={concatStrings("ef-submenu", "--has-filters")}>
      <div className="ef-container ef-container--l">
        <ul className="ef-submenu__districts">
          {subnavigation.map((item, index) => (
            <li
              className={concatStrings(
                "ef-submenu__district",
                index === 0 && "ef-submenu__district--main"
              )}
              key={index}
            >
              <Link
                className="ef-submenu__link"
                to={item.full_slug}
                activeClassName="--is-current"
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>

        <div className="ef-submenu__filters">
          {subnavigationDropdown1 && subnavigationDropdown1.length > 0 && (
            <div className="ef-filter">
              <div className="ais-MenuSelect">
                <select className="ais-MenuSelect-select" onChange={gotoPage}>
                  {subnavigationDropdown1.map((sub1, index) => (
                    <option
                      key={index}
                      className="ais-MenuSelect-option"
                      value={sub1.link}
                      selected={sub1.active === true}
                    >
                      {sub1.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {subnavigationDropdown2 && subnavigationDropdown2.length > 0 && (
            <div className="ef-filter">
              <div className="ais-MenuSelect">
                <select className="ais-MenuSelect-select" onChange={gotoPage}>
                  {subnavigationDropdown2.map((sub2, index) => (
                    <option
                      key={index}
                      className="ais-MenuSelect-option"
                      value={sub2.link}
                      selected={sub2.active === true}
                    >
                      {sub2.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default OSubmenu
