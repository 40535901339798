import React from "react"
import { Link } from "gatsby"

const MBreadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="ef-breadcrumbs ef-header__breadcrumbs">
      {breadcrumbs.map((item, index) => (
        <Link className="ef-breadcrumb" to={item.full_slug} key={index}>
          {item.name}
        </Link>
      ))}
    </div>
  )
}

export default MBreadcrumbs
