import React from "react"

const AChartDonut = ({
  dashArray1,
  dashArray2 = false,
  dashArray3 = false,
  strokeWidth1 = false,
  strokeWidth2 = false,
  strokeWidth3 = false,
  subclass1 = "secondary",
  subclass2 = "primary",
  subclass3 = "",
  subclass4 = "",
}) => {
  return (
    <div className="ef-player-stats__chart">
      <svg
        className="ef-donut"
        viewBox="0 0 33.83098862 33.83098862"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={"ef-donut__circle ef-donut__circle--" + subclass1}
          strokeWidth={4}
          strokeDasharray="100,100"
          fill="none"
          cx={16.91549431}
          cy={16.91549431}
          r={15.91549431}
        ></circle>
        <circle
          className={"ef-donut__circle ef-donut__circle--" + subclass2}
          strokeWidth={strokeWidth1 ? strokeWidth1 : 4}
          strokeDasharray={dashArray1}
          fill="none"
          cx={16.91549431}
          cy={16.91549431}
          r={15.91549431}
        ></circle>
        {dashArray2 && (
          <circle
            className={"ef-donut__circle ef-donut__circle--" + subclass3}
            strokeWidth={strokeWidth2 ? strokeWidth2 : 4}
            strokeDasharray={dashArray2}
            fill="none"
            cx={16.91549431}
            cy={16.91549431}
            r={15.91549431}
          ></circle>
        )}
        {dashArray3 && (
          <circle
            className={"ef-donut__circle ef-donut__circle--" + subclass4}
            strokeWidth={strokeWidth3 ? strokeWidth3 : 4}
            strokeDasharray={dashArray3}
            fill="none"
            cx={16.91549431}
            cy={16.91549431}
            r={15.91549431}
          ></circle>
        )}
      </svg>
    </div>
  )
}

export default AChartDonut
