import React from "react"

const OEmbedHtmlBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--embed">
      <div className="ef-container ef-container--l">
        <div
          className="ef-embed"
          dangerouslySetInnerHTML={{ __html: props.data.html }}
        ></div>
      </div>
    </section>
  )
}

export default OEmbedHtmlBlock
