import React, { useState } from "react"
import AHeading from "../atoms/a-heading"

import Blocks from "../_blocks"

const OAccordionBlock = props => {
  const [activeAccordionTab, setActiveAccordionTab] = useState(0)

  return (
    <section className="ef-content__block ef-content__block--accordion">
      <div className="ef-container ef-container--l">
        <AHeading headline_level="h3" headline={props.data.headline} />
        {props.data.accordion_items.map((item, index) => (
          <div
            className="ef-accordion"
            aria-expanded={activeAccordionTab === index + 1 ? "true" : "false"}
            data-multiselect="disabled"
            key={index}
            onClick={() =>
              setActiveAccordionTab(
                activeAccordionTab !== index + 1 ? index + 1 : 0
              )
            }
          >
            <header className="ef-accordion__header">
              <AHeading
                headline_level="h4"
                headline={item.headline}
                add_class="ef-accordion__heading"
              />
            </header>
            <div
              className="ef-accordion__container"
              style={{
                height: activeAccordionTab === index + 1 ? "100%" : "",
              }}
            >
              <div className="ef-accordion__body">
                {item.content &&
                  item.content.map &&
                  item.content.map(block =>
                    Blocks[block.component]
                      ? React.createElement(Blocks[block.component], {
                          key: index,
                          data: block,
                          pageData: {},
                        })
                      : `Block ${block.component} in ${block.uid} not created yet`
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default OAccordionBlock
