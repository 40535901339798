import React from "react"
//import { Link } from "gatsby"

import FooterParts from "../_footer-parts"

const OFooter = props => {
  return (
    <footer className="ef-footer">
      <div className="ef-container ef-container--l">
        {props.content &&
          props.content.body &&
          props.content.body.map &&
          props.content.body
            // Begin Workaround for Typo3 Bug
            // Remove duplicate elements from "insert records"
            .filter(
              (component, index, self) =>
                index === self.findIndex(c => c.uid === component.uid)
            )
            // End
            .map(footerpart =>
              FooterParts[footerpart.component]
                ? React.createElement(FooterParts[footerpart.component], {
                    key: footerpart.uid,
                    data: footerpart,
                    website_theme: props.website_theme,
                    pageData: {},
                  })
                : `Footerpart ${footerpart.component} in ${footerpart.uid} not created yet`
            )}
      </div>
    </footer>
  )
}

export default OFooter
