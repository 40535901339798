import React from "react"
import OFullscreenHeroBlock from "./o-fullscreen-hero-block"
import OFullscreenVideoHeroBlock from "./o-fullscreen-video-hero-block"
import OArticleSliderBlock from "./o-article-slider-block"
import ONewsflashSliderBlock from "./o-newsflash-slider-block"

const OSelectionCollectionBlock = props => {
  let additionalLinks = []
  if (
    props.data.hero_link_text_1 &&
    props.data.hero_link_1 &&
    props.data.hero_link_1.url
  ) {
    additionalLinks.push({
      link: props.data.hero_link_1,
      link_text: props.data.hero_link_text_1,
    })
  }
  if (
    props.data.hero_link_text_2 &&
    props.data.hero_link_2 &&
    props.data.hero_link_2.url
  ) {
    additionalLinks.push({
      link: props.data.hero_link_2,
      link_text: props.data.hero_link_text_2,
    })
  }
  if (
    props.data.hero_link_text_3 &&
    props.data.hero_link_3 &&
    props.data.hero_link_3.url
  ) {
    additionalLinks.push({
      link: props.data.hero_link_3,
      link_text: props.data.hero_link_text_3,
    })
  }

  return (
    <>
      {props.data.plugin_component === "plugin_selection_hero" &&
        props.data.card_items[0] &&
        props.data.card_items[0].type === "video_page" && (
          <OFullscreenVideoHeroBlock
            data={{
              kicker: props.data.card_items[0].kicker,
              headline: props.data.card_items[0].headline,
              image: props.data.card_items[0].image,
              intro: props.data.card_items[0].subline,
              main_link: props.data.card_items[0].link,
              links: additionalLinks,
            }}
            item={{
              videoUID: props.data.card_items[0].videoUID,
            }}
          ></OFullscreenVideoHeroBlock>
        )}
      {props.data.plugin_component === "plugin_selection_hero" &&
        props.data.card_items[0] &&
        props.data.card_items[0].type !== "video_page" && (
          <OFullscreenHeroBlock
            data={{
              kicker: props.data.card_items[0].kicker,
              headline: props.data.card_items[0].headline,
              image: props.data.card_items[0].image,
              intro: props.data.card_items[0].subline,
              main_link: props.data.card_items[0].link,
              links: additionalLinks,
            }}
          ></OFullscreenHeroBlock>
        )}
      {props.data.selection_collection_layout_type === "hero" &&
        props.data.card_items[0] &&
        props.data.card_items[0].type === "video_page" && (
          <OFullscreenVideoHeroBlock
            data={{
              kicker: props.data.card_items[0].kicker,
              headline: props.data.card_items[0].headline,
              image: props.data.card_items[0].image,
              intro: props.data.card_items[0].subline,
              main_link: props.data.card_items[0].link,
              links: additionalLinks,
            }}
            item={{
              videoUID: props.data.card_items[0].videoUID,
            }}
          ></OFullscreenVideoHeroBlock>
        )}
      {props.data.selection_collection_layout_type === "hero" &&
        props.data.card_items[0] &&
        props.data.card_items[0].type !== "video_page" && (
          <OFullscreenHeroBlock
            data={{
              kicker: props.data.card_items[0].kicker,
              headline: props.data.card_items[0].headline,
              image: props.data.card_items[0].image,
              intro: props.data.card_items[0].subline,
              main_link: props.data.card_items[0].link,
            }}
          ></OFullscreenHeroBlock>
        )}
      {props.data.selection_collection_layout_type === "image-text-slider" && (
        <OArticleSliderBlock
          data={{ card_items: props.data.card_items }}
          card_content_type="article_card"
          uid={props.data.uid || null}
        ></OArticleSliderBlock>
      )}
      {props.data.selection_collection_layout_type === "text-slider" && (
        <ONewsflashSliderBlock
          data={{ card_items: props.data.card_items }}
          card_content_type="kicker_headline_card"
          expansion={false}
        ></ONewsflashSliderBlock>
      )}
    </>
  )
}

export default OSelectionCollectionBlock
