import React from "react"

import AImage from "../atoms/a-image"
import AHeading from "../atoms/a-heading"
import ALink from "../atoms/a-link"

import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "gatsby-component-lib/utilities/date-time-formats"

const MGalleryCard = props => {
  return (
    <ALink
      className="ef-article-card ef-article-card--gallery js-modal-trigger"
      link={props.item.link ? props.item.link : ""}
      data-modal={1}
    >
      <div className="ef-media ef-article-card__media">
        {props.item.image && (
          <AImage
            add_class="ef-article-card__image"
            image_size="card_l"
            image={props.item.image}
            useDefault="true"
          />
        )}
        {props.item.hover_image && props.item.hover_image.src && (
          <AImage
            add_class="ef-article-card__image"
            image_size="card_l"
            image={props.item.hover_image}
          />
        )}
      </div>
      <div className="ef-article-card__text">
        {props.item.kicker && (
          <div className="ef-kicker">
            <Moment format={DATE_TIME_FORMATS.default}>
              {props.item.displayDatetime}
            </Moment>
            &nbsp;
            {props.item.kicker && "/ " + props.item.kicker}
          </div>
        )}
        {props.item.headline && (
          <AHeading
            headline_level="h3"
            headline={props.item.headline}
            add_class="ef-heading--has-kicker ef-article-card__heading"
          />
        )}
        {props.item.subline && (
          <div
            className="ef-paragraph ef-text--s"
            dangerouslySetInnerHTML={{ __html: props.item.subline }}
          />
        )}
      </div>
    </ALink>
  )
}

export default MGalleryCard
