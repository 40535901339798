import React from "react"

const ODataTableBlock = props => {
  var table = []
  let spalten
  let reihe = props.data.text.split("##NL##")
  for (let i = 0; i < reihe.length; i++) {
    spalten = reihe[i].split("|")
    table.push(spalten)
  }

  let container_size = "ef-container--" + props.data.table_size
  return (
    <section className="ef-content__block ef-content__block--data-table">
      <div className={"ef-container " + container_size}>
        <table className="ef-data-table --from-desktop">
          <thead className="ef-data-table__header">
            {table.map(
              (item, index) =>
                index === 0 && (
                  <tr className="ef-data-table__header-row" key={index}>
                    {item.map((item, index) => (
                      <th className="ef-data-table__header-cell" key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                )
            )}
          </thead>
          <tbody className="ef-data-table__body">
            {table.map(
              (item, index) =>
                index > 0 && (
                  <tr className="ef-data-table__row" key={index}>
                    {item.map((item, index) => (
                      <td
                        className="ef-data-table__cell"
                        data-name={table[0][index]}
                        key={index}
                      >
                        {item}
                      </td>
                    ))}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default ODataTableBlock
