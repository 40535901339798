import React from "react"

const OEmbedBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--embed">
      <div className="ef-container ef-container--m">
        <div
          className="ef-embed"
          dangerouslySetInnerHTML={{ __html: props.data.embed_code }}
        ></div>
      </div>
    </section>
  )
}

export default OEmbedBlock
