import React, { useContext } from "react"
import { Link } from "gatsby"

import { concatStrings } from "../../utilities/utilities"
import { navigate } from "@reach/router"
import { LiveMatchContext } from "../../context/LiveMatchContext"

const OSubmenuMatch = ({
  matchSubnavigation,
  matchData,
  subnavigationDropdown1 = [],
  subnavigationDropdown2 = [],
}) => {
  function gotoPage(e) {
    navigate(e.target.value)
  }

  const matchContext = useContext(LiveMatchContext)
  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    parseInt(matchData.id) === parseInt(matchContext.matchId)
  ) {
    matchData = matchContext.liveMatchData
  }

  const matchIsLive =
    matchContext && parseInt(matchData.id) === parseInt(matchContext.matchId)
      ? matchContext.matchIsLive
      : matchData.liveticker_active || false

  const hashtag = `#${matchData.team_home.team_initials_name}${matchData.team_away.team_initials_name}`

  return (
    <nav
      className={concatStrings(
        "ef-submenu",
        "--has-filters",
        matchIsLive ? "ef-submenu--live" : ""
      )}
    >
      <div className="ef-container ef-container--l">
        <ul className="ef-submenu__districts">
          <li className={"ef-submenu__district ef-submenu__district--main"}>
            <span className="ef-submenu__link">{hashtag}</span>
          </li>

          {matchSubnavigation.map((item, index) => (
            <li className={"ef-submenu__district"} key={index}>
              <Link
                className="ef-submenu__link"
                to={item.full_slug}
                activeClassName="--is-current"
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>

        <div className="ef-submenu__filters">
          {subnavigationDropdown1 && subnavigationDropdown1.length > 0 && (
            <div className="ef-filter">
              <div className="ais-MenuSelect">
                <select className="ais-MenuSelect-select" onChange={gotoPage}>
                  {subnavigationDropdown1.map((sub1, index) => (
                    <option
                      key={index}
                      className="ais-MenuSelect-option"
                      value={sub1.link}
                      selected={sub1.active === true}
                    >
                      {sub1.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {subnavigationDropdown2 && subnavigationDropdown2.length > 0 && (
            <div className="ef-filter">
              <div className="ais-MenuSelect">
                <select className="ais-MenuSelect-select" onChange={gotoPage}>
                  {subnavigationDropdown2.map((sub2, index) => (
                    <option
                      key={index}
                      className="ais-MenuSelect-option"
                      value={sub2.link}
                      selected={sub2.active === true}
                    >
                      {sub2.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default OSubmenuMatch
