import React from "react"
import sports from "../../utilities/sports"

import { getSiteUrl } from "../../../config/website-hosts/mapping"

import { useTranslation } from "react-i18next"
import ALink from "../atoms/a-link"

const OSportGridBlock = () => {
  const { t } = useTranslation()

  let activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"

  return (
    <>
      <header className="ef-content__header ef-content__header--spacing">
        <div className="ef-container ef-container--l">
          <div className="ef-kicker">
            <div className="ef-kicker__item">Eintracht Frankfurt e.V.</div>
          </div>
          <h1 className="ef-heading ef-heading--2 ef-heading--has-kicker">
            Ein Verein – über 50 Sportarten
          </h1>
        </div>
      </header>

      <section className="ef-content__block ef-content__block--sport-grid">
        <div className="ef-container ef-container--l">
          <div className="ef-card-grid ef-sport-grid">
            {sports.map((sport, index) => (
              <div className="ef-card-grid__item" key={index}>
                <ALink
                  className="ef-sport-card"
                  link={getSiteUrl(activeEnv, sport.website)}
                >
                  <div className="ef-sport-card__icon">
                    <svg className="ef-sport-card__icon-image">
                      <use xlinkHref={"#ef-sport-icon--" + sport.website} />
                    </svg>
                  </div>
                  <h5 className="ef-heading ef-heading--5 ef-sport-card__heading">
                    {sport.name}
                  </h5>
                </ALink>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default OSportGridBlock
