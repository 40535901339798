import React from "react"
import AHeading from "../atoms/a-heading"
import AImage from "../atoms/a-image"

const OHeroBlock = props => {
  return (
    <header className="ef-hero ef-article__header">
      <div className="ef-container ef-container--l">
        <div className="ef-hero__title">
          <div className="ef-container ef-container--l">
            {props.data.kicker && (
              <div className="ef-kicker">{props.data.kicker}</div>
            )}
            {props.data.headline && (
              <AHeading
                headline_level={
                  props.data.headline_level && props.data.headline_level
                    ? props.data.headline_level
                    : "h1"
                }
                headline={props.data.headline}
                add_class="ef-heading--has-kicker"
              />
            )}
            {props.data.subheadline && (
              <AHeading
                headline_level="h3"
                headline={props.data.subheadline}
                add_class="ef-heading--sub"
              />
            )}
          </div>
        </div>
        {props.data.image && (
          <div className="ef-hero__media">
            <div className="ef-media ef-media--16x9">
              <AImage image={props.data.image} image_size="xl" />
            </div>
          </div>
        )}
        <div className="ef-hero__intro">
          <div className="ef-container ef-container--l">
            <p className="ef-paragraph ef-paragraph--lead">
              {props.data.intro}
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

OHeroBlock.defaultProps = {
  data: {
    headlineType: "h1",
  },
}

export default OHeroBlock
