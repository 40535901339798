import { useTranslation } from "react-i18next"
import moment from "moment"
import React, { useState, useEffect } from "react"

const OCountdown = props => {
  const { t } = useTranslation()

  const matchData = props.matchData
  const matchKickoff = matchData.kickoff

  const [updated, setUpdated] = useState(false)
  const [timeDiff, setTimeDiff] = useState({ d: 0, h: 0, m: 0, s: 0 })

  const eventTime = moment(matchKickoff).unix()
  const currentTime = moment().unix()
  const diffTime = eventTime - currentTime

  let duration = moment.duration(diffTime * 1000, "milliseconds")
  let interval = 1000

  const isFuture = diffTime > 0

  function updateTimer() {
    if (isFuture) {
      duration = moment.duration(
        duration.asMilliseconds() - interval,
        "milliseconds"
      )
      let d = moment.duration(duration).days()
      let h = moment.duration(duration).hours()
      let m = moment.duration(duration).minutes()
      let s = moment.duration(duration).seconds()
      setTimeDiff({ d: d, h: h, m: m, s: s })
      setUpdated(true)
    }
  }

  useEffect(() => {
    if (isFuture) {
      setInterval(function() {
        updateTimer()
      }, interval)
      updateTimer()
    }
  }, [])

  if (!isFuture) {
    return <></>
  }

  return (
    <>
      {props.isBodyEmpty && (
        <section className="ef-content__block ef-content__block--countdown-backdrop">
          <div
            className="ef-countdown-backdrop"
            style={{
              backgroundImage:
                "url(https://mobil.express.de/image/32465006/max/1920/1080/b9deaba36f375267971afc1b9ac87ddf/Rk/eintracht-frankfurt-choreo-190503.jpg)",
            }}
          ></div>
        </section>
      )}
      {matchData.definite === true && (
        <section className="ef-content__block ef-content__block--countdown-m">
          <div className="ef-countdown ef-countdown--m">
            <div className="ef-countdown__unit ef-countdown__unit--days">
              <div className="ef-countdown__digits">
                {updated ? timeDiff.d : "-"}
              </div>
              <div className="ef-countdown__label">Tage</div>
            </div>
            <div className="ef-countdown__unit ef-countdown__unit--hours">
              <div className="ef-countdown__digits">
                {updated ? timeDiff.h : "-"}
              </div>
              <div className="ef-countdown__label">Stunden</div>
            </div>
            <div className="ef-countdown__unit ef-countdown__unit--minutes">
              <div className="ef-countdown__digits">
                {updated ? timeDiff.m : "-"}
              </div>
              <div className="ef-countdown__label">Minuten</div>
            </div>
            <div className="ef-countdown__unit ef-countdown__unit--seconds">
              <div className="ef-countdown__digits">
                {updated ? timeDiff.s : "-"}
              </div>
              <div className="ef-countdown__label">Sekunden</div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default OCountdown
