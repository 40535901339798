import React from "react"
import AHeading from "../atoms/a-heading"
import ALink from "../atoms/a-link"

const OSectionHeaderBlock = props => {
  return (
    <section
      className="ef-content__block ef-content__block--section-header"
      id="aktuelles"
    >
      <div className="ef-container ef-container--l">
        <div className="ef-section-header">
          {props.data.headline && (
            <AHeading
              headline_level="h2"
              headline={props.data.headline}
              add_class="ef-section-header__heading"
            />
          )}
          {props.data.link && props.data.link_text && (
            <div className="ef-section-header__more">
              <ALink
                link={props.data.link}
                className="ef-link-button ef-link-button--more ef-section-header__link-button"
              >
                {props.data.link_text}
              </ALink>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default OSectionHeaderBlock
