import React, { createContext, useContext, useEffect, useState } from "react"
import algoliasearch from "algoliasearch"
import { InstantSearch, Configure, connectMenu } from "react-instantsearch-core"

export const ALGOLIA_HITS_PER_PAGE = 10

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const searchClient = {
  search(requests) {
    // if no query, we return an emtpy result
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      })
    }
    return algoliaClient.search(requests)
  },
}

export const AlgoliaSearchContext = createContext({
  queryAccessor: null,
  baseUrl: process.env.GATSBY_WEBSITE_BASE_URL,
  website: null,
})

const VirtualMenu = connectMenu(() => null)

const getWebsiteFilterType = website => {
  if (website === "global") {
    return null
  }
  return website
}

const TAlgoliaSearch = ({
  children,
  website,
  lang,
  searchOptions,
  initialQuery,
  propagateQuery,
}) => {
  const algoliaSearchContext = useContext(AlgoliaSearchContext)
  const [query, setQuery] = useState(initialQuery)
  const queryAccessor = { query, setQuery }

  const options = {
    // defaults
    ...{
      query,
      filters: "",
      attributesToSnippet: ["content:40", "name"],
      facetingAfterDistinct: true,
    },
    ...(searchOptions || {}),
  }

  useEffect(() => {
    propagateQuery(query)
  }, [query])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
    >
      <Configure
        query={options.query}
        filters={options.filters}
        facetingAfterDistinct={options.facetingAfterDistinct}
        attributesToSnippet={options.attributesToSnippet}
        distinct
      />
      <VirtualMenu attribute="language" defaultRefinement={lang} />
      <VirtualMenu
        attribute="website"
        defaultRefinement={getWebsiteFilterType(website)}
      />
      <AlgoliaSearchContext.Provider
        value={{
          ...algoliaSearchContext,
          ...{ queryAccessor, website },
        }}
      >
        {children}
      </AlgoliaSearchContext.Provider>
    </InstantSearch>
  )
}

export default TAlgoliaSearch
