import React from "react"
import OLoggedInMenu from "./o-logged-In-menu"

const OLogin = ({
  visibleOverlayType,
  setVisibleOverlayType,
  loggedIn,
  userMenuVisible,
  setUserMenuVisible,
  userData,
}) => {
  return userMenuVisible ? (
    <div className="ef-login --is-active">
      <div
        className="ef-login__close"
        onClick={() => setUserMenuVisible(!userMenuVisible)}
      >
        <svg className="ef-icon" width={16} height={16}>
          <use xlinkHref="#ef-icon--x-16" />
        </svg>
      </div>
      <div
        className="ef-login__branding"
        style={{
          backgroundImage:
            'url("https://i3-img.p7s1.io/pis/ezone/97cfqgELB38wdEB0AB1fHPDQCtTDCJ4UYl_Ic-IXCoYylZ0mXar255psZunNxEf6nIbS_ptHLX5IFtTKiVdkmIxzXpYobtJ3eJld165bfGwr6wrYZgRrur7Alk6Akp6BF1ssyoVUF-oaCYTqMAE49T9ljhlo_YKeg5JHKCgN5l6SFV29tYLsffRjH8hOiGbo5aDdNm5zvZkffDAdo_Eus-VkAwyVgCGiOnOHBEeOHFi9mI2dE-bCYUe8AmVScK0DktPCkMaLzvcGQQwn9CATjvvfeCZZ/profile:mag-max-1280x722")',
        }}
      />
      <div className="ef-login__content">
        <OLoggedInMenu
          loggedIn={loggedIn}
          userData={userData}
          userMenuVisible={userMenuVisible}
          setUserMenuVisible={setUserMenuVisible}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default OLogin
