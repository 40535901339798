import React from "react"
import AImage from "../atoms/a-image"
import AHeading from "../atoms/a-heading"
import { concatStrings } from "../.."
import ALink from "../atoms/a-link"

const MMagazineCard = props => {
  return (
    <ALink
      className="ef-magazine-card"
      link={props.item.link ? props.item.link : ""}
    >
      <div className="ef-magazine-card__inner">
        {props.item.image && (
          <div className="ef-media ef-magazine-card__media">
            <AImage
              add_class="ef-media__image ef-magazine-card__image"
              image_size="card_l"
              aspectRatio="1:1"
              image={props.item.image}
              useDefault="true"
            />
          </div>
        )}
        <div className="ef-magazine-card__text">
          {props.item && props.item.kicker && (
            <div className="ef-kicker">{props.item.kicker}</div>
          )}
          {props.item && props.item.headline && (
            <AHeading
              headline_level="h3"
              headline={props.item.headline}
              add_class={concatStrings(
                props.item.kicker && "ef-heading--has-kicker",
                "ef-magazine-card__heading"
              )}
            />
          )}
          {props.item && props.item.subline && (
            <p className="ef-paragraph ef-text--s">{props.item.subline}</p>
          )}
        </div>
      </div>
    </ALink>
  )
}

export default MMagazineCard
