import React from "react"

import { concatStrings } from "../../utilities/utilities"

import AHeading from "../atoms/a-heading"
import ALink from "../atoms/a-link"

const MNewsflashKickerHeadlineSublineCard = props => {
  function OpenExpansion(e) {
    e.preventDefault()
    let triggers = document.querySelectorAll("[data-expansion-name]")
    triggers.forEach(function(item) {
      item.classList.remove("--is-active")
    })
    e.currentTarget.classList.toggle("--is-active")
    let targetData = e.currentTarget.getAttribute("data-expansion-name")
    let target = document.querySelector('[data-expansion="' + targetData + '"]')
    let targets = document.querySelectorAll("[data-expansion]")
    targets.forEach(function(item) {
      item.classList.remove("--is-expanded")
    })
    target.classList.toggle("--is-expanded")
  }

  if (
    props.item.link &&
    (props.expansion === false || props.expansion === undefined)
  ) {
    return (
      <ALink className="ef-newsflash-card" link={props.item.link}>
        <div className="ef-newsflash-card__text">
          {props.item.kicker && (
            <div className="ef-kicker">{props.item.kicker}</div>
          )}
          {props.item.headline && (
            <AHeading
              headline_level="h5"
              headline={props.item.headline}
              add_class={concatStrings(
                props.item.kicker && "ef-heading--has-kicker",
                "ef-newsflash-card__heading"
              )}
            />
          )}
          {props.item.subline && (
            <div
              className="ef-paragraph ef-text--s"
              dangerouslySetInnerHTML={{ __html: props.item.subline }}
            />
          )}
        </div>
      </ALink>
    )
  } else {
    return (
      <a
        className="ef-newsflash-card"
        href={
          props.item.link && props.expansion === false
            ? props.item.link.url
            : "/"
        }
        data-expansion-name={
          props.expansion === true ? props.uid + "-" + props.index : null
        }
        onClick={props.expansion === true ? OpenExpansion : undefined}
      >
        <div className="ef-newsflash-card__text">
          {props.item.kicker && (
            <div className="ef-kicker">{props.item.kicker}</div>
          )}
          {props.item.headline && (
            <AHeading
              headline_level="h5"
              headline={props.item.headline}
              add_class={concatStrings(
                props.item.kicker && "ef-heading--has-kicker",
                "ef-newsflash-card__heading"
              )}
            />
          )}
          {props.item.subline && (
            <div
              className="ef-paragraph ef-text--s"
              dangerouslySetInnerHTML={{ __html: props.item.subline }}
            />
          )}
        </div>
      </a>
    )
  }
}

export default MNewsflashKickerHeadlineSublineCard
