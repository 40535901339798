import React, { useContext } from "react"
import MSearchCard from "../molecules/m-search-card"
import { MenuSelect, Pagination, ScrollTo } from "react-instantsearch-dom"
import {
  connectHits,
  connectStats,
  connectMenu,
} from "react-instantsearch-core"
import { concatStrings } from "../.."
import {
  ALGOLIA_HITS_PER_PAGE,
  AlgoliaSearchContext,
} from "../technical/t-algolia-search"
import { LayoutContext } from "./o-layout"
import { useTranslation } from "react-i18next"

const Results = connectHits(({ hits }) => {
  return (
    <div className="ef-card-grid ef-search-grid">
      {hits.map(hit => (
        <div key={hit.objectID} className="ef-card-grid__item">
          <MSearchCard hit={hit} />
        </div>
      ))}
    </div>
  )
})

const WebsiteMenu = connectMenu(({ items, refine }) => {
  const { t } = useTranslation()

  return (
    <ul className="ais-Menu-list">
      <li
        className={concatStrings(
          "ais-Menu-item",
          !items.find(item => item.isRefined) && "ais-Menu-item--selected"
        )}
      >
        <a
          href="#"
          onClick={event => {
            event.preventDefault()
            refine("")
          }}
        >
          <span className="ais-Menu-label">{t("Websites.all")}</span>
        </a>
      </li>
      {items.map(item => (
        <li
          key={item.value}
          className={concatStrings(
            "ais-Menu-item",
            item.isRefined && "ais-Menu-item--selected"
          )}
        >
          <a
            className="ais-Menu-link"
            href="#"
            onClick={event => {
              event.preventDefault()
              refine(item.value)
            }}
          >
            <span className="ais-Menu-label">
              {t(`Websites.${item.label}`)}
            </span>
            <span className="ais-Menu-count">{item.count}</span>
          </a>
        </li>
      ))}
    </ul>
  )
})

const OSearchResults = ({ nbHits }) => {
  const { query } = useContext(AlgoliaSearchContext).queryAccessor
  const { isSearchPage } = useContext(LayoutContext)
  const { t } = useTranslation()

  return (
    <div
      className={concatStrings(
        "ef-search",
        (query || isSearchPage) && "--is-active"
      )}
      data-theme="light"
    >
      {query && nbHits > 0 && (
        <div className="ef-content__block ef-content__block--search-filters">
          <div className="ef-container ef-container--l">
            <WebsiteMenu attribute="website" />
          </div>
        </div>
      )}
      <section className="ef-content__block ef-content__block--search-results">
        <ScrollTo />
        <div className="ef-container ef-container--l">
          <div className="ef-container__narrow-m">
            <header className="ef-search-results">
              <p className="ef-search-results__amount">{nbHits} Ergebnisse</p>
              <div className="ef-search-results__filter">
                <div className="ef-filter">
                  <MenuSelect
                    attribute="type"
                    translations={{
                      seeAllOption: "Alle Formate",
                    }}
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(`SearchType.${item.label}`),
                      }))
                    }
                  />
                </div>
                {/*<div className="ef-filter">*/}
                {/*  <SearchFilterTimeframe />*/}
                {/*</div>*/}
              </div>
            </header>
            <Results />
            {nbHits > ALGOLIA_HITS_PER_PAGE ? (
              <div className="ef-pagination-wrapper ef-pagination-wrapper--search-results">
                <Pagination showLast={true} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default connectStats(OSearchResults)
