import React from "react"
import AImage from "../atoms/a-image"
import ALink from "../atoms/a-link"

const OFullscreenHeroBlock = props => {
  return (
    <header className="ef-fullscreen-hero">
      <div className="ef-fullscreen-hero__media">
        {props.data.image && (
          <AImage
            add_class="ef-fullscreen-hero__image"
            image_size="l"
            image={props.data.image}
            aspectRatio="16:7"
          />
        )}
      </div>
      <div className="ef-fullscreen-hero__content">
        {props.data.main_link && (
          <ALink
            className="ef-fullscreen-hero__main-link"
            link={props.data.main_link ? props.data.main_link : ""}
          ></ALink>
        )}
        <div className="ef-fullscreen-hero__title">
          <h1 className="ef-heading ef-heading--1 ef-heading--has-kicker ef-heading--on-image ef-fullscreen-hero__heading">
            {props.data.kicker && (
              <div className="ef-kicker ef-fullscreen-hero__kicker">
                {props.data.kicker}
              </div>
            )}
            {props.data.headline && (
              <div className="ef-fullscreen-hero__heading-text">
                {props.data.headline}
              </div>
            )}
          </h1>
        </div>
        <div className="ef-fullscreen-hero__text">
          {props.data.intro && (
            <p className="ef-paragraph ef-paragraph--lead ef-fullscreen-hero__paragraph">
              {props.data.intro}
            </p>
          )}
          {props.data.links && (
            <div className="ef-link-buttons">
              {props.data.links.map((item, index) => (
                <ALink
                  className="ef-link-button ef-link-button--more ef-fullscreen-hero__link-button"
                  link={item.link}
                  key={index}
                >
                  {item.link_text}
                </ALink>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default OFullscreenHeroBlock
