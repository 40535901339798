import React, { useContext } from "react"
import { LayoutContext } from "../../components-legacy/organisms/o-layout"
import { Link } from "gatsby"
import { getSiteUrl } from "../../../config/website-hosts/mapping"

const ALink = props => {
  const { website } = useContext(LayoutContext)
  let linkUrl = ""
  if (props.link) {
    if (typeof props.link === "object" && props.link.url) {
      if (props.link.website && props.link.website !== website) {
        let activeEnv =
          process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"
        linkUrl = getSiteUrl(activeEnv, props.link.website) + props.link.url
      } else {
        linkUrl = props.link.url
      }
    } else if (typeof props.link === "string") {
      linkUrl = props.link
    } else {
      linkUrl = ""
    }

    if (
      linkUrl.length < 1 ||
      linkUrl.indexOf("www") >= 0 ||
      linkUrl.indexOf("http") >= 0
    ) {
      return (
        <a
          className={props.className}
          href={linkUrl}
          target={props.target}
          rel={props.rel}
        >
          {props.children}
        </a>
      )
    } else {
      return (
        <Link className={props.className} to={linkUrl}>
          {props.children}
        </Link>
      )
    }
  } else {
    return <div className={props.className}>{props.children}</div>
  }
}

export default ALink
