import React from "react"

import { concatStrings } from "../../utilities/utilities"

import AImage from "../atoms/a-image"
import AHeading from "../atoms/a-heading"
import ALink from "../atoms/a-link"

const MNewsflashImageKickerHeadlineCard = props => {
  function OpenExpansion(e) {
    e.preventDefault()
    let triggers = document.querySelectorAll("[data-expansion-name]")
    triggers.forEach(function(item) {
      item.classList.remove("--is-active")
    })
    e.currentTarget.classList.toggle("--is-active")
    let targetData = e.currentTarget.getAttribute("data-expansion-name")
    let target = document.querySelector('[data-expansion="' + targetData + '"]')
    let targets = document.querySelectorAll("[data-expansion]")
    targets.forEach(function(item) {
      item.classList.remove("--is-expanded")
    })
    target.classList.toggle("--is-expanded")
  }

  if (
    props.item.link &&
    (props.expansion === false || props.expansion === undefined)
  ) {
    return (
      <ALink className="ef-newsflash-card" link={props.item.link}>
        {props.item.image && (
          <div className="ef-media ef-newsflash-card__media">
            <AImage
              add_class="ef-newsflash-card__image"
              image_size="card_l"
              image={props.item.image}
              useDefault="true"
            />
          </div>
        )}
        <div className="ef-newsflash-card__text">
          {props.item && props.item.kicker && (
            <div className="ef-kicker">{props.item.kicker}</div>
          )}
          {props.item && props.item.headline && (
            <AHeading
              headline_level="h5"
              headline={props.item.headline}
              add_class={concatStrings(
                props.item.kicker && "ef-heading--has-kicker",
                "ef-newsflash-card__heading"
              )}
            />
          )}
        </div>
      </ALink>
    )
  } else {
    return (
      <a
        className="ef-newsflash-card"
        href={
          props.item && props.item.link && props.expansion === false
            ? props.item.link.url
            : "/"
        }
        data-expansion-name={
          props.expansion === true ? props.uid + "-" + props.index : null
        }
        onClick={props.expansion === true ? OpenExpansion : undefined}
      >
        {props.item.image && (
          <div className="ef-media ef-newsflash-card__media">
            <AImage
              add_class="ef-newsflash-card__image"
              image_size="card_l"
              image={props.item.image}
              useDefault="true"
            />
          </div>
        )}
        <div className="ef-newsflash-card__text">
          {props.item && props.item.kicker && (
            <div className="ef-kicker">{props.item.kicker}</div>
          )}
          {props.item && props.item.headline && (
            <AHeading
              headline_level="h5"
              headline={props.item.headline}
              add_class={concatStrings(
                props.item.kicker && "ef-heading--has-kicker",
                "ef-newsflash-card__heading"
              )}
            />
          )}
        </div>
      </a>
    )
  }
}

export default MNewsflashImageKickerHeadlineCard
