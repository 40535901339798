import React from "react"
import ALink from "../atoms/a-link"

const OFooterCopyright = props => {
  return (
    <section className="ef-footer__section ef-footer__section--bar">
      <div className="ef-footer__bar">
        <nav className="ef-footer__menu">
          <div className="ef-footer__menu-item ef-footer__copyright">
            © {new Date().getFullYear()} {props.data.copyright_text}
          </div>
          {props.data.copyright_links.map((link, index) => (
            <div className="ef-footer__menu-item" key={index}>
              <ALink className="ef-footer__menu-link" link={link.link}>
                {link.text}
              </ALink>
            </div>
          ))}
        </nav>
        {props.data.languages && props.data.languages.length > 0 && (
          <nav className="ef-footer__languages">
            {props.data.languages.map((language, index) => (
              <div className="ef-footer__language" key={index}>
                <ALink
                  className="ef-footer__language-link"
                  link={language.link}
                >
                  {language.text}
                </ALink>
              </div>
            ))}
          </nav>
        )}
      </div>
    </section>
  )
}

export default OFooterCopyright
