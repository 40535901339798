import React from "react"
import { useTranslation } from "react-i18next"
import { eventType } from "../../utilities/utilities"

const OGoalHomeEntry = props => {
  return (
    <div className="ef-goal__home">
      <div className="ef-goal__name">
        {props.event.team === "team_home" ? props.event.person_name : ""}
      </div>
      <div className="ef-goal__time">
        {props.event.team === "team_home" ? props.event.time + "'" : ""}
      </div>
    </div>
  )
}

const OGoalAwayEntry = props => {
  return (
    <div className="ef-goal__away">
      <div className="ef-goal__time">
        {props.event.team === "team_away" ? props.event.time + "'" : ""}
      </div>
      <div className="ef-goal__name">
        {props.event.team === "team_away" ? props.event.person_name : ""}
      </div>
    </div>
  )
}

const OGoalsEntry = props => {
  const event = props.event

  return (
    <div key={props.key} className="ef-goal">
      <OGoalHomeEntry event={event} />
      <div className="ef-goal__result">
        {event.homeScore}:{event.awayScore}
      </div>
      <OGoalAwayEntry event={event} />
    </div>
  )
}

const OGoals = props => {
  const { t } = useTranslation()

  const match = props.match
  let homeScore = 0
  let awayScore = 0
  const goalEvents =
    match && match.opta_events
      ? match.opta_events
          .filter(event => event.event_type === eventType.GOAL)
          .sort((event1, event2) => event1.time - event2.time)
          .map(event => {
            event.team === "team_home" ? homeScore++ : awayScore++
            return { ...event, homeScore, awayScore }
          })
      : null

  if (!goalEvents) {
    return <></>
  }

  return (
    <div className="ef-goals">
      {goalEvents.map((event, index) => (
        <React.Fragment key={event.id || index}>
          <OGoalsEntry event={event} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default OGoals
