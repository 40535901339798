import React from "react"
import ALink from "../atoms/a-link"

const OCtaButtonBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--cta-button">
      <div className="ef-container ef-container--m">
        <div className="ef-call-to-action ef-call-to-action--center">
          <ALink
            href={props.data.link}
            className="ef-button ef-button--primary ef-button--large"
          >
            {props.data.link_text}
          </ALink>
        </div>
      </div>
    </section>
  )
}

export default OCtaButtonBlock
