import React from "react"
import { Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { AlgoliaSearchContext } from "../technical/t-algolia-search"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import ALink from "../../components-legacy/atoms/a-link"

const LinkContent = hit => {
  return (
    <div className="ef-search-card__content">
      <div className="ef-kicker ef-search-card__kicker">
        {hit.kicker.map((kicker, key) => {
          return (
            <span key={key} className="ef-kicker__item">
              {isNaN(kicker) ? (
                kicker
              ) : (
                <Moment format={DATE_TIME_FORMATS.default}>{kicker}</Moment>
              )}
            </span>
          )
        })}
      </div>
      <h4 className="ef-heading ef-heading--4 ef-heading--has-kicker ef-search-card__heading">
        <Snippet hit={hit} attribute="name" />
      </h4>
      <p className="ef-text--s ef-search-card__text">
        <Snippet hit={hit} attribute="content" />
      </p>
    </div>
  )
}

const MSearchCard = ({ hit }) => {
  return (
    <AlgoliaSearchContext.Consumer>
      {searchPageContext => {
        const baseUrl = searchPageContext.baseUrl.replace(
          "<WEBSITE>",
          hit.website
        )
        return (
          <div className="ef-search-card">
            <ALink
              className="ef-player-profile__social-link"
              link={{
                url: hit.full_slug,
                website: hit.website,
              }}
            >
              {LinkContent(hit)}
            </ALink>
          </div>
        )
      }}
    </AlgoliaSearchContext.Consumer>
  )
}

export default MSearchCard
