import React from "react"

const ORichtextBlock = ({ data }) => {
  return (
    <section className="ef-content__block ef-content__block--rich-text">
      <div className="ef-container ef-container--l">
        {/* prettier-ignore */}
        <div
          className="ef-copy-block"
          dangerouslySetInnerHTML={{ __html: data && data.text && data.text.replace(/\\"/g, '"') }}
        ></div>
      </div>
    </section>
  )
}

export default ORichtextBlock
