import Axios from "axios"
import React, { useContext, useEffect } from "react"
import { useCookies } from "react-cookie"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import { ScriptContext, ScriptServices } from "../technical/t-script-tags"
import MShare from "./m-share"

// import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"

export const SESSION_URL =
  "https://sso-functions.eintracht.de/.netlify/functions/video_token"


const MVideoDetailHeader = ({
  content,
  display_datetime,
  currentRootCategory,
  website,
}) => {
  const scriptContext = useContext(ScriptContext)

  const [cookies] = useCookies(["yum_jwt"])

  useEffect(() => {
    const [successPlayer, errorPlayer] = scriptContext[ScriptServices.AVP]
    if (successPlayer && !errorPlayer) {
      let headers = null
      if (cookies && cookies.yum_jwt) {
        headers = {
          headers: {
            Authorization: "Bearer " + cookies.yum_jwt,
          },
        }
        // setAuthenticated(true)
      }

      let config = {
        id: "playercontainer",
        configUrl: `https://eintracht-tv.spott2.sportradar.com/api/v2/content/${content.video_uid}/player-setting?portal=at&autoplay=true&enableProgressBar=true&enableTime=true&enableSeekForward=true&enableSeekBehind=true&language=de&showTitle=true`,
        allowDVR: true,
      }

      Axios.post(SESSION_URL, null, headers)
        .then(function(response) {
          const data = response.data

          const desiredEndTime = data.desired_end_time

          if (data.heartbeat === true) {
            config.heartbeat = {
              enabled: true,
              time: 300,
              url: data.heartbeat_url,
              method: "GET",
            }
          }

          // authorization for registered users
          if (headers) {
            config.streamAccessQueryParameters = `authorization_code=${data.jwt_token}`
          }

          // 30 seconds preview for anonymous
          if (desiredEndTime) {
            // desiredEndTime for pay-videos, only
            if( content.payment === "pay" ) {
              config.desiredEndTime = desiredEndTime
            }
            config.streamAccessQueryParameters = `authorization_code=${data.jwt_token}`
          }

          //eslint-disable-next-line no-undef
          new avvpl.setupPlayer(config)
        })
        .catch(error => {
          console.log("error", error)
        })
    }
  }, [scriptContext])

  return (
    <header className="ef-video-header">
      <div className="ef-container ef-container--l">
        <div className="ef-video-header__video">
          <div className="ef-media ef-media--16x9">
            <div id="playercontainer"></div>
          </div>
        </div>
        <div className="ef-video-header__text">
          <div className="ef-kicker ef-video-header__kicker">
            <div className="ef-kicker__item">
              <Moment format={DATE_TIME_FORMATS.default}>
                {display_datetime}
              </Moment>
            </div>
            <div className="ef-kicker__item">{currentRootCategory.title}</div>
          </div>
          <h1 className="ef-heading ef-heading--1 ef-video-header__heading">
            {content.title}
          </h1>
          <p className="ef-paragraph ef-paragraph--lead ef-video-header__description">
            {content.teaser}
          </p>
          <MShare title={content.headline} website={website} disabled="0" />
        </div>
      </div>
    </header>
  )
}

export default MVideoDetailHeader
