const DATE_TIME_FORMATS = {
  time: "HH:mm [Uhr]",
  default: "DD.MM.YYYY",
  date_w_day: "dd., D.M.YYYY",
  date_w_day_leading_zeros: "dd., DD.MM.YYYY",
  default_w_time: "DD.MM, HH:mm [Uhr]",
  extended: "dd., DD.MM.YYYY, HH:mm [Uhr]",
}

export { DATE_TIME_FORMATS }
