import { useLocation } from "@reach/router"
import React from "react"
import ALink from "../atoms/a-link"

export const USER_STATUS = {
  ANONYM: "anonym",
  LOGGED_IN: "logged_in",
}

const OHeaderAccount = props => {
  const location = useLocation()

  return (
    <>
      {props.loggedIn && (
        <div
          className="ef-header__account"
          onClick={() => props.setUserMenuVisible(!props.userMenuVisible)}
        >
          <svg className="ef-icon" width="16" height="16">
            <use href="#ef-icon--user-16" xlinkHref="#ef-icon--user-16"></use>
          </svg>
          <div className="ef-header__account-name">{props.data.title}</div>
        </div>
      )}
      {!props.loggedIn && (
        <div className="ef-header__login">
          <ALink link={`${props.data.login_url}?return_url=${location.href}`}>
            <div className="ef-button ef-button--secondary ef-button--small">
              Login
            </div>
          </ALink>
        </div>
      )}
    </>
  )
}

export default OHeaderAccount
