import React from "react"
import Moment from "react-moment"
import { peopleGrouper } from "../../utilities/utilities"
import ASocialLink from "../atoms/a-social-link"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"

import ALink from "../atoms/a-link"
import AImage from "../atoms/a-image"
import { useTranslation } from "react-i18next"

const OPlayerFullscreenHeroBlock = props => {
  const { t } = useTranslation()
  const heroBackground =
    "https://media.eintracht.de/image/upload/v1589802561/player-hero-bg-d1c9.jpg"
  const { person } = props
  return (
    <div className="ef-fullscreen-hero ef-fullscreen-hero--player">
      <div className="ef-fullscreen-hero__media">
        {person.hero_image && (
          <>
            <AImage
              add_class="ef-fullscreen-hero__image"
              defaultClass="false"
              image={{ src: heroBackground }}
            />
            <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--back-1" />
            <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--back-2" />
            <div className="ef-fullscreen-hero__foreground">
              <div className="ef-container ef-container--l">
                <AImage
                  add_class="ef-fullscreen-hero__foreground-image"
                  aspectRatio="1:1"
                  defaultClass="false"
                  image={{
                    alternative: person.title,
                    title: person.title,
                    src: person.hero_image,
                    focus_in_percent: {
                      left: 50,
                      top: 0,
                    },
                  }}
                />
              </div>
            </div>
            <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--front-1" />
            <div className="ef-fullscreen-hero__branding ef-fullscreen-hero__branding--front-2" />
          </>
        )}
      </div>
      <div className="ef-fullscreen-hero__content">
        <div className="ef-fullscreen-hero__title">
          <h1 className="ef-heading ef-heading--1 ef-heading--has-kicker ef-heading--on-image ef-fullscreen-hero__heading">
            <div className="ef-kicker ef-fullscreen-hero__kicker">
              {peopleGrouper(person.position)}
            </div>
            <div className="ef-fullscreen-hero__heading-text">
              <div className="ef-fullscreen-hero__player">
                {person.is_player && (
                  <>
                    <div className="ef-fullscreen-hero__player-number">
                      {person.jersey_number}
                    </div>
                    <div className="ef-fullscreen-hero__player-name">
                      {person.title}
                    </div>
                  </>
                )}
                {!person.is_player && <div className="">{person.title}</div>}
              </div>
            </div>
          </h1>
        </div>
        <div className="ef-fullscreen-hero__text">
          <div className="ef-player-profile">
            <div className="ef-player-profile__items">
              <div className="ef-player-profile__item">
                {person.country && (
                  <>
                    <strong>{t("Stats.Nationality")}: </strong>
                    <br />
                    {person.country}
                  </>
                )}
              </div>
              <div className="ef-player-profile__item">
                {person.birthday && (
                  <>
                    <strong>{t("Stats.Birthday")}: </strong>
                    <br />
                    <Moment format={DATE_TIME_FORMATS.default}>
                      {person.birthday}
                    </Moment>
                  </>
                )}
              </div>
              <div className="ef-player-profile__item">
                {person.height && (
                  <>
                    <strong>{t("Stats.Height")}: </strong>
                    <br />
                    {person.height / 100}m
                  </>
                )}
              </div>
              <div className="ef-player-profile__item">
                {person.ef_player_since && (
                  <>
                    <strong>{t("Stats.PlayerSince")}: </strong>
                    <br />
                    <Moment format={DATE_TIME_FORMATS.default}>
                      {person.ef_player_since}
                    </Moment>
                  </>
                )}
              </div>
              <div className="ef-player-profile__item">
                <div className="ef-player-profile__social-links">
                  {person.facebook_url && (
                    <ASocialLink url={person.facebook_url} name="facebook" />
                  )}

                  {person.instagram_url && (
                    <>
                      <ALink
                        className="ef-player-profile__social-link"
                        link={person.instagram_url}
                      >
                        <svg className="ef-icon" width={16} height={16}>
                          <use
                            xlinkHref="#ef-icon--instagram-16"
                            href="#ef-icon--instagram-16"
                          />
                        </svg>
                      </ALink>
                    </>
                  )}

                  {person.twitter_url && (
                    <>
                      <ALink
                        className="ef-player-profile__social-link"
                        link={person.twitter_url}
                      >
                        <svg className="ef-icon" width={16} height={16}>
                          <use
                            xlinkHref="#ef-icon--twitter-16"
                            href="#ef-icon--twitter-16"
                          />
                        </svg>
                      </ALink>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OPlayerFullscreenHeroBlock
