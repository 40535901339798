import React, { useContext } from "react"
import OMatchSliderBlock from "../organisms/o-match-slider-block"
import { LayoutContext } from "../organisms/o-layout"

const TEintrachtMatches = () => {
  const layoutContext = useContext(LayoutContext)

  if (!layoutContext.startPageData) {
    return null
  }

  const data = layoutContext.startPageData

  const matches =
    data.matches.edges.length > 0 ? data.matches.edges[0].node.data.matches : []

  const standingsLinkFullSlug =
    data.matches.edges.length > 0 ? data.matches.edges[0].node.full_slug : ""

  return (
    <OMatchSliderBlock
      data={{ matches: matches, standingsLinkFullSlug: standingsLinkFullSlug }}
    />
  )
}

export default TEintrachtMatches
