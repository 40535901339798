import React from "react"
import MPlayerCard from "../molecules/m-player-card"

// import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"
import { useTranslation } from "react-i18next"

const OSquadByPosition = ({ people = [], position = "" }) => {
  const { t } = useTranslation()
  if (people.length > 0) {
    return (
      <section className="ef-content__block ef-content__block--player-grid">
        <div className="ef-container ef-container--l">
          <h3 className="ef-heading ef-heading--3">{t(position)}</h3>
          <div className="ef-card-grid ef-player-grid">
            {people.map((person, index) => (
              <MPlayerCard person={person} key={index} />
            ))}
          </div>
        </div>
      </section>
    )
  } else {
    return ""
  }
}

export default OSquadByPosition
