import React, { useContext, useEffect } from "react"
import { ScriptContext, ScriptServices } from "../technical/t-script-tags"

const ABanner = ({ id }) => {
  const scriptContext = useContext(ScriptContext)

  useEffect(() => {
    // SAS
    const [successSAS, errorSAS] = scriptContext[ScriptServices.SAS]
    if (successSAS && !errorSAS) {
      window.sas.cmd.push(function() {
        window.sas.render(id)
      })
    }
  }, [scriptContext])

  return <div id={id} />
}

export default ABanner
