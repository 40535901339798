import moment from "moment"
import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import OMatchHeaderExpansion from "./o-match-header-expansion"
import { LiveMatchContext } from "gatsby-component-lib/context/LiveMatchContext"
import ALink from "../atoms/a-link"

const LIVETICKER_INTERVAL = process.env.GATSBY_LIVETICKER_POLLING_INTERVAL
const CIRCLE_SECONDS = LIVETICKER_INTERVAL / 1000

export const OMatchHeaderHOC = props => {
  return (
    <div className="ef-match-meta" data-theme="light">
      <OMatchHeader {...props} />
    </div>
  )
}

const OMatchHeader = props => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(props.expansionIsOpen || false)

  const matchContext = useContext(LiveMatchContext)

  let matchData = props.matchData || {}
  let matchIsLive = matchData.liveticker_active || false
  let showSkeleton = false

  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    (parseInt(matchData.id) === parseInt(matchContext.matchId) || !matchData.id)
  ) {
    matchData = matchContext.liveMatchData
    matchIsLive = matchContext.matchIsLive
    showSkeleton = matchContext.showSkeleton
  }

  const [refreshAnimationIsRunning, setRefreshAnimationIsRunning] = useState(
    matchIsLive
  )

  const forceUpdate = () => {
    if (matchContext && refreshAnimationIsRunning) {
      setRefreshAnimationIsRunning(false)
      matchContext.forceUpdate()
      setTimeout(() => {
        setRefreshAnimationIsRunning(true)
      }, 0)
    }
  }

  if (!matchData || !matchData.competition || !matchData.season) {
    return <></>
  }

  const competition = matchData.competition
  const season = matchData.season

  const teamHome = matchData.team_home
  const teamAway = matchData.team_away

  const today = moment()
  const kickoff = moment(matchData.kickoff)
  const isBefore = today.isBefore(kickoff, "day")

  const expansionIsOpenClass = isOpen ? "--is-open" : ""

  return (
    <header className={`ef-match-header ${expansionIsOpenClass}`}>
      <div className="ef-match-header__grid">
        <div className="ef-container ef-container--l">
          <div className="ef-match-header__details">
            <div className="ef-match-header__detail">
              {t(`Shortname.${competition.title}`)} {season.title} /{" "}
              {console.log("MatchData", matchData)}
              {matchData.matchday_title}
            </div>
            <div className="ef-match-header__detail">
              <Moment
                format={
                  matchData.definite
                    ? DATE_TIME_FORMATS.extended
                    : DATE_TIME_FORMATS.date_w_day_leading_zeros
                }
              >
                {matchData.kickoff}
              </Moment>
            </div>
            <div className="ef-match-header__detail">{matchData.venue}</div>
          </div>
          <div className="ef-match-header__team-logo ef-match-header__team-logo--home">
            <img
              alt={teamHome.team_name + "-logo"}
              className="ef-match-header__team-logo-image"
              src={teamHome.images.medium_square}
            />
          </div>
          <div className="ef-match-header__team-name ef-match-header__team-name--home">
            {teamHome.team_name}
          </div>

          <div
            className={
              "ef-match-header__result" +
              (matchIsLive ? " ef-match-header__result--live" : "")
            }
          >
            {showSkeleton && (
              <div className="ef-spinner ef-spinner--result"></div>
            )}

            {!showSkeleton && (
              <>
                <div className="ef-match-header__result-primary">
                  {matchData.team_home_final_score != null
                    ? matchData.team_home_final_score
                    : "–"}
                  :
                  {matchData.team_away_final_score != null
                    ? matchData.team_away_final_score
                    : "–"}
                </div>

                {matchData.team_home_half_score !== null &&
                  matchData.team_home_half_score !== null && (
                    <div className="ef-match-header__result-secondary">
                      ({matchData.team_home_half_score}:
                      {matchData.team_away_half_score})
                    </div>
                  )}
              </>
            )}
          </div>

          <div className="ef-match-header__team-logo ef-match-header__team-logo--away">
            <img
              alt={teamAway.team_name + "-logo"}
              className="ef-match-header__team-logo-image"
              src={teamAway.images.medium_square}
            />
          </div>
          <div className="ef-match-header__team-name ef-match-header__team-name--away">
            {teamAway.team_name}
          </div>
          <div className="ef-match-header__services">
            {matchIsLive && (
              <div className="ef-match-header__live">
                <div className="ef-match-header__live-time">LIVE</div>
                <div
                  className="ef-match-header__live-reload"
                  onClick={() => forceUpdate()}
                >
                  <div
                    className={
                      "ef-match-header__live-spinner " +
                      (refreshAnimationIsRunning ? " --is-running" : "")
                    }
                    style={{
                      "--live-reload-time": `${CIRCLE_SECONDS}s`,
                    }}
                  >
                    <div className="ef-match-header__live-spinner-circle">
                      <div className="ef-match-header__live-spinner-circle-full">
                        <div className="ef-match-header__live-spinner-circle-fill"></div>
                      </div>
                      <div className="ef-match-header__live-spinner-circle-half">
                        <div className="ef-match-header__live-spinner-circle-fill"></div>
                      </div>
                      <div className="ef-match-header__live-spinner-circle-inside"></div>
                    </div>
                  </div>
                  <svg className="ef-icon" width="16" height="16">
                    <use
                      href="#ef-icon--refresh-16"
                      xlinkHref="#ef-icon--refresh-16"
                    ></use>
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {["profis", "en"].includes(props.website) && (
        <OMatchHeaderExpansion
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          matchData={matchData}
          matchIsLive={matchIsLive}
        />
      )}
    </header>
  )
}

export default OMatchHeader
