import React from "react"

const AStatTableHeaderCell = ({ classSuffix, label, svg = false }) => {
  return (
    <div
      className={
        "ef-data-table__header-cell ef-data-table__header-cell--sortable ef-squad-stats__header-cell ef-squad-stats__header-cell--" +
        classSuffix
      }
    >
      <span className="ef-data-table__header-cell-label">
        {svg && (
          <svg className="ef-icon" width={16} height={16}>
            <use xlinkHref={"#ef-icon--" + svg} href={"#ef-icon--" + svg} />
          </svg>
        )}
        {!svg && label}
      </span>
    </div>
  )
}

export default AStatTableHeaderCell
