import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Link } from "gatsby"
import { concatStrings } from "../../utilities/utilities"
import OSubmenu from "./o-submenu"
import { SEARCH_PAGE_URL } from "gatsby-theme-ef-base/src/pages/suche"
import OSubmenuMatch from "../organisms/o-submenu-match"
import MBreadcrumbs from "../molecules/m-breadcrumbs"
import OHeaderAccount from "./o-header-account"
import OHeaderSearch from "./o-header-search"
import { LayoutContext } from "./o-layout"
import { AlgoliaSearchContext } from "../technical/t-algolia-search"
import ALink from "../atoms/a-link"

const OHeader = ({
  visibleOverlayType,
  setVisibleOverlayType,
  isNewsListPage,
  subnavigation,
  matchSubnavigation,
  liveMatchData,
  breadcrumbs,
  subnavigationDropdown1,
  subnavigationDropdown2,
  loggedIn,
  userData,
  setUserMenuVisible,
  userMenuVisible,
  website,
}) => {
  const { isSearchPage } = useContext(LayoutContext)
  const { query } = useContext(AlgoliaSearchContext).queryAccessor

  return (
    <header
      className={concatStrings(
        "ef-header",
        breadcrumbs && breadcrumbs.length && "--has-breadcrumbs",
        visibleOverlayType === "search" && "--has-search-mode"
      )}
    >
      <div className="ef-header__bar">
        <div className="ef-header__items ef-header__items--primary">
          {website === "tv" ? (
            <ALink link="/" className="ef-header__logo">
              <svg className="ef-header__logo-tv">
                <use xlinkHref="#ef-logo--tv"></use>
              </svg>
            </ALink>
          ) : (
            <>
              <ALink
                link={{ url: "/", website: "global" }}
                className="ef-header__logo"
              >
                <svg className="ef-header__logo-full">
                  <use xlinkHref="#ef-logo" />
                </svg>
                <svg className="ef-header__logo-minimal">
                  <use xlinkHref="#ef-logo-minimal" />
                </svg>
              </ALink>
              {breadcrumbs && breadcrumbs.length > 0 ? (
                <MBreadcrumbs breadcrumbs={breadcrumbs} />
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div className="ef-header__items ef-header__items--secondary">
          <div className="ef-header__partners">
            <a
              href="https://www3.smartadserver.com/h/cc?imgid=25412128&tmstp=[timestamp]&tgt=[reference]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="ef-header__partner ef-header__partner--main">
                <use xlinkHref="#ef-partner-indeed--simple" />
              </svg>
            </a>
            <img
              style={{ width: "1px", height: "1px", opacity: 0 }}
              src="https://www3.smartadserver.com/imp?imgid=25412128&tmstp=[timestamp]&tgt=[targeting]"
            />
            <a
              href="https://www3.smartadserver.com/h/cc?imgid=25412169&tmstp=[timestamp]&tgt=[reference]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="ef-header__partner ef-header__partner--outfitter">
                <use xlinkHref="#ef-partner-nike" />
              </svg>
            </a>
            <img
              style={{ width: "1px", height: "1px", opacity: 0 }}
              src="https://www3.smartadserver.com/imp?imgid=25412169&tmstp=[timestamp]&tgt=[targeting]"
            />
          </div>
          {/* <div
            className="ef-header__login"
            onClick={() =>
              setVisibleOverlayType(
                visibleOverlayType !== "login" ? "login" : ""
              )
            }
          >
            <svg className="ef-icon" width={16} height={16}>
              <use xlinkHref="#ef-icon--user-16" />
            </svg>
          </div> */}
          <OHeaderAccount
            loggedIn={loggedIn}
            data={userData}
            setUserMenuVisible={setUserMenuVisible}
            userMenuVisible={userMenuVisible}
          />
          {isNewsListPage ? (
            <Link to={SEARCH_PAGE_URL}>
              <div className="ef-header__search ">
                <div className="ef-header__search-icon">
                  <svg className="ef-icon" width={16} height={16}>
                    <use xlinkHref="#ef-icon--search-16" />
                  </svg>
                </div>
              </div>
            </Link>
          ) : (
            <OHeaderSearch
              visibleOverlayType={visibleOverlayType}
              setVisibleOverlayType={setVisibleOverlayType}
            />
          )}
        </div>
      </div>
      {subnavigation &&
        (subnavigation.length > 1 ||
          (subnavigation.length === 1 &&
            ((subnavigationDropdown1 && subnavigationDropdown1.length > 0) ||
              (subnavigationDropdown2 &&
                subnavigationDropdown2.length > 0)))) && (
          <OSubmenu
            subnavigation={subnavigation}
            subnavigationDropdown1={subnavigationDropdown1}
            subnavigationDropdown2={subnavigationDropdown2}
          />
        )}
      {matchSubnavigation && matchSubnavigation.length > 0 && (
        <OSubmenuMatch
          matchSubnavigation={matchSubnavigation}
          matchData={liveMatchData}
        />
      )}
      {!isSearchPage && visibleOverlayType === "search" && !query ? (
        <div
          className="ef-search-overlay --is-active"
          onClick={() => setVisibleOverlayType("")}
        />
      ) : (
        ""
      )}
    </header>
  )
}

OHeader.propTypes = {
  siteTitle: PropTypes.string,
}

OHeader.defaultProps = {
  siteTitle: ``,
}

export default OHeader
