import React from "react"
import OMenuPrimaryExtension from "./o-menu-primary-extension"
import ALink from "../atoms/a-link"
import { useTranslation } from "react-i18next"
import { getSiteUrl } from "../../../config/website-hosts/mapping"

const OMenuPrimary = ({ tree, currentWebsite, setCurrentWebsite }) => {
  let timeoutOnMouseOver = false
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"

  const { t } = useTranslation()

  const mouseOverTreatment = data => {
    if (timeoutOnMouseOver) {
      clearTimeout(timeoutOnMouseOver)
    }
    timeoutOnMouseOver = setTimeout(() => {
      setCurrentWebsite(data)
      timeoutOnMouseOver = false
    }, 300)
  }
  const mouseOutTreatment = () => {
    clearTimeout(timeoutOnMouseOver)
  }
  return (
    <>
      <ALink
        className="ef-menu__logo-link-mobile"
        link={{ url: "/", website: "global" }}
      ></ALink>
      <section
        className="ef-primary-menu"
        data-theme="dark"
        onClick={event => event.stopPropagation()}
      >
        <div className="ef-primary-menu__inner">
          <ALink
            link={{ url: "/", website: "global" }}
            className="ef-header__logo"
          >
            <svg className="ef-primary-menu__logo">
              <use xlinkHref="#ef-logo" />
            </svg>
          </ALink>

          <ul className="ef-primary-menu__items">
            {tree.map((item, key) => (
              <li
                key={key}
                className={
                  item.website === currentWebsite
                    ? "ef-primary-menu__item --is-current --is-active"
                    : "ef-primary-menu__item"
                }
              >
                {item.external_url ? (
                  <>
                    <a
                      className="ef-primary-menu__item-link"
                      // activeClassName="--is-current"
                      // href={`https://ef-${currentWebsite}.netlify.com/`}
                      href={item.external_url}
                      onMouseEnter={() => mouseOverTreatment(item.website)}
                      onMouseLeave={() => mouseOutTreatment()}
                    >
                      {item.title}
                    </a>
                    <div className="ef-primary-menu__item-action ef-primary-menu__item-action--external" />
                  </>
                ) : (
                  <>
                    <a
                      className="ef-primary-menu__item-link"
                      // activeClassName="--is-current"
                      // href={`https://ef-${currentWebsite}.netlify.com/`}
                      href={getSiteUrl(activeEnv, currentWebsite)}
                      onMouseEnter={() => mouseOverTreatment(item.website)}
                      onMouseLeave={() => mouseOutTreatment()}
                    >
                      {item.title}
                    </a>
                    <div className="ef-primary-menu__item-action ef-primary-menu__item-action--expand" />
                  </>
                )}
              </li>
            ))}
          </ul>
          <div className="ef-primary-menu__ctas">
            <div className="ef-primary-menu__cta">
              <ALink
                className="ef-button ef-button--secondary ef-button--small ef-primary-menu__cta-button"
                link="https://tickets.eintracht.de"
              >
                Tickets
              </ALink>
            </div>
            <div className="ef-primary-menu__cta">
              <ALink
                className="ef-button ef-button--secondary ef-button--small ef-primary-menu__cta-button"
                link="https://shop.eintracht.de"
              >
                Fanshop
              </ALink>
            </div>
            <div className="ef-primary-menu__cta">
              <ALink
                className="ef-button ef-button--secondary ef-button--small ef-primary-menu__cta-button"
                link="https://mitglied.eintracht.de/"
              >
                {t("Membership")}
              </ALink>
            </div>
            <div className="ef-primary-menu__cta">
              <ALink
                className="ef-button ef-button--secondary ef-button--small ef-primary-menu__cta-button"
                link="https://community.eintracht.de"
              >
                {t("Community")}
              </ALink>
            </div>
          </div>

          <div className="ef-primary-menu__partners">
            <svg className="ef-primary-menu__partner ef-primary-menu__partner--main">
              <use xlinkHref="#ef-partner-indeed" />
            </svg>
            <svg className="ef-primary-menu__partner ef-primary-menu__partner--outfitter">
              <use xlinkHref="#ef-partner-nike" />
            </svg>
          </div>
        </div>
      </section>
      {currentWebsite === "sportarten" && (
        <OMenuPrimaryExtension
          tree={tree.find(item => item.website === "sportarten").children}
          currentWebsite={currentWebsite}
          setCurrentWebsite={setCurrentWebsite}
        />
      )}
    </>
  )
}

export default OMenuPrimary
