import React, { useRef } from "react"
import useComponentSize from "@rehooks/component-size"

import Plx from "react-plx"

const OHighlightHero = props => {
  let ref = useRef(null)
  let { height } = useComponentSize(ref)

  if (!global.window) {
    global.window = {}
  }

  let aniDuration = height + window.innerHeight
  let scrolled = height + window.innerHeight

  let scaleEnd = 1 + scrolled / 1500

  let imageTranslate = -(scrolled * 0.5)
  let introTranslate = -scrolled

  const parallaxData = [
    {
      start: ".ef-highlight-hero",
      duration: aniDuration,
      properties: [
        {
          startValue: 1,
          endValue: scaleEnd,
          property: "scale",
        },
        {
          startValue: 0,
          endValue: imageTranslate,
          property: "translateY",
        },
      ],
    },
  ]
  const parallaxData2 = [
    {
      start: ".ef-highlight-hero",
      duration: aniDuration,
      properties: [
        {
          startValue: 0,
          endValue: introTranslate,
          property: "translateY",
        },
      ],
    },
  ]
  return (
    <header className="ef-highlight-hero" ref={ref}>
      <div className="ef-highlight-hero__title">
        <div className="ef-container ef-container--l">
          <div className="ef-heading ef-heading--3 ef-heading--sub">
            Trikot 2019/20
          </div>
          <h1 className="ef-heading ef-heading--1 ef-heading--has-kicker ef-highlight-hero__title">
            Feinster Frankfurter Stoff
          </h1>
        </div>
      </div>
      <Plx parallaxData={parallaxData}>
        <div className="ef-media ef-media--3x2 ef-highlight-hero__media">
          <img
            className="ef-media__image ef-highlight-hero__image"
            alt="Alt Text"
            src="https://www.sge4ever.de/wp-content/uploads/2019/06/A7848B1C-6CE6-4105-A961-E912E16C5162.png"
          />
        </div>
      </Plx>
      <Plx parallaxData={parallaxData2} animateWhenNotInViewport={true}>
        <div className="ef-highlight-hero__intro">
          <div className="ef-container ef-container--m">
            <p className="ef-paragraph ef-paragraph--featured">
              <span className="ef-text--highlight">Celiac taxidermy </span>venmo
              poutine air plant squid, cray mumblecore ennui try-hard organic
              street art blog. Chia vice quinoa echo park church-key tote bag.
              Poke meditation tousled cold-pressed, listicle palo santo
              succulents. Vinyl organic tbh you probably haven't heard of them,
              typewriter lo-fi ethical sartorial cred pop-up. Kale chips prism
              selvage, church-key tousled tacos offal narwhal man braid kitsch
              brooklyn waistcoat.
            </p>
          </div>
        </div>
      </Plx>
    </header>
  )
}

export default OHighlightHero
