import React from "react"
import { MAX_WIDTH_TIMELINE, eventType } from "../../utilities/utilities"
import LiveTickerService from "../../utilities/liveTickerService"

const ATimelineEvents = props => {
  let events = props.optaEvents

  let currentMatchLength = props.currentMatchLength

  let eventsByTeam = LiveTickerService.groupByTeam(events, "team")
  let eventsByTeamAndTime = {
    team_home: [],
    team_away: [],
  }

  eventsByTeamAndTime.team_home = LiveTickerService.groupByTeamAndNormalizeTime(
    eventsByTeam.team_home
  )
  eventsByTeamAndTime.team_away = LiveTickerService.groupByTeamAndNormalizeTime(
    eventsByTeam.team_away
  )

  let timelineEvents = []
  Object.entries(eventsByTeamAndTime).map(([, eventsbyMatchMinute]) => {
    Object.entries(eventsbyMatchMinute).map(([, eventsByMinute]) => {
      let multipleEvents = eventsByMinute.length > 1
      let multiEventIcon = <> </>
      if (multipleEvents) {
        multiEventIcon = (
          <div className="ef-timeline__event-icon">
            <div className="ef-timeline__event-multiple-badge">
              {" "}
              {eventsByMinute.length}{" "}
            </div>{" "}
          </div>
        )
      }

      let event = eventsByMinute[0]
      let eventTimeRelative = (event.time * 100) / currentMatchLength
      let progress = Math.min(eventTimeRelative, MAX_WIDTH_TIMELINE)
      let time = LiveTickerService.calculateProgressByTime(event)

      timelineEvents.push(
        <div
          className={
            "ef-timeline__event " +
            (event.team === "team_home"
              ? "ef-timeline__event--home "
              : "ef-timeline__event--away ") +
            "--has-tooltip"
          }
          data-minute={event.time}
          key={event.time + "-" + event.id}
          style={{
            left: progress + "%",
          }}
        >
          <div className="ef-timeline__event-minute"> {time}. </div>{" "}
          {multipleEvents && multiEventIcon}{" "}
          {!multipleEvents && (
            <div className="ef-timeline__event-icon">
              <svg className="ef-icon" width={16} height={16}>
                <use xlinkHref={LiveTickerService.mapEventToIcon(event)} />{" "}
              </svg>{" "}
            </div>
          )}{" "}
          <div className="ef-timeline__event-details">
            {" "}
            {eventsByMinute.map(event => {
              return (
                <div
                  key={
                    event.time +
                    "-" +
                    (event.person_id ||
                      event.assist_person_id ||
                      event.person_on_id + "-" + event.person_off_id)
                  }
                  className="ef-timeline__event-detail"
                >
                  <div className="ef-timeline__event-detail-icon">
                    <svg className="ef-icon" width={16} height={16}>
                      <use
                        xlinkHref={LiveTickerService.mapEventToIcon(event)}
                      />{" "}
                    </svg>{" "}
                    <div className="ef-timeline__event-detail-icon-line" />
                  </div>{" "}
                  <div className="ef-timeline__event-detail-description">
                    <span className="ef-timeline__event-detail-minute">
                      {" "}
                      {time + ". "}{" "}
                    </span>{" "}
                    <span className="ef-timeline__event-detail-text">
                      {" "}
                      {event.event_type === eventType.YELLOW_CARD
                        ? "Gelbe Karte: " + event.person_name
                        : ""}{" "}
                      {event.event_type === eventType.RED_CARD
                        ? "Rote Karte: " + event.person_name
                        : ""}{" "}
                      {event.event_type === eventType.YELLOW_RED_CARD
                        ? "Gelbrote Karte: " + event.person_name
                        : ""}{" "}
                      {event.event_type === eventType.GOAL
                        ? "Tor, " +
                          event.person_name +
                          (event.assist_person_name
                            ? " (Vorlage: " + event.assist_person_name + ")"
                            : "")
                        : ""}{" "}
                      {event.event_type === eventType.SUBSTITUTION &&
                        "Spielerwechsel: " +
                          event.person_name_on +
                          " für " +
                          event.person_name_off}{" "}
                    </span>{" "}
                  </div>{" "}
                </div>
              )
            })}{" "}
          </div>{" "}
        </div>
      )
    })
  })

  return timelineEvents
}

export default ATimelineEvents
