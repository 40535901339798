import React from "react"

import Cards from "../_cards"

import Slider from "react-slick"

const ONewsflashSmallSliderBlock = props => {
  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }
  const settings = {
    slidesToShow: 8,
    slidesToScroll: 8,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  return (
    <section className="ef-content__block ef-content__block--newsflash-slider">
      <Slider
        {...settings}
        className="ef-slider ef-newsflash-slider ef-newsflash-slider--default"
      >
        {props.data.card_items.map((item, index) => (
          <div className="ef-slide" key={index}>
            {Cards[props.card_content_type]
              ? React.createElement(Cards[props.card_content_type], {
                  item: item,
                  index: index,
                  uid: props.data.uid,
                  expansion: props.expansion,
                })
              : `The ${props.card_content_type} in ${props.data.uid} was not created yet`}
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default ONewsflashSmallSliderBlock
