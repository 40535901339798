import React, { useRef, useState, useEffect } from "react"
import Prism from "prismjs"
import { json } from "prismjs/components/prism-json"
import "prismjs/themes/prism.css"

const ODebug = ({ content, jsonName }) => {
  let ref = useRef(null)
  const [showJsonDebug, setShowJsonDebug] = useState(false)
  useEffect(() => {
    if (ref && ref.current) {
      Prism.highlightElement(ref.current, json)
    }
  })
  if (process.env.GATSBY_SHOW_DEBUG_JSON === "true") {
    return (
      <section
        className="o-debug"
        style={{
          width: "100%",
          padding: "0",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "1140px",
            margin: "0 auto",
          }}
        >
          <button
            style={{
              padding: "10px",
              fontWeight: "700",
            }}
            onClick={() => {
              setShowJsonDebug(showJsonDebug === false ? true : false)
            }}
          >
            {showJsonDebug === false
              ? `Show "${jsonName}"-JSON`
              : `Hide "${jsonName}"-JSON`}
          </button>
          {showJsonDebug === true ? (
            <div style={{ background: "white", padding: "10px" }}>
              <h4 style={{ color: "black" }}>{`JSON`}</h4>
              <pre>
                <code className="language-json" ref={ref}>
                  {JSON.stringify(content, null, "\t")}
                </code>
              </pre>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    )
  } else {
    return ""
  }
}

export default ODebug
