import React, { useState } from "react"

// import ALineUpCards from "../atoms/a-line-up-cards"
// import ALineUpGoals from "../atoms/a-line-up-goals"
// import ALineUpSubstitution from "../atoms/a-line-up-substitution"
import { useTranslation } from "react-i18next"

const enrichLineup = match => {
  // Add the current_score for every goal that happens
  let currentScoreHome = 0
  let currentScoreAway = 0
  function generateCurrentScore(half_time) {
    half_time.forEach(event => {
      if (event.event_type === "Goal") {
        if (event.team === "team_home") {
          currentScoreHome += 1
        } else {
          currentScoreAway += 1
        }
        event.current_score = `${currentScoreHome}:${currentScoreAway}`
      }
    })
  }
  generateCurrentScore(match.opta_events)

  // Push every event, that belongs to a player, into this player
  function pushPlayerEvents() {
    let teams = [match.team_home, match.team_away]
    let half_times = [match.opta_events]
    teams.forEach(team => {
      if (team.lineup && team.lineup.length > 0) {
        team.lineup.forEach((player, index) => {
          half_times.forEach(half_time => {
            half_time.forEach(event => {
              if (
                event.person_id === player.id ||
                event.person_on_id === player.id ||
                event.person_off_id === player.id
              ) {
                if (team.lineup[index].events) {
                  team.lineup[index].events.push(event)
                } else {
                  team.lineup[index].events = []
                  team.lineup[index].events.push(event)
                }
              }
            })
          })
        })
      }
    })
  }
  pushPlayerEvents()
}

const OLineUpBlock = ({ match }) => {
  const { t } = useTranslation()

  const [mobileView, setMobileView] = useState(
    match.team_home.id === 159 ? "home" : "away"
  )

  if (
    match &&
    match.team_home &&
    match.team_away &&
    match.team_home.lineup &&
    match.team_away.lineup
  ) {
    enrichLineup(match)

    return (
      <div className="ef-content__block ef-content__block--line-up">
        <div className="ef-container ef-container--l">
          <div className="ef-line-up">
            <h6 className="ef-line-up__heading">
              <div className="ef-line-up__heading-text">{t("Lineup")}</div>
              <div className="ef-line-up__toggle">
                <div
                  className={
                    "ef-toggle" +
                    (mobileView === "home"
                      ? " ef-toggle--left"
                      : " ef-toggle--right")
                  }
                  onClick={() =>
                    setMobileView(mobileView === "home" ? "away" : "home")
                  }
                >
                  <div className="ef-toggle__label ef-toggle__label--left">
                    {match.team_home.team_name}
                  </div>
                  <div className="ef-toggle__button">
                    <div className="ef-toggle__button-handle" />
                  </div>
                  <div className="ef-toggle__label ef-toggle__label--right">
                    {match.team_away.team_name}
                  </div>
                </div>
              </div>
            </h6>
            <div className="ef-line-up__list">
              <div
                className={
                  "ef-line-up__home" +
                  (mobileView === "home" ? " --is-active" : "")
                }
              >
                {match.team_home.lineup.map(
                  (player, index) =>
                    player.status === "Start" && (
                      <div
                        className="ef-line-up__player"
                        key={player.shirt_number}
                      >
                        <div className="ef-line-up__player-number">
                          {player.shirt_number}
                        </div>
                        <div className="ef-line-up__player-position">
                          {t("Stats.Short" + player.role)}
                        </div>
                        <div className="ef-line-up__player-name">
                          {player.first_name} {player.last_name}
                        </div>
                        {/* <div className="ef-line-up__player-events">
                          <ALineUpCards events={player.events} />
                          <ALineUpGoals events={player.events} />
                          <ALineUpSubstitution player={player} />
                        </div> */}
                      </div>
                    )
                )}
              </div>

              <div
                className={
                  "ef-line-up__away" +
                  (mobileView === "away" ? " --is-active" : "")
                }
              >
                {match.team_away.lineup.map(
                  (player, index) =>
                    player.status === "Start" && (
                      <div
                        className="ef-line-up__player"
                        key={player.shirt_number}
                      >
                        <div className="ef-line-up__player-number">
                          {player.shirt_number}
                        </div>
                        <div className="ef-line-up__player-position">
                          {t("Stats.Short" + player.role)}
                        </div>
                        <div className="ef-line-up__player-name">
                          {player.first_name} {player.last_name}
                        </div>
                        {/* <div className="ef-line-up__player-events">
                          <ALineUpCards events={player.events} />
                          <ALineUpGoals events={player.events} />
                          <ALineUpSubstitution player={player} />
                        </div> */}
                      </div>
                    )
                )}
              </div>
            </div>
            <h6 className="ef-line-up__heading">
              <div className="ef-line-up__heading-text">
                {t("SubstitutesBench")}
              </div>
              <div className="ef-line-up__toggle">
                <div
                  className={
                    "ef-toggle" +
                    (mobileView === "home"
                      ? " ef-toggle--left"
                      : " ef-toggle--right")
                  }
                  onClick={() =>
                    setMobileView(mobileView === "home" ? "away" : "home")
                  }
                >
                  <div className="ef-toggle__label ef-toggle__label--left">
                    {match.team_home.team_name}
                  </div>
                  <div className="ef-toggle__button">
                    <div className="ef-toggle__button-handle" />
                  </div>
                  <div className="ef-toggle__label ef-toggle__label--right">
                    {match.team_away.team_name}
                  </div>
                </div>
              </div>
            </h6>
            <div className="ef-line-up__list">
              <div
                className={
                  "ef-line-up__home" +
                  (mobileView === "home" ? " --is-active" : "")
                }
              >
                {match.team_home.lineup.map(
                  (player, index) =>
                    player.status === "Sub" && (
                      <div
                        className="ef-line-up__player"
                        key={player.shirt_number}
                      >
                        <div className="ef-line-up__player-number">
                          {player.shirt_number}
                        </div>
                        <div className="ef-line-up__player-position">
                          {t("Stats.Short" + player.role_sub)}
                        </div>
                        <div className="ef-line-up__player-name">
                          {player.first_name} {player.last_name}
                        </div>
                        <div className="ef-line-up__player-events">
                          {/* <ALineUpCards events={player.events} />
                          <ALineUpGoals events={player.events} />
                          <ALineUpSubstitution player={player} /> */}
                        </div>
                      </div>
                    )
                )}
              </div>
              <div
                className={
                  "ef-line-up__away" +
                  (mobileView === "away" ? " --is-active" : "")
                }
              >
                {match.team_away.lineup.map(
                  (player, index) =>
                    player.status === "Sub" && (
                      <div
                        className="ef-line-up__player"
                        key={player.shirt_number}
                      >
                        <div className="ef-line-up__player-number">
                          {player.shirt_number}
                        </div>
                        <div className="ef-line-up__player-position">
                          {t("Stats.Short" + player.role_sub)}
                        </div>
                        <div className="ef-line-up__player-name">
                          {player.first_name} {player.last_name}
                        </div>
                        <div className="ef-line-up__player-events">
                          {/* <ALineUpCards events={player.events} />
                          <ALineUpGoals events={player.events} />
                          <ALineUpSubstitution player={player} /> */}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
            <h6 className="ef-line-up__heading">
              <div className="ef-line-up__heading-text">{t("Trainer")}</div>
              <div className="ef-line-up__toggle">
                <div
                  className={
                    "ef-toggle" +
                    (mobileView === "home"
                      ? " ef-toggle--left"
                      : " ef-toggle--right")
                  }
                  onClick={() =>
                    setMobileView(mobileView === "home" ? "away" : "home")
                  }
                >
                  <div className="ef-toggle__label ef-toggle__label--left">
                    {match.team_home.team_name}
                  </div>
                  <div className="ef-toggle__button">
                    <div className="ef-toggle__button-handle" />
                  </div>
                  <div className="ef-toggle__label ef-toggle__label--right">
                    {match.team_away.team_name}
                  </div>
                </div>
              </div>
            </h6>
            <div className="ef-line-up__list">
              <div
                className={
                  "ef-line-up__home" +
                  (mobileView === "home" ? " --is-active" : "")
                }
              >
                <div className="ef-line-up__text">
                  {match.team_home.manager && (
                    <>
                      {match.team_home.manager.first_name}{" "}
                      {match.team_home.manager.last_name}
                    </>
                  )}
                </div>
              </div>
              <div
                className={
                  "ef-line-up__away" +
                  (mobileView === "away" ? " --is-active" : "")
                }
              >
                <div className="ef-line-up__text">
                  {match.team_away.manager && (
                    <>
                      {match.team_away.manager.first_name}{" "}
                      {match.team_away.manager.last_name}
                    </>
                  )}
                </div>
              </div>
            </div>
            <h6 className="ef-line-up__heading">
              <div className="ef-line-up__heading-text">{t("Referee")}</div>
            </h6>
            <div className="ef-line-up__text ef-line-up__text--referee">
              {match.referees && match.referees.length > 0 && (
                <>
                  {match.referees[0].first_name} {match.referees[0].last_name}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return ""
  }
}

export default OLineUpBlock
