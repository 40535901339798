import { navigate } from "gatsby"
import moment from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import OMatchesLink from "./o-matches-link"
import ALink from "../atoms/a-link"

const OMatchesBlock = props => {
  const { t } = useTranslation()
  const [cssActiveClass, setCssActiveClass] = useState({
    previousButtonCss: "--is-active",
    previousMatchesCss: "",
  })

  const visibilityDate = moment().subtract({ days: 14 })

  const selectedCompetition = props.competition
  const otherCompetitionsInSeason =
    props.otherCompetitionsInSeason.competitions &&
    props.otherCompetitionsInSeason.competitions.length > 0
      ? props.otherCompetitionsInSeason.competitions
      : []
  const matches = props.matches

  const previousMatches = matches.filter(match => {
    const kickoffDateAndTime = moment(match.kickoff)
    return kickoffDateAndTime < visibilityDate
  })
  let upcomingMatches = matches.filter(match => {
    const kickoffDateAndTime = moment(match.kickoff)
    return kickoffDateAndTime > visibilityDate
  })

  let seasonIsOver = false
  if (!upcomingMatches.length) {
    seasonIsOver = true
    upcomingMatches = previousMatches
  }

  const currentMatch = upcomingMatches.find(m => m.period === "PreMatch")
  const currentMatchday = currentMatch ? currentMatch.matchday : 0

  function onCompetitionChange(e) {
    let selectedCompetitionTitle = e.target.value
    let selectedCompetition = otherCompetitionsInSeason.filter(
      competition => competition.title === selectedCompetitionTitle
    )
    if (selectedCompetition.length > 0) {
      let location = selectedCompetition[0]
      navigate(location.full_slug)
    } else {
      navigate(e.target.value)
    }
  }

  function showMoreEarlier(e) {
    e.preventDefault()
    setCssActiveClass({
      previousButtonCss: "",
      previousMatchesCss: "--is-active",
    })
  }

  return (
    <>
      <header className="ef-content__header">
        <div className="ef-container ef-container--l">
          <div className="ef-heading-and-filters">
            <h1 className="ef-heading ef-heading--2">
              {props.headline && props.headline}
            </h1>
            <div className="ef-filters">
              <div className="ef-filter">
                <div className="ais-MenuSelect">
                  <select
                    className="ais-MenuSelect-select"
                    value={
                      selectedCompetition ? selectedCompetition.title : false
                    }
                    onChange={onCompetitionChange}
                  >
                    <option
                      className="ais-MenuSelect-option"
                      value={props.linkToAllMatches}
                    >
                      {t("Dropdowns.AllMatches")}
                    </option>
                    {otherCompetitionsInSeason.map(competition => {
                      return (
                        <option
                          key={competition.title}
                          className="ais-MenuSelect-option"
                          value={competition.title}
                        >
                          {competition.title}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="ef-content__block ef-content__block--matches">
        <div className="ef-container ef-container--l">
          <div className="ef-matches">
            <div
              className={
                "ef-matches__earlier " + cssActiveClass.previousMatchesCss
              }
            >
              {previousMatches.map(match => {
                return (
                  <OMatchesLink
                    website={props.website}
                    match={match}
                    key={match.id}
                    currentMatchday={currentMatchday}
                  />
                )
              })}
            </div>
            <div
              className={
                "ef-matches__earlier-button " +
                (!seasonIsOver && cssActiveClass.previousButtonCss)
              }
              onClick={showMoreEarlier}
            >
              <a className="ef-link-button ef-link-button--more-top" href="/">
                {t("Earlier")}
              </a>
            </div>
            {upcomingMatches.map(match => {
              return (
                <OMatchesLink
                  website={props.website}
                  match={match}
                  key={match.id}
                  currentMatchday={currentMatchday}
                />
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default OMatchesBlock
