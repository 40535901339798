import React from "react"
import { connectNumericMenu } from "react-instantsearch-core"
import moment from "moment"

const NumericMenu = connectNumericMenu(
  ({ items, currentRefinement, refine }) => {
    return (
      <div className="ais-MenuSelect">
        <select
          className="ais-MenuSelect-select"
          onChange={e => refine(e.target.value)}
          defaultValue={currentRefinement}
        >
          {items.map(item => {
            return item.label === "All" ? (
              ""
            ) : (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
)

// timestamps for NumericMenu (timeframe-select)
const tsNow = moment().valueOf()
const tsLastWeekStart = moment()
  .subtract(7, "d")
  .valueOf()
const tsLastMonthStart = moment()
  .subtract(30, "d")
  .valueOf()

let actualSeasonStart = moment()
  .startOf("year")
  .add(7, "M")

actualSeasonStart = actualSeasonStart.isAfter(moment())
  ? actualSeasonStart.subtract(1, "y")
  : actualSeasonStart

const tsActualSeasonStart = actualSeasonStart.valueOf()
const tsLastSeasonStart = actualSeasonStart
  .clone()
  .subtract(1, "y")
  .valueOf()

const ASearchFilterTimeframe = () => {
  return (
    <NumericMenu
      attribute="timestamp"
      items={[
        {
          label: "Beliebige Zeit",
        },
        {
          label: "Letzte Woche",
          start: tsLastWeekStart,
          end: tsNow,
        },
        {
          label: "Letzter Monat",
          start: tsLastMonthStart,
          end: tsNow,
        },
        {
          label: "Diese Saison",
          start: tsActualSeasonStart,
          end: tsNow,
        },
        {
          label: "Letzte Saison",
          start: tsLastSeasonStart,
          end: tsActualSeasonStart,
        },
      ]}
    />
  )
}

export default ASearchFilterTimeframe
