import { useLocation } from "@reach/router"
import moment from "moment"
import "moment/locale/de"
import queryString from "query-string"
import React, { createContext, useEffect, useState } from "react"
import Moment from "react-moment"
import { concatStrings } from "../../utilities/utilities"
import OFooter from "../organisms/o-footer"
import ONavigation from "../organisms/o-navigation"
import OStickyAdBlock from "../organisms/o-sticky-ad-block"
import TAlgoliaSearch from "../technical/t-algolia-search"
import TLayoutScriptHelmet from "../technical/t-layout_script-helmet"
import TScriptTags from "../technical/t-script-tags"

// TODO
// Moment.globalTimezone = "Europe/Berlin"
Moment.globalMoment = moment
Moment.globalLocale = "de"
Moment.globalFormat = "DD.MM.YYYY, HH:mm"
Moment.globalLocal = true
Moment.globalElement = "span"

export const LayoutContext = createContext({
  isSearchPage: false,
  website: null,
  showAds: true,
})

export const loadSvg = () => {
  let ajax = new XMLHttpRequest()
  ajax.open("GET", "//design.eintracht.de/assets/svg/svg-sprite.svg", true)
  ajax.send()
  ajax.onload = function() {
    let div = document.createElement("div")
    div.innerHTML = ajax.responseText
    document.body.insertBefore(div, document.body.childNodes[0])
  }
}

const OLayout = ({
  children,
  matchIsLive,
  isSearchPage,
  searchOptions,
  isNewsListPage,
  tree,
  website,
  website_theme,
  website_lang,
  breadcrumbs,
  subnavigation,
  subnavigationDropdown1,
  subnavigationDropdown2,
  footerPageNodeContent,
  showAds,
  startPageData,
}) => {
  const location = useLocation()
  const initialQuery = queryString.parse(location.search).q || ""
  const [query, setQuery] = useState(initialQuery)

  const getSubmenuClass = () => {
    return subnavigation &&
      (subnavigation.length > 1 ||
        (subnavigation.length === 1 &&
          ((subnavigationDropdown1 && subnavigationDropdown1.length > 0) ||
            (subnavigationDropdown2 && subnavigationDropdown2.length > 0))))
      ? "--has-submenu"
      : null
  }

  useEffect(() => {
    loadSvg()
    moment.locale(website_lang)
  }, [])

  return (
    <>
      <TLayoutScriptHelmet />
      <TScriptTags>
        <LayoutContext.Provider
          value={{
            isSearchPage: isSearchPage,
            website: website,
            showAds: showAds,
            startPageData: startPageData,
          }}
        >
          <TAlgoliaSearch
            website={website}
            lang={website_lang}
            initialQuery={initialQuery}
            searchOptions={searchOptions}
            propagateQuery={setQuery}
          >
            <div className={concatStrings("ef-page", getSubmenuClass())}>
              <ONavigation
                matchIsLive={matchIsLive}
                tree={tree}
                website={website}
                lang={website_lang}
                isNewsListPage={isNewsListPage}
                breadcrumbs={breadcrumbs}
                subnavigation={subnavigation}
                subnavigationDropdown1={subnavigationDropdown1}
                subnavigationDropdown2={subnavigationDropdown2}
              />
              {children}
              <OStickyAdBlock />
              <OFooter
                website_theme={website_theme}
                content={footerPageNodeContent}
              />
            </div>
          </TAlgoliaSearch>
        </LayoutContext.Provider>
      </TScriptTags>
    </>
  )
}

OLayout.defaultProps = {
  matchlive: false,
}

export default OLayout
