import React from "react"

import { concatStrings } from "../../utilities/utilities"

import AImage from "../atoms/a-image"
import AHeading from "../atoms/a-heading"
import ALink from "../atoms/a-link"

const MTeaserCard = props => {
  return (
    <ALink
      className="ef-teaser-card"
      link={props.item.link ? props.item.link : ""}
    >
      <div className="ef-media ef-teaser-card__media">
        {props.item.image && (
          <AImage
            add_class="ef-teaser-card__image"
            image_size="card_l"
            image={props.item.image}
            useDefault="true"
          />
        )}
      </div>
      <div className="ef-teaser-card__text">
        {props.item.kicker && (
          <div className="ef-kicker">{props.item.kicker}</div>
        )}
        {props.item.headline && (
          <AHeading
            headline_level="h2"
            headline={props.item.headline}
            add_class={concatStrings(
              props.item.kicker && "ef-heading--has-kicker",
              "ef-teaser-card__heading"
            )}
          />
        )}
      </div>
    </ALink>
  )
}

export default MTeaserCard
