import React, { useEffect, useRef, useState, useContext } from "react"
import { navigate } from "gatsby"
import { CSSTransition } from "react-transition-group"
import { SEARCH_PAGE_URL } from "gatsby-theme-ef-base/src/pages/suche"
import useDebounce from "../../hooks/useDebounce"
import { AlgoliaSearchContext } from "../technical/t-algolia-search"
import { LayoutContext } from "./o-layout"
import { concatStrings } from "../.."
import { useTranslation } from "react-i18next"

const OHeaderSearch = ({ visibleOverlayType, setVisibleOverlayType }) => {
  const { query, setQuery } = useContext(AlgoliaSearchContext).queryAccessor
  const [searchTerm, setSearchTerm] = useState(query || "")
  const { isSearchPage } = useContext(LayoutContext)
  const inputRef = useRef(null)
  const debouncedQuery = useDebounce(searchTerm, 100)
  const { t } = useTranslation()

  const resetSearch = () => {
    setVisibleOverlayType("")
    setSearchTerm("")
  }

  const activateSearch = () => {
    setVisibleOverlayType("search")
    inputRef.current.focus()
  }

  useEffect(() => {
    document.body.classList.toggle(
      "--is-frozen",
      visibleOverlayType === "search" && !isSearchPage
    )
  }, [visibleOverlayType])

  const handleSubmit = event => {
    event.preventDefault()
    if (isSearchPage) return
    navigate(`${SEARCH_PAGE_URL}?q=${encodeURIComponent(debouncedQuery)}`, {
      replace: true,
    })
  }

  useEffect(() => {
    setQuery(debouncedQuery)
    if (isSearchPage) {
      activateSearch()
      navigate(`${SEARCH_PAGE_URL}?q=${encodeURIComponent(debouncedQuery)}`, {
        replace: true,
      })
    }
  }, [debouncedQuery])

  return (
    <div
      className={
        "ef-header__search " +
        (visibleOverlayType === "search" || isSearchPage ? "--is-active" : "")
      }
    >
      <div className="ais-SearchBox">
        <form
          className="ais-SearchBox-form"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <input
            autoFocus={true}
            ref={inputRef}
            name="search"
            className="ais-SearchBox-input"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder={t("Search.SearchTerm")}
            spellCheck="false"
            maxLength="512"
          />
          <button
            className="ais-SearchBox-reset"
            type="reset"
            title={t("Search.Delete")}
            onClick={() => setSearchTerm("")}
          >
            <svg className="ais-SearchBox-resetIcon" width={10} height={10} />
          </button>
        </form>
      </div>
      {isSearchPage ? (
        ""
      ) : (
        <div className="ef-header__search-close" onClick={() => resetSearch()}>
          {t("Abort")}
        </div>
      )}
      <div className="ef-header__search-icon" onClick={() => activateSearch()}>
        <svg className="ef-icon" width={16} height={16}>
          <use xlinkHref="#ef-icon--search-16" />
        </svg>
      </div>

      {isSearchPage || query || visibleOverlayType !== "search" ? (
        ""
      ) : (
        <CSSTransition appear={true} in={true} enter={false} timeout={300}>
          {state => (
            <div
              className={concatStrings(
                "ef-search-dropdown",
                state === "entered" && "--is-active"
              )}
            >
              <ul className="ef-search-quicklinks">
                <h6 className="ef-search-quicklinks__heading">Quicklinks</h6>
                <li className="ef-search-quicklink">
                  <a href="//shop.eintracht.de">Fanshop</a>
                </li>
                <li className="ef-search-quicklink">
                  <a href="//tickets.eintracht.de">Tickets</a>
                </li>
                <li className="ef-search-quicklink">
                  <a href={t("SearchQuicklinks.team.url")}>
                    {t("SearchQuicklinks.team.name")}
                  </a>
                </li>
                <li className="ef-search-quicklink">
                  <a href={t("SearchQuicklinks.route.url")}>
                    {t("SearchQuicklinks.route.name")}
                  </a>
                </li>
                <li className="ef-search-quicklink">
                  <a href={t("SearchQuicklinks.sports.url")}>
                    {t("SearchQuicklinks.sports.name")}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </CSSTransition>
      )}
    </div>
  )
}

export default OHeaderSearch
