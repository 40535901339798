import React, { useState } from "react"
import { Location } from "@reach/router"
import { useTranslation } from "react-i18next"
import ALink from "../atoms/a-link"

const MShare = props => {
  const { t } = useTranslation()
  const [shareIsOpen, setShareIsOpen] = useState("false")

  let title = `${props.title} - ${props.website &&
    t("Websites." + props.website)} - ${t("SeoTitleSuffix")}`

  const shareFunction = location => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!")
        })
        .catch(console.error)
    } else {
      setShareIsOpen(shareIsOpen !== "true" ? "true" : "false")
    }
  }
  if (props.disabled !== 1) {
    return (
      <Location>
        {({ location }) => (
          <>
            <div className="ef-share">
              <div
                className={
                  shareIsOpen === "true"
                    ? "ef-share__button --is-open"
                    : "ef-share__button"
                }
                onClick={() => shareFunction(location)}
              >
                <div className="ef-share__button-android">
                  <svg className="ef-icon" width={24} height={24}>
                    <use xlinkHref="#ef-icon--share-android-24" />
                  </svg>
                </div>
                <div className="ef-share__button-ios">
                  <svg className="ef-icon" width={24} height={24}>
                    <use xlinkHref="#ef-icon--share-ios-24" />
                  </svg>
                </div>
                <div className="ef-share__button-close">
                  <svg className="ef-icon" width={16} height={16}>
                    <use xlinkHref="#ef-icon--x-16" />
                  </svg>
                </div>
              </div>
              <div
                className={
                  shareIsOpen === "true"
                    ? "ef-share__options --is-open"
                    : "ef-share__options"
                }
              >
                <ALink
                  className="ef-share__option ef-share__option--facebook"
                  link={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="ef-icon" width={16} height={16}>
                    <use xlinkHref="#ef-icon--facebook-16" />
                  </svg>
                </ALink>
                <ALink
                  className="ef-share__option ef-share__option--twitter"
                  link={`https://twitter.com/intent/tweet?text=${
                    title && title.length > 0 ? title + ":" : ""
                  }&amp;url=${location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="ef-icon" width={16} height={16}>
                    <use xlinkHref="#ef-icon--twitter-16" />
                  </svg>
                </ALink>
                <ALink
                  className="ef-share__option ef-share__option--whatsapp"
                  link={`https://api.whatsapp.com/send?text=Jetzt%20auf%20der%20Eintracht-Website%20ansehen%3A%20${location.href}%20Nur%20die%20SGE!`}
                  target="_blank"
                >
                  <svg className="ef-icon" width={16} height={16}>
                    <use xlinkHref="#ef-icon--whatsapp-16" />
                  </svg>
                </ALink>

                <a
                  className="ef-share__option ef-share__option--email"
                  href={`mailto:?subject='Betreff'&body=${
                    title && title.length > 0 ? title + ":" : ""
                  } ${location.href}`}
                >
                  <svg className="ef-icon" width={16} height={16}>
                    <use xlinkHref="#ef-icon--email-16" />
                  </svg>
                </a>
              </div>
            </div>
          </>
        )}
      </Location>
    )
  } else {
    return ""
  }
}

export default MShare
