import React, { useContext } from "react"
import { LayoutContext } from "../organisms/o-layout"
import OTableSliderBlock from "../organisms/o-table-slider-block"

const TEintrachtLeagueTable = () => {
  const layoutContext = useContext(LayoutContext)

  if (!layoutContext.startPageData) {
    return null
  }

  const data = layoutContext.startPageData

  const standings =
    data.standings.edges.length > 0
      ? data.standings.edges[0].node.data.standings
      : []

  const standingsLink = data.standings.edges[0].node.full_slug

  return (
    <OTableSliderBlock
      data={{ standings: standings, standingsLink: standingsLink }}
    />
  )
}

export default TEintrachtLeagueTable
