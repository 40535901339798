import React from "react"

const OFormationBlock = props => {
  if (
    props.match &&
    props.match.team_home &&
    props.match.team_away &&
    props.match.team_home.lineup &&
    props.match.team_away.lineup &&
    props.match.team_home.formation_used !== null &&
    props.match.team_away.formation_used !== null
  ) {
    return (
      <section className="ef-content__block ef-content__block--formation">
        <div className="ef-container ef-container--l">
          <div className="ef-formation --is-in-view">
            <svg
              className="ef-formation__field ef-formation__field--vertical"
              width="293.32"
              height="516.05"
            >
              <use xlinkHref="#ef-soccer-field-vertical" />
            </svg>
            <svg
              className="ef-formation__field ef-formation__field--horizontal"
              width="516.05"
              height="293.32"
            >
              <use xlinkHref="#ef-soccer-field-horizontal" />
            </svg>
            <div
              className={
                "ef-formation__team ef-formation__team--home ef-formation__team--" +
                props.match.team_away.formation_used +
                " ef-formation__team--primary"
              }
            >
              {props.match.team_home.lineup.map(
                (player, index) =>
                  player.status === "Start" && (
                    <div
                      className="ef-formation__player"
                      data-position={index}
                      key={player.shirt_number}
                    >
                      <div className="ef-formation__player-jersey">
                        <svg className="ef-icon" width={48} height={48}>
                          <use xlinkHref="#ef-icon--jersey-48" />
                        </svg>
                        <div className="ef-formation__player-number">
                          {player.shirt_number}
                        </div>
                      </div>
                      <div className="ef-formation__player-name">
                        {player.last_name}
                      </div>
                    </div>
                  )
              )}
            </div>
            <div
              className={
                "ef-formation__team ef-formation__team--away ef-formation__team--" +
                props.match.team_away.formation_used +
                " ef-formation__team--secondary"
              }
              style={{ color: "#da251c", fill: "#fff" }}
            >
              {props.match.team_away.lineup.map(
                (player, index) =>
                  player.status === "Start" && (
                    <div
                      className="ef-formation__player"
                      data-position={index}
                      key={player.shirt_number}
                    >
                      <div className="ef-formation__player-jersey">
                        <svg className="ef-icon" width={48} height={48}>
                          <use xlinkHref="#ef-icon--jersey-48" />
                        </svg>
                        <div className="ef-formation__player-number">
                          {player.shirt_number}
                        </div>
                      </div>
                      <div className="ef-formation__player-name">
                        {player.last_name}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return <></>
  }
}

export default OFormationBlock
