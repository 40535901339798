import React, { useContext } from "react"
import Cards from "../_cards"
import Slider from "react-slick"
import { contentAdMapping } from "../../../config/website-hosts/mapping"
import MAdCard from "../molecules/m-ad-card"
import { LayoutContext } from "./o-layout"

const OArticleSliderBlock = props => {
  const layoutContext = useContext(LayoutContext)

  if (!props.data.card_items) {
    return <div></div>
  }

  const showAds = layoutContext.showAds
  let card_items = props.data.card_items
  const uidAdMap = contentAdMapping
  const pageUid = props.uid || null
  const ad = uidAdMap.find(obj => parseInt(obj.uid) === parseInt(pageUid))

  const adIsSchonDa = card_items.find(obj => obj && obj.type === "ad")
  if (ad && showAds && !adIsSchonDa) {
    card_items.splice(ad.position, 0, ad)
  }
  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }

  function renderCard(item, props) {
    if (item.type === "ad") {
      return React.createElement(MAdCard, {
        item: item,
      })
    } else if (item.type === "video_page") {
      return React.createElement(Cards["video_card"], {
        item: item,
      })
    } else if (item.type === "magazine_page") {
      return React.createElement(Cards["magazine_card"], {
        item: item,
      })
    } else {
      return Cards[props.card_content_type] ? (
        React.createElement(Cards[props.card_content_type], {
          item: item,
        })
      ) : (
        <div>
          `The ${props.card_content_type} in ${props.data.uid} was not created
          yet`
        </div>
      )
    }
  }

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section className="ef-content__block ef-content__block--article-slider">
      <Slider {...settings} className="ef-slider ef-article-slider">
        {card_items.map((item, index) => (
          <div className="ef-slide" key={index}>
            {item && renderCard(item, props)}
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default OArticleSliderBlock
