import React from "react"
import ALink from "../atoms/a-link"
import { getSiteUrl } from "../../../config/website-hosts/mapping"

const OMenuPrimaryExtension = ({ tree, currentWebsite, setCurrentWebsite }) => {
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"
  return (
    <section
      className="ef-primary-menu-extension --is-active"
      data-theme="dark"
    >
      <div className="ef-menu__back">
        <div
          className="ef-link-button ef-link-button--back"
          onClick={() => setCurrentWebsite("")}
        >
          Zurück
        </div>
      </div>
      <div className="ef-primary-menu-extension__inner">
        <header className="ef-menu__items-header">
          <h4 className="ef-heading ef-heading--4 ef-menu__items-heading">
            Sportarten
          </h4>
        </header>
        <ul className="ef-primary-menu-extension__items">
          {tree.map((item, key) => (
            <li
              key={key}
              className="ef-primary-menu-extension__item"
              data-continent={item.website}
            >
              <ALink
                className="ef-primary-menu-extension__item-link"
                link={getSiteUrl(activeEnv, item.website)}
              >
                <div className="ef-menu__sport">
                  <svg className="ef-menu__sport-icon">
                    <use xlinkHref={`#ef-sport-icon--${item.website}`} />
                  </svg>
                  <div className="ef-menu__sport-label">{item.title}</div>
                </div>
              </ALink>
              <div className="ef-primary-menu-extension__item-action ef-primary-menu__item-action--expand" />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
export default OMenuPrimaryExtension
