import React from "react"

import AImage from "../atoms/a-image"
import ALink from "../atoms/a-link"

const MProductCard = props => {
  return (
    <ALink
      className="ef-product-card"
      link={props.item.link ? props.item.link : ""}
      target={props.item.link.target ? props.item.link.target : ""}
    >
      <div className="ef-media ef-product-card__media">
        {props.item.image && (
          <AImage
            add_class="ef-product-card__image"
            image_size="card_l"
            aspectRatio="1:1"
            image={props.item.image}
            useDefault="true"
          />
        )}
        {props.item.hover_image && (
          <AImage
            add_class="ef-product-card__image"
            image_size="card_l"
            aspectRatio="1:1"
            image={props.item.hover_image}
          />
        )}
      </div>
      <div className="ef-product-card__text ef-text--center">
        {props.item.headline && (
          <div className="ef-heading ef-heading--5">{props.item.headline}</div>
        )}
        {props.item.subline && (
          <div
            className="ef-paragraph ef-text--s"
            dangerouslySetInnerHTML={{ __html: props.item.subline }}
          />
        )}
      </div>
    </ALink>
  )
}

export default MProductCard
