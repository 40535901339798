import React from "react"

import AHeading from "../atoms/a-heading"

const OHeadingBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--header-text">
      <div className="ef-container ef-container--l">
        <div className="ef-copy-block">
          <AHeading
            headline_level={props.data.headline_level}
            headline={props.data.headline}
            text_center={props.data.text_center}
          />
        </div>
      </div>
    </section>
  )
}

export default OHeadingBlock
