import React from "react"
import AImage from "../atoms/a-image"
import AHeading from "../atoms/a-heading"

const OPersonGridBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--person-grid">
      <div className="ef-container ef-container--l">
        <AHeading headline_level="h4" headline={props.data.headline} />
        <div className="ef-card-grid ef-card-grid--center ef-person-grid">
          {props.data.person_items.map((item, index) => (
            <div className="ef-card-grid__item" key={index}>
              <div className="ef-person-card h-card">
                <div className="ef-media ef-person-card__media">
                  {item.image && (
                    <AImage
                      image={item.image}
                      image_size="card_s"
                      add_class="ef-person-card__image u-photo"
                      aspectRatio="1:1"
                    />
                  )}
                </div>
                <div className="ef-person-card__text">
                  <div className="ef-person-card__name p-name">{item.name}</div>
                  <div className="ef-person-card__role p-role">{item.role}</div>
                  {((item.email && item.email.length > 0) ||
                    (item.tel && item.tel.length > 0)) && (
                    <div className="ef-person-card__contact">
                      {item.email && item.email.length > 0 && (
                        <a
                          className="ef-text--link ef-person-card__contact-item u-email"
                          href={"mailto:" + item.email}
                        >
                          {item.email}
                        </a>
                      )}
                      {item.tel && item.tel.length > 0 && (
                        <a
                          className="ef-text--link ef-person-card__contact-item p-tel"
                          href={"tel:" + item.tel}
                        >
                          {item.tel}
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OPersonGridBlock
