const mapping = {
  development: {
    beispiel: "https://ef-beispiel.netlify.app",
    basketball: "https://ef-basketball.netlify.app",
    boxen: "https://ef-boxen.netlify.app",
    business: "https://ef-business.netlify.app",
    cheerleading: "https://ef-cheerleading.netlify.app",
    klub: "https://ef-klub.netlify.app",
    curling: "https://ef-curling.netlify.app",
    dart: "https://ef-dart.netlify.app",
    doku: "https://ef-doku.netlify.app",
    eishockey: "https://ef-eishockey.netlify.app",
    eissport: "https://ef-eissport.netlify.app",
    en: "https://ef-en.netlify.app",
    "en-club": "https://ef-en-club.netlify.app",
    "en-fans": "https://ef-en-fans.netlify.app",
    esports: "https://ef-esports.netlify.app",
    fans: "https://ef-fans.netlify.app",
    fechten: "https://ef-fechten.netlify.app",
    fitness: "https://ef-fitness.netlify.app",
    frauen: "https://ef-frauen.netlify.app",
    funsport: "https://ef-funsport.netlify.app",
    fussballschule: "https://ef-fussballschule.netlify.app",
    global: "https://ef-www.netlify.app",
    handball: "https://ef-handball.netlify.app",
    hockey: "https://ef-hockey.netlify.app",
    jp: "https://ef-jp.netlify.app",
    kampfsport: "https://ef-kampfsport.netlify.app",
    leichtathletik: "https://ef-leichtathletik.netlify.app",
    museum: "https://ef-museum.netlify.app",
    nachwuchs: "https://ef-nachwuchs.netlify.app",
    profis: "https://ef-profis.netlify.app",
    ringen: "https://ef-ringen.netlify.app",
    rugby: "https://ef-rugby.netlify.app",
    status: "https://ef-status.netlify.app",
    tanzen: "https://ef-tanzen.netlify.app",
    tennis: "https://ef-tennis.netlify.app",
    tickets: "https://ef-tickets.netlify.app",
    tischfussball: "https://ef-tischfussball.netlify.app",
    tischtennis: "https://ef-tischtennis.netlify.app",
    triathlon: "https://ef-triathlon.netlify.app",
    turnen: "https://ef-turnen.netlify.app",
    tv: "https://ef-tv.netlify.app",
    "ultimate-frisbee": "https://ef-ultimate-frisbee.netlify.app",
    volleyball: "https://ef-volleyball.netlify.app",
  },
  staging: {
    beispiel: "https://beispiel-staging.eintracht.de",
    basketball: "https://basketball-staging.eintracht.de",
    boxen: "https://boxen-staging.eintracht.de",
    business: "https://business-staging.eintracht.de",
    cheerleading: "https://cheerleading-staging.eintracht.de",
    klub: "https://klub-staging.eintracht.de",
    dart: "https://dart-staging.eintracht.de",
    doku: "https://doku-staging.eintracht.de",
    eishockey: "https://eishockey-staging.eintracht.de",
    eissport: "https://eissport-staging.eintracht.de",
    en: "https://en-staging.eintracht.de",
    "en-club": "https://en-club-staging.eintracht.de",
    "en-fans": "https://en-fans-staging.eintracht.de",
    esports: "https://esports-staging.eintracht.de",
    fans: "https://fans-staging.eintracht.de",
    fechten: "https://fechten-staging.eintracht.de",
    fitness: "https://fitness-staging.eintracht.de",
    frauen: "https://frauen-staging.eintracht.de",
    funsport: "https://funsport-staging.eintracht.de",
    fussballschule: "https://fussballschule-staging.eintracht.de",
    global: "https://www-staging.eintracht.de",
    handball: "https://handball-staging.eintracht.de",
    hockey: "https://hockey-staging.eintracht.de",
    jp: "https://jp-staging.eintracht.de",
    kampfsport: "https://kampfsport-staging.eintracht.de",
    leichtathletik: "https://leichtathletik-staging.eintracht.de",
    museum: "https://museum-staging.eintracht.de",
    nachwuchs: "https://nachwuchs-staging.eintracht.de",
    profis: "https://profis-staging.eintracht.de",
    ringen: "https://ringen-staging.eintracht.de",
    rugby: "https://rugby-staging.eintracht.de",
    status: "https://status-staging.eintracht.de",
    tanzen: "https://tanzen-staging.eintracht.de",
    tennis: "https://tennis-staging.eintracht.de",
    tickets: "https://tickets-staging.eintracht.de",
    tischfussball: "https://tischfussball-staging.eintracht.de",
    tischtennis: "https://tischtennis-staging.eintracht.de",
    triathlon: "https://triathlon-staging.eintracht.de",
    turnen: "https://turnen-staging.eintracht.de",
    tv: "https://tv-staging.eintracht.de",
    "ultimate-frisbee": "https://ultimate-frisbee-staging.eintracht.de",
    volleyball: "https://volleyball-staging.eintracht.de",
  },
  production: {
    beispiel: "https://beispiel.eintracht.de",
    basketball: "https://basketball.eintracht.de",
    boxen: "https://boxen.eintracht.de",
    business: "https://business.eintracht.de",
    cheerleading: "https://cheerleading.eintracht.de",
    klub: "https://klub.eintracht.de",
    dart: "https://dart.eintracht.de",
    doku: "https://doku.eintracht.de",
    eishockey: "https://eishockey.eintracht.de",
    eissport: "https://eissport.eintracht.de",
    en: "https://en.eintracht.de",
    "en-club": "https://club.eintracht.de",
    "en-fans": "https://supporters.eintracht.de",
    esports: "https://esports.eintracht.de",
    fans: "https://fans.eintracht.de",
    fechten: "https://fechten.eintracht.de",
    fitness: "https://fitness.eintracht.de",
    frauen: "https://frauen.eintracht.de",
    funsport: "https://funsport.eintracht.de",
    fussballschule: "https://fussballschule2.eintracht.de",
    global: "https://wow.eintracht.de",
    handball: "https://handball.eintracht.de",
    hockey: "https://hockey.eintracht.de",
    jp: "https://jp.eintracht.de",
    kampfsport: "https://kampfsport.eintracht.de",
    leichtathletik: "https://leichtathletik.eintracht.de",
    museum: "https://museum.eintracht.de",
    nachwuchs: "https://nachwuchs.eintracht.de",
    profis: "https://profis.eintracht.de",
    ringen: "https://ringen.eintracht.de",
    rugby: "https://rugby.eintracht.de",
    status: "https://status.eintracht.de",
    tanzen: "https://tanzen.eintracht.de",
    tennis: "https://tennis.eintracht.de",
    tickets: "https://tickets.eintracht.de",
    tischfussball: "https://tischfussball.eintracht.de",
    tischtennis: "https://tischtennis.eintracht.de",
    triathlon: "https://triathlon.eintracht.de",
    turnen: "https://turnen.eintracht.de",
    tv: "https://tv2.eintracht.de",
    "ultimate-frisbee": "https://ultimate-frisbee.eintracht.de",
    volleyball: "https://volleyball.eintracht.de",
  },
}

const contentAdMapping = [
  {
    type: "ad",
    uid: "14204",
    position: 2,
    ad_code: "sas_88118",
  },
  {
    type: "ad",
    uid: "14203",
    position: 2,
    ad_code: "sas_88120",
  },

  // {
  //   type: "ad",
  //   uid: "1",
  //   position: 2,
  //   ad_code: "sas_88118",
  // },
  // {
  //   type: "ad",
  //   uid: "26",
  //   position: 2,
  //   ad_code: "sas_90875",
  // },
]

const blockAdMapping = [
  {
    type: "stadium_ad_block",
    ad_code_left: "sas_90614",
    ad_code_main: "sas_88123",
    ad_code_right: "sas_90615",
  },
  {
    type: "stadium_ad_schedule_block",
    ad_code_left: "sas_90622",
    ad_code_main: "sas_90623",
    ad_code_right: "sas_90624",
  },
  {
    type: "stadium_ad_stats_block",
    ad_code_left: "sas_90628",
    ad_code_main: "sas_90630",
    ad_code_right: "sas_60629",
  },
  {
    type: "stadium_ad_league_table_block",
    ad_code_left: "sas_90632",
    ad_code_main: "sas_90633",
    ad_code_right: "sas_90631",
  },
]

module.exports = {
  contentAdMapping: contentAdMapping,
  blockAdMapping: blockAdMapping,
  mapping: mapping,
  getSiteUrl: (activeEnv, websiteKey) => {
    return mapping[activeEnv][websiteKey]
  },
}
