import React, { useState } from "react"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"

import ODebug from "gatsby-component-lib/components-legacy/organisms/o-debug"
import ALink from "../atoms/a-link"

const OLeagueTableBlock = props => {
  const { t } = useTranslation()

  const currentMatchday = props.content.data.matchday

  let currentStandingType = props.content.standing_type || "full"
  currentStandingType =
    currentStandingType === "current" ? "full" : currentStandingType
  const [standingType, setStandingType] = useState(currentStandingType)

  const onCompetitionChange = e => {
    if (e.target.value.length > 0) {
      navigate("/" + e.target.value)
    }
  }

  const onTypeChange = e => {
    const selectedType = e.target.value
    setStandingType(selectedType)
  }

  if (!props.tableData.standings) {
    return <></>
  }

  let standings = props.tableData.standings[standingType]

  const labelMap = [
    {
      label: t("Dropdowns.Full"),
      type: "full",
    },
    {
      label: t("Dropdowns.Home"),
      type: "home",
    },
    {
      label: t("Dropdowns.Away"),
      type: "away",
    },
  ]

  // Vorheriger- / Nächster Spieltag > nur in league_table_page.template verfügbar
  let prev_path = null
  let next_path = null
  if (
    currentMatchday &&
    props.matchDayTableLinks &&
    props.matchDayTableLinks.length > 0
  ) {
    let prev_matchday = currentMatchday > 1 ? currentMatchday - 1 : false
    let next_matchday =
      currentMatchday === props.matchDayTableLinks.length
        ? false
        : currentMatchday + 1
    prev_path = prev_matchday
      ? props.matchDayTableLinks.filter(m => m.matchday === prev_matchday)[0]
          .full_slug
      : false
    next_path = next_matchday
      ? props.matchDayTableLinks.filter(m => m.matchday === next_matchday)[0]
          .full_slug
      : false
  }

  return (
    <>
      <header className="ef-content__header">
        <div className="ef-container ef-container--l">
          <div className="ef-heading-and-filters">
            <h1 className="ef-heading ef-heading--2">
              {props.headline && props.headline}
            </h1>
            <div className="ef-filters">
              <div className="ef-filter">
                <div className="ef-filter ef-filter--prev-next">
                  <div className="ais-MenuSelect">
                    <select
                      className="ais-MenuSelect-select"
                      id="select-table-type"
                      onChange={onTypeChange}
                      defaultValue={standingType}
                    >
                      {labelMap.map(standing => (
                        <option
                          key={standing.type}
                          className="ais-MenuSelect-option"
                          value={standing.type}
                        >
                          {standing.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="ef-filter">
                <div className="ef-filter ef-filter--prev-next">
                  {prev_path && (
                    <div className="ef-filter__prev">
                      <ALink link={prev_path}>
                        <svg className="ef-icon" width={16} height={16}>
                          <use
                            xlinkHref="#ef-icon--chevron-left-16"
                            href="#ef-icon--chevron-left-16"
                          />
                        </svg>
                      </ALink>
                    </div>
                  )}

                  <div className="ais-MenuSelect">
                    <select
                      onChange={onCompetitionChange}
                      className="ais-MenuSelect-select"
                    >
                      {Object.values(props.matchDayTableLinks)
                        .filter(elem => {
                          return elem.has_standings
                        })
                        .map((entry, index) => (
                          <option
                            key={index}
                            className="ais-MenuSelect-option"
                            value={entry.full_slug}
                            selected={currentMatchday === entry.matchday}
                          >
                            {entry.matchday}. {t("Matchday")}
                          </option>
                        ))}
                    </select>
                  </div>

                  {next_path && (
                    <div className="ef-filter__next">
                      <ALink link={next_path}>
                        <svg className="ef-icon" width={16} height={16}>
                          <use
                            xlinkHref="#ef-icon--chevron-right-16"
                            href="#ef-icon--chevron-right-16"
                          />
                        </svg>
                      </ALink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="ef-content__block ef-content__block--league-table">
        <div className="ef-container ef-container--l">
          <div className="ef-league-table">
            <header className="ef-league-table__row ef-league-table__header">
              <div className="ef-league-table__header-item ef-league-table__header-item--position">
                {t("Stats.Place")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--logo" />
              <div className="ef-league-table__header-item ef-league-table__header-item--team">
                {t("Stats.Club")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--matches">
                {t("Stats.Matches")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--stats">
                {t("Stats.StatsShort")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--goals">
                {t("Stats.Goals")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--difference">
                {t("Stats.DifferenceShort")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--points">
                {t("Stats.Points")}
              </div>
              <div className="ef-league-table__header-item ef-league-table__header-item--form">
                {t("Stats.Shape")}
              </div>
            </header>

            {standings &&
              standings.map((team, index) => (
                <div
                  key={team.team_id}
                  className={
                    "ef-league-table__row" +
                    (team.team_id === 159
                      ? " ef-league-table__row--eintracht"
                      : "")
                  }
                >
                  <div className="ef-league-table__cell ef-league-table__cell--position">
                    <div className="ef-league-table__position">{index + 1}</div>
                    <div className="ef-league-table__prev-position ef-league-table__prev-position--same">
                      {team.position_trend === "" && (
                        <div className="ef-league-table__prev-position-same" />
                      )}
                      {team.position_trend === "+" && (
                        <div className="ef-league-table__prev-position ef-league-table__prev-position--up">
                          <svg className="ef-icon" width="12" height="12">
                            <use
                              href="#ef-icon--chevron-up-12"
                              xlinkHref="#ef-icon--chevron-up-12"
                            ></use>
                          </svg>
                        </div>
                      )}
                      {team.position_trend === "-" && (
                        <div className="ef-league-table__prev-position ef-league-table__prev-position--down">
                          <svg className="ef-icon" width="12" height="12">
                            <use
                              href="#ef-icon--chevron-down-12"
                              xlinkHref="#ef-icon--chevron-down-12"
                            ></use>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--logo">
                    <div className="ef-league-table__logo">
                      <img
                        alt={team.team_name}
                        className="ef-league-table__logo-image"
                        src={team.team_logo_url}
                      />
                    </div>
                  </div>
                  <div
                    className="ef-league-table__cell ef-league-table__cell--team"
                    data-token={team.team_initials_name}
                  >
                    <div className="ef-league-table__team">
                      {team.team_name}
                    </div>
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--matches">
                    {team.played}
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--stats">
                    <div
                      className="ef-league-table__stats-item ef-league-table__stats-item--won"
                      data-stats={team.won}
                    >
                      {team.won}
                    </div>
                    <div
                      className="ef-league-table__stats-item ef-league-table__stats-item--drawn"
                      data-stats={team.drawn}
                    >
                      {team.drawn}
                    </div>
                    <div
                      className="ef-league-table__stats-item ef-league-table__stats-item--lost"
                      data-stats={team.lost}
                    >
                      {team.lost}
                    </div>
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--goals">
                    {team.goals}
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--difference">
                    {team.goal_balance > 0 ? "+" : ""}
                    {team.goal_balance}
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--points">
                    {team.points}
                  </div>
                  <div className="ef-league-table__cell ef-league-table__cell--form">
                    <div className="ef-league-table__form">
                      {team.trends.map((trend, index) => (
                        <>
                          {index !== 0 && (
                            <div className="ef-league-table__form-line-wrapper">
                              <div
                                className={
                                  "ef-league-table__form-line ef-league-table__form-line--" +
                                  (team.trends[index - 1].trend === "S"
                                    ? "top"
                                    : "") +
                                  (team.trends[index - 1].trend === "U"
                                    ? "middle"
                                    : "") +
                                  (team.trends[index - 1].trend === "N"
                                    ? "bottom"
                                    : "") +
                                  "-to-" +
                                  (trend.trend === "S" ? "top" : "") +
                                  (trend.trend === "U" ? "middle" : "") +
                                  (trend.trend === "N" ? "bottom" : "")
                                }
                              />
                            </div>
                          )}
                          <div
                            className={
                              "ef-league-table__form-dot" +
                              (trend.trend === "S"
                                ? " ef-league-table__form-dot--won"
                                : "") +
                              (trend.trend === "U"
                                ? " ef-league-table__form-dot--drawn"
                                : "") +
                              (trend.trend === "N"
                                ? " ef-league-table__form-dot--lost"
                                : "") +
                              " --has-tooltip"
                            }
                          >
                            <div className="ef-tooltip ef-tooltip--top ef-tooltip--auto">
                              <div className="ef-tooltip__item">
                                {trend.opponent_name}{" "}
                                {team.home_team === true ? "(H) " : "(A) "}
                                <strong>
                                  {trend.team_home_final_score}:
                                  {trend.team_away_final_score}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default OLeagueTableBlock
