import React from "react"
import sports from "../../utilities/sports"

import { getSiteUrl } from "../../../config/website-hosts/mapping"

import Slider from "react-slick"

import { useTranslation } from "react-i18next"
import ALink from "../atoms/a-link"

const OSportSliderBlock = () => {
  const { t } = useTranslation()

  let activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"

  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }
  const settings = {
    slidesToShow: 12,
    slidesToScroll: 12,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 12,
          slidesToScroll: 12,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  }

  return (
    <>
      <section className="ef-content__block ef-content__block--sport-slider">
        <Slider {...settings} className="ef-slider ef-sport-slider">
          {sports.map((sport, index) => (
            <div className="ef-slide" key={index}>
              <ALink
                className="ef-sport-card"
                link={getSiteUrl(activeEnv, sport.website)}
              >
                <div className="ef-sport-card__icon">
                  <svg className="ef-sport-card__icon-image">
                    <use xlinkHref={"#ef-sport-icon--" + sport.website} />
                  </svg>
                </div>
                <h5 className="ef-heading ef-heading--5 ef-sport-card__heading">
                  {sport.name}
                </h5>
              </ALink>
            </div>
          ))}
        </Slider>
      </section>
    </>
  )
}

export default OSportSliderBlock
