import OArticleSliderBlock from "./organisms/o-article-slider-block"
import OVideoSliderBlock from "./organisms/o-video-slider-block"
import OProductSliderBlock from "./organisms/o-product-slider-block"
import ONewsflashSliderBlock from "./organisms/o-newsflash-slider-block"
import ONewsflashSmallSliderBlock from "./organisms/o-newsflash-small-slider-block"
import OTeaserSliderBlock from "./organisms/o-teaser-slider-block"

import OArticleGridBlock from "./organisms/o-article-grid-block"
import OProductGridBlock from "./organisms/o-product-grid-block"
import ONewsflashSmallGridBlock from "./organisms/o-newsflash-small-grid-block"
import ONewsflashGridBlock from "./organisms/o-newsflash-grid-block"
import OVideoGridBlock from "./organisms/o-video-grid-block"
import OTeaserGridBlock from "./organisms/o-teaser-grid-block"

export default {
  article_slider_block: OArticleSliderBlock,
  video_slider_block: OVideoSliderBlock,
  product_slider_block: OProductSliderBlock,
  teaser_slider_block: OTeaserSliderBlock,
  newsflash_slider_block: ONewsflashSliderBlock,
  newsflash_small_slider_block: ONewsflashSmallSliderBlock,

  article_grid_block: OArticleGridBlock,
  video_grid_block: OVideoGridBlock,
  product_grid_block: OProductGridBlock,
  teaser_grid_block: OTeaserGridBlock,
  newsflash_grid_block: ONewsflashGridBlock,
  newsflash_small_grid_block: ONewsflashSmallGridBlock,
}
