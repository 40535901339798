import React from "react"

import Cards from "../_cards"

const ONewsflashGridBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--newsflash-grid">
      <div className="ef-container ef-container--l">
        <div
          className={
            "ef-card-grid ef-newsflash-grid " +
            (props.type && props.type === "wide" && " ef-newsflash-grid--wide")
          }
        >
          {props.data.card_items.map((item, index) => (
            <div className="ef-card-grid__item" key={index}>
              {Cards[props.card_content_type]
                ? React.createElement(Cards[props.card_content_type], {
                    item: item,
                    index: index,
                    uid: props.data.uid,
                    expansion: props.expansion,
                  })
                : `The ${props.card_content_type} in ${props.data.uid} was not created yet`}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ONewsflashGridBlock
