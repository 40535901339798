import React from "react"
import AImage from "../atoms/a-image"

const OImageAndTextBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--image-and-text">
      <div className="ef-container ef-container--l">
        <div className="ef-container__narrow-m">
          <div className="ef-image-and-text">
            <div
              className={
                props.data.image_align
                  ? "ef-image-and-text__image ef-image-and-text__image--" +
                    props.data.image_align
                  : "ef-image-and-text__image ef-image-and-text__image--left"
              }
            >
              <div className="ef-container__narrow-m">
                <div className="ef-media ef-media--16x9">
                  <AImage image={props.data.image} image_size="m" />
                </div>
                {props.data.image.description && (
                  <div className="ef-caption">
                    {props.data.image.description}
                  </div>
                )}
              </div>
            </div>
            <div className="ef-image-and-text__text">
              <div
                className="ef-copy-block"
                dangerouslySetInnerHTML={{ __html: props.data.text }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OImageAndTextBlock
