import React from "react"

import { clean_fullslug } from "../../utilities/utilities"
import ALink from "../atoms/a-link"
import AImage from "../atoms/a-image"

const MPlayerCard = ({ person }) => {
  return (
    <ALink
      className={
        person.manual_person === true
          ? "ef-player-card ef-player-card--simple"
          : "ef-player-card"
      }
      link={clean_fullslug(person.full_slug)}
    >
      <div className="ef-media ef-player-card__media">
        <div
          className="ef-player-card__background"
          style={{
            backgroundImage:
              "url(https://frontify-6rv2k7rq46cig4qf.eintracht.de/assets/img/patterns/molecules/player-card/player-background.jpg)",
          }}
        ></div>
        <div className="ef-player-card__branding ef-player-card__branding--back-1" />
        <div className="ef-player-card__branding ef-player-card__branding--back-2" />
        {person.transparent_image && (
          <AImage
            add_class="ef-player-card__image"
            aspectRatio="3:4"
            image={{
              alternative: person.title,
              title: person.title,
              src: person.transparent_image,
              focus_in_percent: {
                left: 50,
                top: 0,
              },
            }}
          />
        )}
        <div className="ef-player-card__branding ef-player-card__branding--front-1" />
        <div className="ef-player-card__branding ef-player-card__branding--front-2" />
      </div>
      <div className="ef-player-card__text">
        {person.jersey_number && (
          <div className="ef-player-card__number">{person.jersey_number}</div>
        )}
        <div className="ef-player-card__name">{person.title}</div>
      </div>
    </ALink>
  )
}

export default MPlayerCard
