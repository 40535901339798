import React from "react"

const OVideoBlock = props => {
  let video_code = props.data.video_code.replace(
    "<iframe",
    "<iframe class='ef-media__video'"
  )
  if (props.data.video_size === "m") {
    return (
      <section className="ef-content__block ef-content__block--video">
        <div className="ef-container ef-container--l">
          <div className="ef-container__narrow-m">
            <div
              className="ef-media ef-media--16x9"
              dangerouslySetInnerHTML={{ __html: video_code }}
            ></div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="ef-content__block ef-content__block--video">
        <div className="ef-container ef-container--l">
          <div
            className="ef-media ef-media--16x9"
            dangerouslySetInnerHTML={{ __html: video_code }}
          ></div>
        </div>
      </section>
    )
  }
}

export default OVideoBlock
