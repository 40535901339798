import React from "react"
import { concatStrings } from "../../utilities/utilities"

const AHeading = props => {
  let headline_class =
    "ef-heading--" +
    (props.headline_level ? props.headline_level.replace("h", "") : "2")
  let text_center_class

  if (props.text_center === 1) {
    text_center_class = "ef-text--center"
  } else {
    text_center_class = ""
  }

  const HTag = props.headline_level ? props.headline_level : "h2"

  return (
    <>
      {props.headline && (
        <HTag
          className={concatStrings(
            "ef-heading",
            headline_class,
            text_center_class,
            props.add_class
          )}
        >
          {props.headline}
        </HTag>
      )}
    </>
  )
}

export default AHeading
