import React from "react"
import AHeading from "../atoms/a-heading"

const OListBlock = props => {
  let list_size_class = "ef-list--" + props.data.list_size
  let list_type_class = "ef-list--" + props.data.list_type
  return (
    <section className="ef-content__block ef-content__block--list">
      <div className="ef-container ef-container--l">
        <div className="ef-copy-block">
          <AHeading headline_level="h3" headline={props.data.headline} />
          {props.data.list_type === "ordered" ? (
            <ol
              className={"ef-list " + list_size_class + " " + list_type_class}
            >
              {props.data.list_items.map((item, index) => (
                <li className="ef-list__item" key={index}>
                  {item.text}
                </li>
              ))}
            </ol>
          ) : (
            <ul
              className={"ef-list " + list_size_class + " " + list_type_class}
            >
              {props.data.list_items.map((item, index) => (
                <li className="ef-list__item" key={index}>
                  {item.text}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  )
}

export default OListBlock
