import React from "react"
import ALink from "../atoms/a-link"

const OFooterPartners = props => {
  return (
    <section className="ef-footer__section ef-footer__section--partners">
      <ALink link={props.data.link}>
        {props.data.main_partners && props.data.main_partners.length > 0 && (
          <div className="ef-partner-grid ef-partner-grid--main">
            {props.data.main_partners.map((partner, index) => (
              <div className="ef-partner-grid__item" key={index}>
                <img
                  className="ef-partner"
                  src={
                    props.website_theme === "dark"
                      ? partner.white_logo.src
                      : partner.black_logo.src
                  }
                  title={
                    props.website_theme === "dark"
                      ? partner.white_logo.title
                      : partner.black_logo.title
                  }
                  alt={
                    props.website_theme === "dark"
                      ? partner.white_logo.alternative
                      : partner.black_logo.alternative
                  }
                />
              </div>
            ))}
          </div>
        )}
        {props.data.partners && props.data.partners.length > 0 && (
          <div className="ef-partner-grid ef-partner-grid--premium">
            {props.data.partners.map((partner, index) => (
              <div
                className={"ef-partner-grid__item " + partner.logo_size}
                key={index}
              >
                <img
                  className="ef-partner"
                  src={
                    props.website_theme === "dark"
                      ? partner.white_logo.src
                      : partner.black_logo.src
                  }
                  title={
                    props.website_theme === "dark"
                      ? partner.white_logo.title
                      : partner.black_logo.title
                  }
                  alt={
                    props.website_theme === "dark"
                      ? partner.white_logo.alternative
                      : partner.black_logo.alternative
                  }
                />
              </div>
            ))}
          </div>
        )}
      </ALink>
    </section>
  )
}

export default OFooterPartners
