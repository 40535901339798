const sports = [
  {
    name: "Basketball",
    website: "basketball",
  },
  {
    name: "Boxen",
    website: "boxen",
  },
  {
    name: "Cheerleading",
    website: "cheerleading",
  },
  {
    name: "Dart",
    website: "dart",
  },
  {
    name: "Eishockey",
    website: "eishockey",
  },
  {
    name: "Eissport",
    website: "eissport",
  },
  {
    name: "eSports",
    website: "esports",
  },
  {
    name: "Fechten",
    website: "fechten",
  },
  {
    name: "Fitness & Gymnastik",
    website: "fitness",
  },
  {
    name: "Frauenfussball",
    website: "frauen",
  },
  {
    name: "Funsport",
    website: "funsport",
  },
  {
    name: "Handball",
    website: "handball",
  },
  {
    name: "Hockey",
    website: "hockey",
  },
  {
    name: "Kampfsport",
    website: "kampfsport",
  },
  {
    name: "Leichtathletik",
    website: "leichtathletik",
  },
  {
    name: "Rugby",
    website: "rugby",
  },
  {
    name: "Tanzen",
    website: "tanzen",
  },
  {
    name: "Tennis",
    website: "tennis",
  },
  {
    name: "Tischfussball",
    website: "tischfussball",
  },
  {
    name: "Tischtennis",
    website: "tischtennis",
  },
  {
    name: "Triathlon",
    website: "triathlon",
  },
  {
    name: "Turnen",
    website: "turnen",
  },
  {
    name: "Volleyball",
    website: "volleyball",
  },
  {
    name: "Ultimate Frisbee",
    website: "ultimate-frisbee",
  },
]

export default sports
