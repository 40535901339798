import React from "react"

import Cards from "../_cards"

const OArticleGridBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--article-grid">
      <div className="ef-container ef-container--l">
        <div className="ef-card-grid ef-article-grid">
          {props.data.card_items.map((item, index) => {
            return (
              <div className="ef-card-grid__item" key={index}>
                {Cards[props.card_content_type]
                  ? React.createElement(Cards[props.card_content_type], {
                      item: item,
                      index: index,
                      uid: props.data.uid,
                      expansion: props.expansion,
                    })
                  : `The ${props.card_content_type} in ${props.data.uid} was not created yet`}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default OArticleGridBlock
