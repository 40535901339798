import React from "react"
import Helmet from "react-helmet"
// provide i18n config as topmost as possible....
import "../../i18n/config"

const TLayoutScriptHelmet = () => {
  return (
    <Helmet>
      {/* <script
        type="application/javascript"
        crossorigin="anonymous"
        async={true}
        src="//player.h-cdn.com/loader.js?customer=laola1"
      /> */}
      <script
        type="application/javascript"
        async={true}
        src="//design.eintracht.de/assets/js/uiAnimations.js"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="//avplayer-cdn.sportradar.com/dist/latest/styles.css"
      />
    </Helmet>
  )
}

export default TLayoutScriptHelmet
