import React from "react"
import OArticleSliderBlock from "./o-article-slider-block"
import moment from "moment"

const OArticleSliderBlockHOC = props => {
  let mappedNews = []
  for (let i in props.news) {
    let entry = props.news[i]

    let content = JSON.parse(entry.node.content)
    let headline = content.headline
    let subline = content.teaser

    let categories = ""
    if (content.categories) {
      categories = content.categories
        .map(elem => {
          return elem.title
        })
        .join(", ")
    }

    const kickerText = `${moment(entry.node.display_datetime).format(
      "DD.MM.YYYY"
    )} / ${categories}`

    mappedNews.push({
      link: entry.node.full_slug,
      kicker: kickerText,
      headline: headline,
      subline: subline,
      image: content.images ? content.images[0] : "",
      hover_image:
        content.images && content.images.length > 2 ? content.images[1] : "",
    })
  }

  const data = { card_items: mappedNews }

  return React.createElement(OArticleSliderBlock, {
    data,
    card_content_type: props.card_content_type,
  })
}

export default OArticleSliderBlockHOC
