import React, { useContext } from "react"
import { LiveMatchContext } from "../../context/LiveMatchContext"

const OLiveTickerTeaserBlock = props => {
  const matchContext = useContext(LiveMatchContext)

  let matchData = props.matchData
  let showSkeleton = false

  if (
    matchContext &&
    matchContext.liveMatchData &&
    matchContext.liveMatchData.liveticker_active &&
    parseInt(matchData.id) === parseInt(matchContext.matchId)
  ) {
    matchData = matchContext.liveMatchData
    showSkeleton = matchContext.showSkeleton
  }

  if (!matchData || !matchData.competition || !matchData.season) {
    return <></>
  }

  const tickerEvents = matchData.ticker_events

  function dateToString(event) {
    if (event.time === null || event.time < 0) {
      return <></>
    } else {
      return (
        <span className="ef-live-ticker__time">
          {event.time}
          {event.time_additional ? " + " + event.time_additional : ""}
        </span>
      )
    }
  }

  function mapIconFromEventType(event) {
    if (!event.event_type) {
      return <></>
    }

    const iconEventMap = {
      GoalEintracht: "#ef-icon--soccer-ball-16",
      Goal: "#ef-icon--soccer-ball-16",
      Substitution: "#ef-icon--substitution-16",
      YellowCard: "#ef-icon--yellow-card-16",
      RedCard: "#ef-icon--red-card-16",
      FinalWhistle: "#ef-icon--whistle-16",
      KickoffSecondHalf: "#ef-icon--whistle-16",
      Kickoff: "#ef-icon--whistle-16",
      WhistleFirstHalf: "#ef-icon--half-time-16",
      VideoAssistant: "#ef-icon--video-assist-16",
    }

    if (event.time === null || event.time < 0) {
      return (
        <svg className="ef-icon" width={16} height={16}>
          <use xlinkHref="#ef-icon--user-16" />
        </svg>
      )
    } else {
      return (
        <svg className="ef-icon" width={16} height={16}>
          <use xlinkHref={iconEventMap[event.event_type]} />
        </svg>
      )
    }
  }

  return (
    <section className="ef-content__block ef-content__block--live-ticker">
      <div className="ef-container ef-container--l">
        {showSkeleton && (
          <div className="ef-skeleton ef-skeleton--live-ticker">
            <div className="ef-skeleton__spinner"></div>
          </div>
        )}

        {!showSkeleton && (
          <ul className="ef-live-ticker">
            {tickerEvents.map((event, index) => (
              <li
                key={index}
                className={`ef-live-ticker__item ${
                  event.event_type ? "ef-live-ticker__item--highlight" : ""
                }`}
              >
                <div className="ef-live-ticker__item-content">
                  <div className="ef-live-ticker__meta">
                    {dateToString(event)}
                    {event.event_type && (
                      <span className="ef-live-ticker__icon">
                        {mapIconFromEventType(event)}
                      </span>
                    )}
                  </div>

                  <div className="ef-live-ticker__text">
                    <div className="ef-copy-block">
                      {event.html_description && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: event.html_description,
                          }}
                        />
                      )}
                      {!event.html_description && event.description && (
                        <p>{event.description}</p>
                      )}
                      {event.image_src && (
                        <img alt={event.alt || ""} src={event.image_src} />
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}

export default OLiveTickerTeaserBlock
