import React from "react"
import ALink from "./a-link"

const ASocialLink = ({ url, name }) => {
  return (
    <ALink className="ef-player-profile__social-link" link={url}>
      <svg className="ef-icon" width={16} height={16}>
        <use
          xlinkHref={"#ef-icon--" + name + "-16"}
          href={"#ef-icon--" + name + "-16"}
        />
      </svg>
    </ALink>
  )
}

export default ASocialLink
