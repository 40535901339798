import React from "react"
import ABanner from "../atoms/a-banner"

const OStickyAdBlock = props => {
  const { ad_id_portrait = "sas_59836", ad_id_landscape } = props

  return (
    <div className="ef-mobile-sticky-ad">
      <div
        className="ef-mobile-sticky-ad__banner ef-mobile-sticky-ad__banner--portrait"
        data-label="Partner"
      >
        {ad_id_portrait && <ABanner id={ad_id_portrait} />}
      </div>
      <div
        className="ef-mobile-sticky-ad__banner ef-mobile-sticky-ad__banner--landscape"
        data-label="Partner"
      >
        {ad_id_landscape && <ABanner id={ad_id_landscape} />}
      </div>
    </div>
  )
}

export default OStickyAdBlock
