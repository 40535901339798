import React from "react"
import ABanner from "../atoms/a-banner"

const MAdCard = props => {
  return (
    <div className="ef-article-card ef-article-card--ad">
      <div className="ef-article-card__banner" data-label="Partner">
        <ABanner id={props.item.ad_code} />
      </div>
    </div>
  )
}

export default MAdCard
