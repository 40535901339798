import React from "react"

import Sliders from "../_sliders-grids"
import OExpansionBlock from "./o-expansion-block"

const OManualCollectionBlock = props => {
  let card_definition = props.data.view_type
    ? props.data.view_type.split("__")
    : []
  let card_type = card_definition[0]
  let card_content_type = card_definition[1]
  let target_type = card_type + "_" + props.data.layout_type + "_block"
  let expansion = false

  if (props.data.card_items && props.data.card_items.length > 0) {
    let check_expansion = props.data.card_items.map(function(e) {
      if (e && e.expansion_text) {
        return true
      }
      return false
    })
    if (
      props.data.layout_type === "slider" &&
      check_expansion.indexOf(true) >= 0
    ) {
      expansion = true
    }
  } else {
    return null
  }

  return (
    <>
      {props.data.layout_type && props.data.view_type && Sliders[target_type]
        ? React.createElement(Sliders[target_type], {
            uid: props.data.uid,
            data: props.data,
            card_content_type: card_content_type,
            expansion: expansion,
          })
        : `The ${props.data.layout_type} ${target_type} in ${props.data.uid} not created yet`}
      {expansion === true && <OExpansionBlock data={props.data} />}
    </>
  )

  // if (props.data.view_type === "article") {
  //   return (
  //     <>
  //       <OArticleSliderBlock data={props.data} />
  //     </>
  //   )
  // }
  // if (props.data.view_type === "newsflash") {
  //   return (
  //     <>
  //       <ONewsflashSliderBlock data={props.data} />
  //     </>
  //   )
  // }
  // if (props.data.view_type === "newsflash_small") {
  //   return (
  //     <>
  //       <ONewsflashSliderBlock data={props.data} />
  //     </>
  //   )
  // }
  // if (props.data.view_type === "video") {
  //   return (
  //     <>
  //       <OVideoSliderBlock data={props.data} />
  //     </>
  //   )
  // }
  // if (props.data.view_type === "product") {
  //   return (
  //     <>
  //       <OProductSliderBlock data={props.data} />
  //     </>
  //   )
  // }
}

export default OManualCollectionBlock
