import { Link } from "gatsby"
import React from "react"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import { period } from "../../utilities/utilities"
import ALink from "../atoms/a-link"

const OMatchesLink = props => {
  const currentMatchday = props.currentMatchday
  const match = props.match
  const teamHome = props.match.team_home
  const teamAway = props.match.team_away
  const website = props.website

  return (
    <div
      key={match.id}
      className={
        "ef-match" + (match.matchday === currentMatchday ? " --is-current" : "")
      }
    >
      {(website === "profis" ||
        ((website === "frauen" ||
          website === "nachwuchs" ||
          website === "en") &&
          match.period === period.FULL_TIME)) && (
        <Link className="ef-match__link" to={match.full_slug + "/"} />
      )}
      <div className="ef-match__details">
        <div className="ef-match__detail">
          {props.competition
            ? ""
            : (match.competition.short_title || match.competition.title) +
              " / "}
          {match.matchday_title}
        </div>
        <div className="ef-match__detail">
          <Moment
            format={
              !match.definite && match.period !== period.FULL_TIME
                ? DATE_TIME_FORMATS.date_w_day_leading_zeros
                : DATE_TIME_FORMATS.extended
            }
          >
            {match.kickoff}
          </Moment>
          {!match.definite && match.period !== period.FULL_TIME && ""}
        </div>
      </div>
      <div className="ef-match__team-logo ef-match__team-logo--home">
        <img
          alt={teamHome.team_name}
          className="ef-match__team-logo-image"
          src={teamHome.images.medium_square}
        />
      </div>
      <div
        className="ef-match__team-name ef-match__team-name--home"
        data-shortname={teamHome.team_initials_name}
      >
        <span className="ef-match__team-name-text">{teamHome.team_name}</span>
      </div>
      <div className="ef-match__result">
        {match.team_home_final_score != null
          ? match.team_home_final_score
          : "–"}
        :
        {match.team_away_final_score != null
          ? match.team_away_final_score
          : "–"}
      </div>
      <div className="ef-match__team-logo ef-match__team-logo--away">
        <img
          alt={teamAway.team_name}
          className="ef-match__team-logo-image"
          src={teamAway.images.medium_square}
        />
      </div>
      <div
        className="ef-match__team-name ef-match__team-name--away"
        data-shortname={teamAway.team_initials_name}
      >
        <span className="ef-match__team-name-text">{teamAway.team_name}</span>
      </div>
      {match.ticketLink && (
        <div className="ef-match__button">
          <ALink className="ef-link-button ef-link-button--ticket" link="/">
            Tickets
          </ALink>
        </div>
      )}
      {(website === "profis" ||
        ((website === "frauen" ||
          website === "nachwuchs" ||
          website === "en") &&
          match.period === period.FULL_TIME)) &&
        match.full_slug && (
          <div className="ef-match__arrow">
            <svg className="ef-icon" width="16" height="16">
              <use
                href="#ef-icon--arrow-right-16"
                xlinkHref="#ef-icon--arrow-right-16"
              ></use>
            </svg>
          </div>
        )}
    </div>
  )
}

export default OMatchesLink
