import React from "react"
import ATimelineEvents from "../atoms/a-timeline-events"
import { matchLength, MAX_WIDTH_TIMELINE } from "../../utilities/utilities"
import { useTranslation } from "react-i18next"

const OTimelineBlock = props => {
  const { t } = useTranslation()
  if (!props) {
    return <></>
  }

  const period = props.match.period
  const optaEvents = props.match.opta_events

  const tickerEvents = props.match.ticker_events
  const lastTickerEvent = tickerEvents
    ? tickerEvents.find(elem => elem.time !== null && elem.time > 0)
    : null

  const currentMatchLength =
    props.match.team_away_extra_score === null &&
    props.match.team_home_extra_score === null
      ? matchLength.FULL_TIME
      : matchLength.EXTRA_FULL_TIME

  const eventTimeRelative = lastTickerEvent
    ? (lastTickerEvent.time / currentMatchLength) * 100
    : null
  const progress =
    period === "FullTime"
      ? "100"
      : Math.min(eventTimeRelative, MAX_WIDTH_TIMELINE)

  return (
    <div className="ef-timeline">
      <div className="ef-timeline__bar">
        <div
          className="ef-timeline__progress"
          data-progress={progress}
          style={{
            width: `${progress}%`,
          }}
        />
        <div className="ef-timeline__markers">
          <div className="ef-timeline__marker">0</div>
          <div className="ef-timeline__marker">15</div>
          <div className="ef-timeline__marker">30</div>
          <div className="ef-timeline__marker">HZ</div>
          <div className="ef-timeline__marker">60</div>
          <div className="ef-timeline__marker">75</div>
          <div className="ef-timeline__marker">90</div>
          {currentMatchLength === matchLength.EXTRA_FULL_TIME && (
            <>
              <div className="ef-timeline__marker">105</div>
              <div className="ef-timeline__marker">120</div>
            </>
          )}
        </div>
      </div>
      <div className="ef-timeline__events">
        <ATimelineEvents
          currentMatchLength={currentMatchLength}
          match={props.match}
          optaEvents={optaEvents}
        />
      </div>
    </div>
  )
}

export default OTimelineBlock
