import React from "react"
import Moment from "react-moment"
import Slider from "react-slick"
import { clean_fullslug } from "../../utilities/utilities"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import { useTranslation } from "react-i18next"
import ALink from "../atoms/a-link"
import moment from "moment"

const OMatchSliderBlock = props => {
  const { t } = useTranslation()

  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }

  const nextMatchIdx = props.data.matches.findIndex(
    o => moment(o.kickoff) > moment()
  )

  const settings = {
    slidesToShow: 8,
    slidesToScroll: 7,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,
    variableWidth: false,
    initialSlide: nextMatchIdx - 1,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section className="ef-content__block ef-content__block--match-slider">
      <Slider {...settings} className="ef-slider ef-match-slider">
        <div className="ef-slide" key="0"></div>
        {props.data.matches.map((item, index) => (
          <div className="ef-slide" key={index + 1}>
            <div className="ef-match-card ef-match-card--future">
              <a
                className="ef-match-card__link"
                href={item.full_slug || "#"}
                tabIndex={-1}
              ></a>
              <div className="ef-match-card__teams">
                <span className="ef-match-card__team ef-match-card__team--home">
                  <img
                    className="ef-match-card__team-logo"
                    src={item.team_home.images.mini_square}
                    alt={item.team_home.team_name}
                  />
                  <span className="ef-match-card__team-name">
                    {item.team_home.team_initials_name}
                  </span>
                </span>
                <span className="ef-match-card__result">
                  {item.team_home_final_score != null
                    ? item.team_home_final_score
                    : "–"}
                  :
                  {item.team_away_final_score != null
                    ? item.team_away_final_score
                    : "–"}
                </span>
                <span className="ef-match-card__team ef-match-card__team--away">
                  <span className="ef-match-card__team-name">
                    {item.team_away.team_initials_name}
                  </span>
                  <img
                    className="ef-match-card__team-logo"
                    src={item.team_away.images.mini_square}
                    alt={item.team_away.team_name}
                  />
                </span>
              </div>
              <div className="ef-match-card__data">
                <div className="ef-match-card__data-items">
                  <span className="ef-match-card__competition">
                    {item.competition.short_title}
                  </span>
                  <span className="ef-match-card__date-time">
                    <Moment format={DATE_TIME_FORMATS.default_w_time}>
                      {item.kickoff}
                    </Moment>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="ef-match-table-link">
        <div className="ef-match-table-link__grid ef-match-table-link__grid--matches">
          <div className="ef-match-card ef-match-card--all">
            <ALink
              className="ef-match-card__link"
              link={props.data.standingsLinkFullSlug}
            />
            <div className="ef-link-button ef-link-button--more">
              {t("Dropdowns.AllMatches")}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OMatchSliderBlock
