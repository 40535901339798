import React from "react"

import { concatStrings } from "../../utilities/utilities"

const OInfoBoxBlock = props => {
  return (
    <section className="ef-content__section ef-content__block--info-box">
      <div className="ef-container ef-container--l">
        <div
          className={concatStrings(
            "ef-info-box",
            props.data.text_centered === 1 && "ef-info-box--center"
          )}
        >
          <div
            className="ef-copy-block"
            dangerouslySetInnerHTML={{ __html: props.data.text }}
          ></div>
        </div>
      </div>
    </section>
  )
}

export default OInfoBoxBlock
