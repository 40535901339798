import ORichtextBlock from "./organisms/o-richtext-block"
import OHeadingBlock from "./organisms/o-heading-block"
import OInfoBoxGridBlock from "./organisms/o-info-box-grid-block"
import OInfoBoxBlock from "./organisms/o-info-box-block"
import OCtaButtonBlock from "./organisms/o-cta-button-block"
import OBlockquoteBlock from "./organisms/o-blockquote-block"
import OImageBlock from "./organisms/o-image-block"
import ODataTableBlock from "./organisms/o-data-table-block"
import OGallerySliderBlock from "./organisms/o-gallery-slider-block"
import OListBlock from "./organisms/o-list-block"
import ODownloadBlock from "./organisms/o-downloads-block"
import OPersonGridBlock from "./organisms/o-person-grid-block"
import OEmbedBlock from "./organisms/o-embed-block"
import OVideoBlock from "./organisms/o-video-block"
import OAccordionBlock from "./organisms/o-accordion-block"
import OSectionHeaderBlock from "./organisms/o-section-header-block"
import OManualCollectionBlock from "./organisms/o-manual-collection-block"
import OSelectionCollectionBlock from "./organisms/o-selection-collection-block"
import OQuotesBlock from "./organisms/o-quotes-block"
import OArticleSliderBlock from "./organisms/o-article-slider-block"
import OImageAndTextBlock from "./organisms/o-image-and-text-block"
import OSeparatorBlock from "./organisms/o-separator-block"
import OEmbedHtmlBlock from "./organisms/o-embed-html-block"
import OSportGridBlock from "./organisms/o-sport-grid-block"
import OSportSliderBlock from "./organisms/o-sport-slider-block"
import OJobfinderBlock from "./organisms/o-jobfinder-block"
import OStadiumAdBlock from "./organisms/o-stadium-ad-block"
import OProfisMatchesBlock from "./organisms/o-profis-matches-block"
import OProfisTableBlock from "./organisms/o-profis-table-block"
import OProfisLineupBlock from "./organisms/o-profis-lineup-block"
import OMatchHeader, { OMatchHeaderHOC } from "./organisms/o-match-header"

export default {
  richtext_block: ORichtextBlock,
  heading_block: OHeadingBlock,
  info_box_grid_block: OInfoBoxGridBlock,
  info_box_block: OInfoBoxBlock,
  cta_button_block: OCtaButtonBlock,
  blockquote_block: OBlockquoteBlock,
  image_block: OImageBlock,
  gallery_slider_block: OGallerySliderBlock,
  table: ODataTableBlock,
  list_block: OListBlock,
  downloads_block: ODownloadBlock,
  person_grid_block: OPersonGridBlock,
  embed_block: OEmbedBlock,
  video_block: OVideoBlock,
  accordion_block: OAccordionBlock,
  section_header_block: OSectionHeaderBlock,
  manual_collection_block: OManualCollectionBlock,
  selection_collection_block: OSelectionCollectionBlock,
  quotes_block: OQuotesBlock,
  article_slider_block: OArticleSliderBlock,
  image_and_text_block: OImageAndTextBlock,
  separator_block: OSeparatorBlock,
  html: OEmbedHtmlBlock,
  sport_grid_block: OSportGridBlock,
  sport_slider_block: OSportSliderBlock,
  profis_matches_block: OProfisMatchesBlock,
  profis_table_block: OProfisTableBlock,
  profis_lineup_block: OProfisLineupBlock,
  jobfinder_block: OJobfinderBlock,
  stadium_ad_block: OStadiumAdBlock,
  profis_livematch_block: OMatchHeaderHOC,
}
