import React from "react"

import Slider from "react-slick"

import { useTranslation } from "react-i18next"
import ALink from "../atoms/a-link"

const OTableSliderBlock = props => {
  const { t } = useTranslation()
  const eintrachtStandingIndex =
    props.data.standings && props.data.standings.full
      ? props.data.standings.full.findIndex(
          standing => standing.team_id === 159
        )
      : -1

  function EfNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--next " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Prev
      </span>
    )
  }

  function EfPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <span
        className={"ef-slider__arrow ef-slider__arrow--prev " + className}
        style={{ ...style }}
        onClick={onClick}
      >
        Next
      </span>
    )
  }

  const settings = {
    slidesToShow: 8,
    slidesToScroll: 7,
    arrows: true,
    prevArrow: <EfPrevArrow />,
    nextArrow: <EfNextArrow />,
    mobileFirst: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,
    variableWidth: false,
    initialSlide: eintrachtStandingIndex - 1,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section className="ef-content__block ef-content__block--table-slider-after-matches">
      <Slider {...settings} className="ef-slider ef-match-slider">
        <div className="ef-slide" key="0"></div>
        {props.data.standings.full.map((item, index) => (
          <div className="ef-slide" key={index + 1}>
            <div
              className={
                "ef-table-card " +
                (item.team_id === 159
                  ? "ef-table-card--current"
                  : "ef-table-card--neighbor")
              }
            >
              <div className="ef-table-card__link" href="#" />
              <div className="ef-table-card__team">
                <span className="ef-table-card__team-position">
                  {item.position}
                </span>
                <img
                  className="ef-table-card__team-logo"
                  src={item.team_logo_url}
                  alt="{item.team_initials_name}"
                />
                <span className="ef-table-card__team-name">
                  {item.team_initials_name}
                </span>
              </div>
              <div className="ef-table-card__stats">
                <span className="ef-table-card__goals">
                  {item.goal_balance}
                </span>
                <span className="ef-table-card__points">{item.points} P.</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="ef-match-table-link">
        <div className="ef-match-table-link__grid ef-match-table-link__grid--table">
          <div className="ef-table-card ef-table-card--all">
            <ALink
              className="ef-match-card__link"
              link={props.data.standingsLink}
            ></ALink>
            <div className="ef-link-button ef-link-button--more">
              {t("Table")}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OTableSliderBlock
