import React from "react"

const OSeparatorBlock = () => {
  return (
    <section className="ef-content__block ef-content__block--separator">
      <div className="ef-container ef-container--l">
        <div className="ef-container__narrow-s">
          <div className="ef-separator" />
        </div>
      </div>
    </section>
  )
}

export default OSeparatorBlock
