import React, { useContext } from "react"
import { LayoutContext } from "../organisms/o-layout"
import OPlayerSliderBlock from "../organisms/o-player-slider-block"

const TEintrachtKader = () => {
  const layoutContext = useContext(LayoutContext)

  if (!layoutContext.startPageData) {
    return null
  }

  const data = layoutContext.startPageData

  const people =
    data.squad.edges.length > 0 ? data.squad.edges[0].node.data.players : []

  for (let i in people) {
    // PersonData ist die Typo3 Seite des Spielers (wir brauchen das transparente Bild)
    let personData = false
    personData = data.squadImages.edges.filter(
      edge =>
        edge.node.data !== null &&
        edge.node.data.person_uid === people[i]["person"]["uid"]
    )

    if (personData && personData[0] && personData[0].node) {
      people[i]["person"] = Object.assign(people[i]["person"], {
        transparent_image: personData[0].node.data.transparent_image,
      })
    } else {
      people[i]["person"] = Object.assign(people[i]["person"], {
        transparent_image: "",
      })
    }
  }

  people.sort((p1, p2) => {
    return p1.person.jersey_number - p2.person.jersey_number
  })

  return <OPlayerSliderBlock people={people} />
}

export default TEintrachtKader
