import React, { useState } from "react"
import { concatStrings } from "../../utilities/utilities"
import OMenuPrimary from "./o-menu-primary"
import OMenuSecondary from "./o-menu-secondary"

const OMenu = ({ tree, website, lang, toggleVisibleOverlayType }) => {
  const treeState = () => {
    tree.find(entry => {
      if (entry.website === website) {
        return entry.website
      } else {
        return null
      }
    })
  }
  // Add additional primary menu item for Sportarten
  const sportsTree = tree.find(i => i.website === "sportarten").children
  if (!tree.map(i => i.website).includes(website)) {
    let currentSport = sportsTree.find(i => i.website === website)
    if (currentSport) {
      tree.push(currentSport)
    }
  }
  // ---------

  const [currentWebsite, setCurrentWebsite] = useState(
    treeState.website || website
  )
  const currentWebsiteTree = tree.find(i => i.website === currentWebsite)
  const currentWebsiteChildren = currentWebsiteTree
    ? currentWebsiteTree.children
    : []
  const currentWebsiteTitle = currentWebsiteTree ? currentWebsiteTree.title : ""

  // console.log("Tree", tree)

  const treeCurrentLanguage = tree.filter(i => i.language === lang)

  return (
    <nav
      className={concatStrings("ef-menu", "--is-active")}
      onClick={() => toggleVisibleOverlayType("")}
    >
      <OMenuPrimary
        tree={treeCurrentLanguage}
        website={website}
        currentWebsite={currentWebsite}
        setCurrentWebsite={setCurrentWebsite}
      />
      <OMenuSecondary
        website={website}
        tree={currentWebsiteChildren}
        currentWebsite={currentWebsite}
        setCurrentWebsite={setCurrentWebsite}
        currentWebsiteTitle={currentWebsiteTitle}
        useInternalLinks={website === currentWebsite}
      />
    </nav>
  )
}

export default OMenu
