import React from "react"

const OBlockquoteBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--blockquote">
      <div className="ef-container ef-container--l">
        <blockquote className="ef-blockquote">
          <p className="ef-blockquote__quote">{props.data.quote}</p>
          <cite className="ef-blockquote__cite">{props.data.quote_source}</cite>
        </blockquote>
      </div>
    </section>
  )
}

export default OBlockquoteBlock
