import { graphql } from "gatsby"
import React from "react"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import OSearchResults from "gatsby-component-lib/components-legacy/organisms/o-search-results"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"

export const SEARCH_PAGE_URL = "/suche"

export const query = graphql`
  query {
    tree: allTypo3Page(
      filter: { type: { eq: "navigation" }, slug: { eq: "left" } }
    ) {
      edges {
        node {
          children {
            id
          }
          content
          website
          slug
        }
      }
    }
    footer: allTypo3Page(filter: { type: { eq: "footer_page" } }) {
      edges {
        node {
          content
        }
      }
    }
  }
`

const SearchPage = ({ data, pageContext }) => {
  const tree = JSON.parse(data.tree.edges[0].node.content).tree
  const footer = data.footer.edges[0]
    ? JSON.parse(data.footer.edges[0].node.content)
    : null
  return (
    <OLayout
      tree={tree}
      website={pageContext.website}
      website_lang={pageContext.website_lang}
      isSearchPage={true}
      footerPageNodeContent={footer}
    >
      <TSeo
        metadata=""
        title="Suche"
        website={pageContext.website}
        website_theme={pageContext.website_theme}
      />
      <main className="ef-content ef-content--search-results">
        <OSearchResults />
      </main>
    </OLayout>
  )
}

export default SearchPage
