import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import { useTranslation } from "react-i18next"

function TSeo({
  website,
  website_theme,
  website_lang = "de",
  invert_theme = 0,
  content,
  full_slug,
  title,
  description,
  keywords = [],
  og_title,
  og_description,
  og_type,
  og_url,
  og_image,
  og_image_width,
  og_image_height,
  article_published_time,
  article_modified_time,
  og_locale,
  twitter_card,
  twitter_title,
  twitter_description,
}) {
  if (website_theme !== "dark" && website_theme !== "light") {
    if (website) {
      website_theme = ["profis", "museum"].includes(website) ? "dark" : "light"
    } else {
      website_theme = "light"
    }
  }

  invert_theme =
    content && content.invert_theme ? content.invert_theme : invert_theme

  if (invert_theme === 1) {
    website_theme = website_theme === "dark" ? "light" : "dark"
  }

  const bestMatch = array => {
    return array.find(e => e !== undefined && e !== "")
  }

  // Einmal "c" vorbereiten, um unten nicht stätig prüfen zu müssen, ob content übergeben wurde
  const c =
    content && Object.keys(content).length > 0 ? content : { metadata: {} }
  const m = c.metadata ? c.metadata : {}

  const og_site_name = "Eintracht Frankfurt"
  const twitter_creator = "@eintracht"

  let data = []

  const { t } = useTranslation()

  // SEO Variablen (einige sind optional)
  data.push({
    name: `title`,
    content: `${bestMatch([title, m.title, c.title])} - ${website &&
      t("Websites." + website)} - ${t("SeoTitleSuffix")}`,
  })
  data.push({
    name: `description`,
    content: bestMatch([description, m.description]),
  })
  data.push({
    property: `msapplication-TileColor`,
    content: `#000000`,
  })
  data.push({
    property: `og:site_name`,
    content: og_site_name,
  })
  data.push({
    property: `og:title`,
    content: bestMatch([og_title, m.ogtitle, m.title, title, c.title]),
  })
  data.push({
    property: `og:description`,
    content: bestMatch([
      og_description,
      m.ogdescription,
      description,
      m.description,
    ]),
  })
  data.push({
    property: `og:type`,
    content: og_type ? og_type : "website",
  })
  data.push({
    property: `og:url`,
    content: bestMatch([og_url, full_slug]),
  })
  data.push({
    property: `og:image`,
    content: bestMatch([
      og_image,
      m.ogimage && m.ogimage.src ? m.ogimage.src : "",
    ]),
  })
  data.push({
    property: `og:image:width`,
    content: og_image_width,
  })
  data.push({
    property: `og:image:height`,
    content: og_image_height,
  })
  data.push({
    property: `article:published_time`,
    content: article_published_time,
  })
  data.push({
    property: `article:modified_time`,
    content: article_modified_time,
  })
  data.push({
    property: `og:locale`,
    content: bestMatch([og_locale, c.locale]),
  })
  data.push({
    property: `twitter:card`,
    content: twitter_card,
  })
  data.push({
    property: `twitter:title`,
    content: bestMatch([
      twitter_title,
      og_title,
      m.ogtitle,
      m.title,
      title,
      c.title,
    ]),
  })
  data.push({
    property: `twitter:description`,
    content: bestMatch([
      twitter_description,
      og_description,
      m.ogdescription,
      description,
      m.description,
    ]),
  })
  data.push({
    property: `twitter:site`,
    content: twitter_creator,
  })
  data.push({
    property: `twitter:creator`,
    content: twitter_creator,
  })

  data = data.filter(x => x.content && x.content !== undefined)

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++

  // Falls 'keywords' als String kommen
  // if (keywords.includes(",")) {
  //   keywords = keywords.split(",")
  // }

  // SEO Konstanten
  // SEO Variablen (einige sind optional)

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++

  return (
    <Helmet
      htmlAttributes={{
        lang: website_lang,
      }}
      bodyAttributes={{
        "data-theme": website_theme,
        "data-branding": "2019-20",
      }}
      title={`${bestMatch([title, m.title, c.title])} - ${website &&
        t("Websites." + website)} - ${t("SeoTitleSuffix")}`}
      //titleTemplate={`%s | Eintracht Frankfurt`}
      meta={data.concat()}
    />
  )
}

TSeo.defaultProps = {
  lang: `website_lang`,
  meta: [],
  description: ``,
}

TSeo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default TSeo
