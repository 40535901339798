import React, { useState } from "react"
import { Image } from "cloudinary-react"

const AImage = ({
  image,
  aspectRatio = "16:9",
  defaultClass = "true",
  useDefault = "false",
  add_class,
}) => {
  const { focus_in_percent, alternative, title, src } = image

  const fallback = {
    src:
      "https://media.eintracht.de/image/upload/v1580827612/ef-placeholder-ed2a.jpg",
    alternative: "Ein Platzhalter Bild mit dem Eintracht Logo",
    title: "Eintracht Platzhalter-Bild",
  }

  const attributes = {
    quality: "auto",
    fetchFormat: "auto",
    crop: "fill",
    aspectRatio,
    width: "auto",
    dpr: "auto",
    responsive: true,
    alt: alternative,
    title,
  }

  if (focus_in_percent) {
    attributes.x = `w_mul_${focus_in_percent.left / 100}`
    attributes.y = `w_mul_${focus_in_percent.top / 100}`
    attributes.gravity = "xy_center"
  } else if (useDefault === "true") {
    attributes.alt = fallback.alternative
    attributes.title = fallback.title
    attributes.gravity = "center"
  } else {
    attributes.gravity = "auto"
  }

  const regex = /https:\/\/media\.eintracht\.de\/image\/upload\/[^/]+\/(.+)/
  const match = src
    ? src.match(regex)
    : useDefault === "true"
    ? fallback.src.match(regex)
    : null
  const publicId = match ? match[1] : src

  // Beispiel URL: https://media.eintracht.de/image/upload/ar_16:9,c_fill,g_xy_center,x_w_mul_0.58,y_h_mul_0.36/v1576769208/harley-davidson-npzr4g67ev8-unsplash-a174.jpg

  // hide broken image icon on chrome
  const [imageStyle, setImageStyle] = useState({ visibility: "hidden" })
  const onImageLoaded = () => {
    setImageStyle({ visibility: "visible" })
  }

  return publicId ? (
    <Image
      secureDistribution="media.eintracht.de"
      cname="media.eintracht.de"
      cloudName="eintracht-frankfurt"
      privateCdn
      secure
      responsiveUseBreakpoints="true"
      publicId={publicId}
      onLoad={onImageLoaded}
      className={
        (defaultClass === "true" ? "ef-media__image " : "") + add_class
      }
      style={imageStyle}
      {...attributes}
    />
  ) : (
    <></>
  )
}

export default AImage
