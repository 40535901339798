import React, { useState, useContext } from "react"
import Modal from "react-modal"

import AImage from "../atoms/a-image"
import AHeading from "../atoms/a-heading"

import Axios from "axios"
import { ScriptContext, ScriptServices } from "../technical/t-script-tags"
import { useCookies } from "react-cookie"
import { SESSION_URL } from "./m-video-detail-header"
import Moment from "react-moment"
import { DATE_TIME_FORMATS } from "../../utilities/date-time-formats"
import ALink from "../atoms/a-link"

const MVideoCard = props => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const scriptContext = useContext(ScriptContext)

  const [cookies] = useCookies(["yum_jwt"])
  // const [authenticated, setAuthenticated] = useState(false)

  const openModal = e => {
    e.preventDefault()
    setIsOpen(true)
  }

  const closeModal = e => {
    e.preventDefault()
    setIsOpen(false)
  }

  const afterOpenModal = () => {
    const [successPlayer, errorPlayer] = scriptContext[ScriptServices.AVP]

    if (props.item.videoUID && successPlayer && !errorPlayer) {
      let headers = null
      if (cookies && cookies.yum_jwt) {
        headers = {
          headers: {
            Authorization: "Bearer " + cookies.yum_jwt,
          },
        }
        // setAuthenticated(true)
      }

      let config = {
        id: "playercontainer2",
        configUrl: `https://eintracht-tv.spott2.sportradar.com/api/v2/content/${props.item.videoUID}/player-setting?portal=at&autoplay=true&enableProgressBar=true&enableTime=true&enableSeekForward=true&enableSeekBehind=true&language=de&showTitle=true`,
        allowDVR: true,
      }

      Axios.post(SESSION_URL, null, headers)
        .then(function(response) {
          const data = response.data
          const desiredEndTime = data.desired_end_time

          if (data.heartbeat === true) {
            config.heartbeat = {
              enabled: true,
              time: 300,
              url: data.heartbeat_url,
              method: "GET",
            }
          }

          // authorization for registered users
          if (headers) {
            config.streamAccessQueryParameters = `authorization_code=${data.jwt_token}`
          }

          // 30 seconds preview for anonymous
          if (desiredEndTime) {
            // desiredEndTime for pay-videos, only
            if (props.item.payment === "pay") {
              config.desiredEndTime = desiredEndTime
            }
            config.streamAccessQueryParameters = `authorization_code=${data.jwt_token}`
          }

          //eslint-disable-next-line no-undef
          new avvpl.setupPlayer(config)
        })
        .catch(error => {
          console.log("error", error)
        })
    }
  }

  return (
    <>
      <div className="ef-video-card">
        <ALink
          className="ef-video-card__link"
          link={props.item && props.item.link ? props.item.link : null}
        ></ALink>
        <div className="ef-media ef-video-card__media">
          {props.item && props.item.image && (
            <AImage
              add_className="ef-video-card__image"
              image_size="card_l"
              image={props.item.image}
              useDefault="true"
            />
          )}
          {props.item && props.item.video_url && (
            <video
              className="ef-media__video ef-video-card__video"
              width={640}
              height={360}
              loop={true}
              muted={true}
              // onMouseOver={this.play()}
              // onMouseOut={this.pause()}
            >
              <source type="video/webm" src={props.item.video_url} />
              <source type="video/mp4" src={props.item.video_url} />
            </video>
          )}
        </div>
        {props.item.payment === "pay" && (
          <div className="ef-plus-badge ef-plus-badge--card ef-plus-badge--tv"></div>
        )}
        <a className="ef-video-card__icon" href="#" onClick={e => openModal(e)}>
          <svg className="ef-icon" width="32" height="32">
            <use href="#ef-icon--play-32" xlinkHref="#ef-icon--play-32"></use>
          </svg>
        </a>
        <div className="ef-video-card__text">
          <div className="ef-kicker ef-video-card__kicker">
            {props.item.kicker}
          </div>

          {props.item && props.item.headline && (
            <AHeading
              headline_level="h5"
              headline={props.item.headline}
              add_className="ef-heading--has-kicker ef-video-card__heading"
            />
          )}

          <p className="ef-paragraph ef-text--s ef-video-card__description">
            {props.item.teaser}
          </p>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        className="ef-gallery-modal"
        overlayClassName="ef-gallery-modal"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.75)",
          },
          content: {
            backgroundColor: "transparent",
          },
        }}
      >
        <div className="ef-gallery-modal__close" onClick={e => closeModal(e)}>
          <svg className="ef-icon" width="16" height="16">
            <use href="#ef-icon--x-16" xlinkHref="#ef-icon--x-16"></use>
          </svg>
        </div>
        <div className="ef-gallery-modal__inner">
          <div className="ef-container ef-container--l">
            <div className="ef-media ef-media--16x9">
              <div id="playercontainer2"></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MVideoCard
