import React from "react"
import ALink from "../atoms/a-link"

const OTagsBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--tags">
      <div className="ef-container ef-container--l">
        <div className="ef-container__narrow-m">
          <ul className="ef-tags">
            {props.data.tags.map((item, index) => (
              <li className="ef-tag" key={index}>
                <span link={item.slug} className="ef-tag__link">
                  {item.title}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default OTagsBlock
