import React from "react"
import ALink from "../atoms/a-link"

const OFooterContact = props => {
  return (
    <section className="ef-footer__section ef-footer__section--contact">
      <div className="ef-footer__contact-grid">
        <div className="ef-footer__contact-grid-item ef-footer__contact-grid-item--address">
          <div
            className="ef-footer__address"
            dangerouslySetInnerHTML={{
              __html: props.data.column1_text,
            }}
          ></div>
          {props.data.social_links && props.data.social_links.length > 0 && (
            <div className="ef-footer__social-links">
              {props.data.social_links.map((social, index) => (
                <ALink
                  className="ef-footer__social-link"
                  link={social.link}
                  key={index}
                >
                  <svg className="ef-icon" width={16} height={16}>
                    <use
                      xlinkHref={"#ef-icon--" + social.social_icon + "-16"}
                    />
                  </svg>
                </ALink>
              ))}
            </div>
          )}
        </div>
        <div
          className="ef-footer__contact-grid-item ef-footer__contact-grid-item--phone"
          dangerouslySetInnerHTML={{
            __html: props.data.column2_text,
          }}
        ></div>
        <div
          className="ef-footer__contact-grid-item ef-footer__contact-grid-item--phone"
          dangerouslySetInnerHTML={{
            __html: props.data.column3_text,
          }}
        ></div>
      </div>
    </section>
  )
}

export default OFooterContact
