import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ALink from "../atoms/a-link"
import { getSiteUrl } from "../../../config/website-hosts/mapping"

const OMenuSecondary = ({
  currentWebsiteTitle,
  tree,
  currentWebsite,
  setCurrentWebsite,
  useInternalLinks,
}) => {
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"

  const data = useStaticQuery(graphql`
    query {
      squad: allTypo3Page(filter: { uid: { eq: "team-159-2019-de" } }) {
        edges {
          node {
            uid
            slug
            full_slug
            type
            name
            data {
              season {
                id
                title
                slug
              }
              players {
                person {
                  id
                  uid
                  title
                  slug
                  full_slug
                  position
                  jersey_number
                }
              }
            }
          }
        }
      }
    }
  `)

  const players =
    data.squad.edges.length > 0 ? data.squad.edges[0].node.data.players : []
  const playersByPosition = {
    Goalkeeper: [],
    Defender: [],
    Midfielder: [],
    Forward: [],
  }
  players.map(p => {
    playersByPosition[p.person.position].push(p.person)
    playersByPosition[p.person.position].sort((p1, p2) => {
      return p1.jersey_number - p2.jersey_number
    })
  })

  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(false)

  if (!tree || currentWebsite === "sportarten" || currentWebsite === "") {
    return <div />
  }

  return (
    <section
      className="ef-secondary-menu --is-active"
      data-theme="light"
      onClick={event => event.stopPropagation()}
    >
      <div className="ef-menu__back">
        <div
          className="ef-link-button ef-link-button--back"
          onClick={() => setCurrentWebsite("")}
        >
          Zurück
        </div>
      </div>

      <ul className="ef-secondary-menu__items --is-active">
        <div className="ef-secondary-menu__inner">
          {currentWebsiteTitle && currentWebsiteTitle.length > 0 && (
            <header className="ef-menu__items-header">
              <h4 className="ef-heading ef-heading--4 ef-menu__items-heading">
                <ALink link={{ url: "/", website: currentWebsite }}>
                  {currentWebsiteTitle}
                </ALink>
              </h4>
            </header>
          )}
          {tree.map((item, key) => (
            <li className="ef-secondary-menu__item" key={key}>
              {useInternalLinks && !item.external_url ? (
                <Link
                  to={item.full_slug}
                  activeClassName="--is-current"
                  className="ef-secondary-menu__item-link"
                >
                  {item.title}
                </Link>
              ) : (
                <a
                  className="ef-secondary-menu__item-link"
                  href={
                    item.external_url
                      ? item.external_url
                      : getSiteUrl(activeEnv, item.website) + item.full_slug
                  }
                >
                  {item.title}
                </a>
              )}
              {item.children && item.children.length > 0 ? (
                <>
                  <ul className="ef-secondary-menu__subitems">
                    {item.children.map(subitem => (
                      <>
                        {subitem.uid !== "page-7299-de" && (
                          <li
                            className="ef-secondary-menu__subitem"
                            key={subitem.uid}
                          >
                            {useInternalLinks && !subitem.external_url ? (
                              <Link
                                to={subitem.full_slug}
                                className="ef-secondary-menu__subitem-link"
                                activeClassName="--is-current"
                              >
                                {subitem.title}
                              </Link>
                            ) : (
                              <a
                                key={subitem.uid}
                                className="ef-secondary-menu__subitem-link"
                                href={
                                  subitem.external_url
                                    ? subitem.external_url
                                    : getSiteUrl(activeEnv, subitem.website) +
                                      subitem.full_slug
                                }
                              >
                                {subitem.title}
                              </a>
                            )}
                          </li>
                        )}
                        {subitem.uid === "page-7299-de" && (
                          <>
                            <li
                              className={
                                "ef-secondary-menu__subitem --is-expandable" +
                                (isActive ? " --is-active" : "")
                              }
                              data-menu-expansion-name="players"
                            >
                              <a
                                href="#"
                                className="ef-secondary-menu__subitem-link"
                                onClick={event => {
                                  setIsActive(!isActive)
                                  event.stopPropagation()
                                }}
                              >
                                {t("MenuSecondary.All")}
                              </a>
                              <div className="ef-secondary-menu__subitem-expander"></div>
                            </li>
                          </>
                        )}
                      </>
                    ))}
                  </ul>
                  {item.uid === "page-7294-de" && (
                    <div
                      className={
                        "ef-secondary-menu__expansion" +
                        (isActive ? " --is-expanded" : "")
                      }
                      data-menu-expansion="players"
                    >
                      <div className="ef-secondary-menu__expansion-inner">
                        {Object.keys(playersByPosition).map(
                          (key,
                          value => {
                            let positionLabel = value
                            let playerList = playersByPosition[value]
                            return (
                              <>
                                <h6 className="ef-secondary-menu__expansion-heading">
                                  {t(`MenuSecondary.${positionLabel}`)}
                                </h6>
                                <ul className="ef-secondary-menu__expansion-items">
                                  {Object.values(playerList).map(p => (
                                    <li
                                      key={p.jersey_number}
                                      className="ef-secondary-menu__expansion-item"
                                    >
                                      <Link
                                        className="ef-secondary-menu__expansion-item-link ef-menu__player"
                                        to={p.full_slug}
                                      >
                                        <span className="ef-menu__player-number">
                                          {p.jersey_number}
                                        </span>
                                        <span className="ef-menu__player-name">
                                          {p.title}
                                        </span>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )
                          })
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}

              {/* <div className="ef-menu__players">
              <div className="ef-slider ef-slider--left ef-player-slider--small">
                <div className="ef-slide">
                  <a className="ef-player-card" href="#">
                    <div className="ef-media ef-player-card__media">
                      <div
                        className="ef-player-card__background"
                        style={{
                          backgroundImage:
                            "url(https://cdn-assets-cloud.frontify.com/local/frontify/h_lNxVXLqrDqb2kyrixW3lMmUl7n-aBRzJUzyvzD7_9rjdT9DYEAkXyqeyjhgjSLG4FOJvKIoK9Ms6akgYgMHs3rsQ-ulhRKpFiA-nXJ18LskRfpmo9NTivlKm2s23DA)",
                        }}
                      />
                      <div className="ef-player-card__branding-back" />
                      <img
                        className="ef-media__image ef-player-card__image"
                        srcSet="https://cdn-assets-cloud.frontify.com/local/frontify/h_lNxVXLqrDqb2kyrixW3lMmUl7n-aBRzJUzyvzD7_842EX6HgD8SuWRkTolhwH-LnCocy7rMGrqexnwpT-0KNTD55VVHhjA5G1ORRVWz0rNBR23YaO-Hv9fNLBq2-RU 320w, https://cdn-assets-cloud.frontify.com/local/frontify/h_lNxVXLqrDqb2kyrixW3lMmUl7n-aBRzJUzyvzD7_842EX6HgD8SuWRkTolhwH-LnCocy7rMGrqexnwpT-0KNTD55VVHhjA5G1ORRVWz0rNBR23YaO-Hv9fNLBq2-RU 167w, https://cdn-assets-cloud.frontify.com/local/frontify/h_lNxVXLqrDqb2kyrixW3lMmUl7n-aBRzJUzyvzD7_842EX6HgD8SuWRkTolhwH-LnCocy7rMGrqexnwpT-0KNTD55VVHhjA5G1ORRVWz0rNBR23YaO-Hv9fNLBq2-RU 140w"
                        sizes="(min-width: 1440px) 16vw, (min-width: 600px) 25vw, 50vw"
                        src="https://cdn-assets-cloud.frontify.com/local/frontify/h_lNxVXLqrDqb2kyrixW3lMmUl7n-aBRzJUzyvzD7_842EX6HgD8SuWRkTolhwH-LnCocy7rMGrqexnwpT-0KNTD55VVHhjA5G1ORRVWz0rNBR23YaO-Hv9fNLBq2-RU"
                        alt
                      />
                      <div className="ef-player-card__branding-front" />
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
            </li>
          ))}
        </div>
      </ul>
    </section>
  )
}

export default OMenuSecondary
