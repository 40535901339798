import React, { useContext } from "react"
import stadiumAdBackdropTop from "../../images/backdrop-top.jpg"
import stadiumAdBackdropBottom from "../../images/backdrop-bottom.jpg"
import ABanner from "../atoms/a-banner"
import { blockAdMapping } from "../../../config/website-hosts/mapping"
import { LayoutContext } from "./o-layout"

const OStadiumAdBlock = props => {
  let { type } = props

  const layoutContext = useContext(LayoutContext)
  const showAds = layoutContext.showAds

  const adType = type || "stadium_ad_block"

  const blockAdMap = blockAdMapping
  const ad = blockAdMap.find(obj => obj.type === adType)
  const ad_id_left = ad ? ad.ad_code_left : null
  const ad_id_center = ad ? ad.ad_code_main : null
  const ad_id_right = ad ? ad.ad_code_right : null

  if (!showAds) {
    return <></>
  }

  return (
    <div className="ef-stadium-ad">
      <div className="ef-stadium-ad__backdrop ef-stadium-ad__backdrop--top">
        <img
          className="ef-stadium-ad__backdrop-image"
          src={stadiumAdBackdropTop}
          alt="Stadium Ad Backdrop Top"
        />
      </div>
      <div className="ef-container ef-container--l">
        <div className="ef-stadium-ad__banners">
          <div className="ef-stadium-ad__banner ef-stadium-ad__banner--left">
            <div className="ef-stadium-ad__banner-wrapper">
              <div className="ef-stadium-ad__banner-container">
                {ad_id_left && showAds && <ABanner id={ad_id_left} />}
              </div>
            </div>
          </div>
          <div
            className="ef-stadium-ad__banner ef-stadium-ad__banner--main"
            data-label="Partner"
          >
            <div className="ef-stadium-ad__banner-wrapper">
              <div className="ef-stadium-ad__banner-container">
                {ad_id_center && showAds && <ABanner id={ad_id_center} />}
              </div>
            </div>
          </div>
          <div
            className="ef-stadium-ad__banner ef-stadium-ad__banner--right"
            data-label="Partner"
          >
            <div className="ef-stadium-ad__banner-wrapper">
              <div className="ef-stadium-ad__banner-container">
                {ad_id_right && showAds && <ABanner id={ad_id_right} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ef-stadium-ad__backdrop ef-stadium-ad__backdrop--bottom">
        <img
          className="ef-stadium-ad__backdrop-image"
          src={stadiumAdBackdropBottom}
          alt="Stadium Ad Backdrop Bottom"
        />
      </div>
    </div>
  )
}

export default OStadiumAdBlock
