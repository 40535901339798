import React from "react"
import AHeading from "../atoms/a-heading"
import AImage from "../atoms/a-image"

const OQuotesBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--quotes">
      <div className="ef-container ef-container--l">
        <div className="ef-quotes">
          <AHeading
            headline_level="h3"
            headline={props.data.headline}
            add_class="ef-quotes-heading"
          />
          <div className="ef-quotes__media-wrapper">
            <div className="ef-media ef-quotes__media">
              <AImage
                image={props.data.image}
                image_size="card_s"
                aspectRatio="3:4"
                add_class="ef-quotes__image"
              />
            </div>
          </div>
          <div className="ef-quotes__text">
            <section className="ef-quotes__block ef-quotes__block--blockquote">
              <div className="ef-container ef-container--l">
                <div className="ef-container__narrow-s">
                  <blockquote className="ef-blockquote ef-blockquote--s">
                    <p className="ef-blockquote__quote">{props.data.quote}</p>
                    <cite className="ef-blockquote__cite">
                      {props.data.quote_source}
                    </cite>
                  </blockquote>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OQuotesBlock
