import React from "react"
import AMatchHeaderExpansionToggle from "../atoms/a-match-header-expansion-toogle"
import OGoals from "../organisms/o-goals"
import { period } from "../../utilities/utilities"
import OTimelineBlock from "./o-timeline-block"

const OMatchHeaderExpansion = props => {
  const liveMatchData = props.matchData
  const matchIsLive = props.matchIsLive
  return (
    <>
      <section className="ef-match-header__expansion">
        <div className="ef-container ef-container--l">
          <OGoals match={liveMatchData} />
          {(liveMatchData.period === period.FULL_TIME || matchIsLive) && (
            <OTimelineBlock match={liveMatchData} />
          )}
        </div>
      </section>
      {(liveMatchData.period === period.FULL_TIME || matchIsLive) && (
        <AMatchHeaderExpansionToggle
          setIsOpen={props.setIsOpen}
          isOpen={props.isOpen}
        />
      )}
    </>
  )
}

export default OMatchHeaderExpansion
