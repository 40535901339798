import React from "react"

import { concatStrings } from "../../utilities/utilities"

const OInfoBoxGridBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--info-box-grid">
      <div className="ef-container ef-container--l">
        <div className="ef-grid ef-grid--2">
          <div className="ef-grid__item">
            <div
              className={concatStrings(
                "ef-info-box",
                props.data.text_centered === 1 && "ef-info-box--center"
              )}
            >
              <div
                className="ef-copy-block"
                dangerouslySetInnerHTML={{ __html: props.data.text_left }}
              ></div>
            </div>
          </div>
          <div className="ef-grid__item">
            <div
              className={concatStrings(
                "ef-info-box",
                props.data.text_centered === 1 && "ef-info-box--center"
              )}
            >
              <div
                className="ef-copy-block"
                dangerouslySetInnerHTML={{ __html: props.data.text_right }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OInfoBoxGridBlock
