import React from "react"

const AStatTableBodyCell = ({ classSuffix, dataName, value }) => {
  return (
    <div className={"ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell" + classSuffix} dataName={dataName}>
      {value}
    </div>
  )
}

export default AStatTableBodyCell
