import React from "react"
import ALink from "../atoms/a-link"

const ODownloadBlock = props => {
  return (
    <section className="ef-content__block ef-content__block--downloads">
      <div className="ef-container ef-container--l">
        <h6 className="ef-kicker ef-text--center">{props.data.headline}</h6>
        <ul className="ef-downloads">
          {props.data.download_items.map((item, index) => (
            <li className="ef-download" key={index}>
              <ALink
                className="ef-text--link ef-download__link"
                link={item.file.src}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.file.title}
              </ALink>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default ODownloadBlock
