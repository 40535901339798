import { matchLength, period, eventType } from "./utilities"

const LiveTickerService = {
  groupByTeam: function(elements, orderKey) {
    return elements.reduce(
      (r, event) => ({
        ...r,
        [event[orderKey]]: [...(r[event[orderKey]] || []), event],
      }),
      {}
    )
  },
  groupByTeamAndNormalizeTime: function(elements) {
    if (!elements) {
      return []
    }
    return elements.reduce((r, event) => {
      let timeKey = event.time

      if (
        event.period === period.FIRST_HALF &&
        event.time > matchLength.HALF_TIME
      ) {
        timeKey = matchLength.HALF_TIME
      }
      if (
        event.period === period.SECOND_HALF &&
        event.time > matchLength.FULL_TIME
      ) {
        timeKey = matchLength.FULL_TIME
      }
      if (
        event.period === period.EXTRA_FIRST_HALF &&
        event.time > matchLength.EXTRA_HALF_TIME
      ) {
        timeKey = matchLength.EXTRA_HALF_TIME
      }
      if (
        event.period === period.EXTRA_SECOND_HALF &&
        event.time > matchLength.EXTRA_FULL_TIME
      ) {
        timeKey = matchLength.EXTRA_FULL_TIME
      }

      r[timeKey] = [...(r[timeKey] || []), event]
      return r
    }, {})
  },
  calculateProgressByTime: function(event) {
    let time = event.time
    if (
      event.period === period.FIRST_HALF &&
      event.time > matchLength.HALF_TIME
    ) {
      let extraTime = event.time % matchLength.HALF_TIME
      time = matchLength.HALF_TIME + "+" + extraTime
    }
    if (
      event.period === period.SECOND_HALF &&
      event.time > matchLength.FULL_TIME
    ) {
      let extraTime = event.time % matchLength.FULL_TIME
      time = matchLength.FULL_TIME + "+" + extraTime
    }
    if (
      event.period === period.EXTRA_FIRST_HALF &&
      event.time > matchLength.EXTRA_HALF_TIME
    ) {
      let extraTime = event.time % matchLength.EXTRA_HALF_TIME
      time = matchLength.EXTRA_HALF_TIME + "+" + extraTime
    }
    if (
      event.period === period.EXTRA_SECOND_HALF &&
      event.time > matchLength.EXTRA_FULL_TIME
    ) {
      let extraTime = event.time % matchLength.EXTRA_FULL_TIME
      time = matchLength.EXTRA_FULL_TIME + "+" + extraTime
    }
    return time
  },
  mapEventToIcon: function(event) {
    return (
      "#ef-icon--" +
      (event.event_type === eventType.YELLOW_CARD ? "yellow-card" : "") +
      (event.event_type === eventType.RED_CARD ? "red-card" : "") +
      (event.event_type === eventType.YELLOW_RED_CARD
        ? "2nd-yellow-card"
        : "") +
      (event.event_type === eventType.GOAL ? "soccer-ball" : "") +
      (event.event_type === eventType.SUBSTITUTION ? "substitution" : "") +
      "-16"
    )
  },
}

export default LiveTickerService
