import Axios from "axios"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import Helmet from "react-helmet"
import { concatStrings } from "../../utilities/utilities"
import OSearchResults from "../organisms/o-search-results"
import OHeader from "./o-header"
import { USER_STATUS } from "./o-header-account"
import OLogin from "./o-login"
import OMenu from "./o-menu"

const ONavigation = ({
  matchIsLive,
  tree,
  isNewsListPage,
  website,
  lang,
  subnavigation,
  matchSubnavigation,
  breadcrumbs,
  liveMatchData,
  subnavigationDropdown1,
  subnavigationDropdown2,
}) => {
  const [visibleOverlayType, setVisibleOverlayType] = useState("")
  const [userMenuVisible, setUserMenuVisible] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [userData, setUserData] = useState({})
  const [cookies] = useCookies(["yum_jwt"])

  const [isFrozen, setIsFrozen] = useState(false)

  useEffect(() => {
    Axios.get("https://sso-functions.eintracht.de/.netlify/functions/me", {
      headers: {
        Authorization: "Bearer " + cookies.yum_jwt,
      },
    })
      .then(function(response) {
        setUserData(response.data)
        if (response.data.status === USER_STATUS.ANONYM) {
          setLoggedIn(false)
        } else if (response.data.status === USER_STATUS.LOGGED_IN) {
          setLoggedIn(true)
        }
      })
      .catch(function(error) {
        console.error("Login Status kann nicht abgefragt haben")
        console.error("Error", error)
      })
  }, [])

  const toggleVisibleOverlayType = () => {
    if (visibleOverlayType === "") {
      setVisibleOverlayType("menu")
      setIsFrozen(true)
    }
    if (visibleOverlayType === "menu") {
      setVisibleOverlayType("")
      setIsFrozen(false)
    }
  }

  return (
    <>
      <Helmet>
        <body className={concatStrings(isFrozen && "--no-scroll")} />
      </Helmet>
      <div className="ef-navigation">
        <OHeader
          visibleOverlayType={visibleOverlayType}
          setVisibleOverlayType={setVisibleOverlayType}
          liveMatchData={liveMatchData}
          isNewsListPage={isNewsListPage}
          breadcrumbs={breadcrumbs}
          subnavigation={subnavigation}
          matchSubnavigation={matchSubnavigation}
          subnavigationDropdown1={subnavigationDropdown1}
          subnavigationDropdown2={subnavigationDropdown2}
          loggedIn={loggedIn}
          userData={userData}
          userMenuVisible={userMenuVisible}
          setUserMenuVisible={setUserMenuVisible}
          website={website}
        />
        <div
          className={
            visibleOverlayType !== "menu"
              ? "ef-hamburger ef-header__hamburger"
              : "ef-hamburger ef-header__hamburger --is-active"
          }
          onClick={() => toggleVisibleOverlayType()}
        >
          <div className="ef-hamburger__icon">
            <div className="ef-hamburger__icon-line" />
          </div>
        </div>
        {visibleOverlayType === "menu" && (
          <OMenu
            tree={tree}
            website={website}
            lang={lang}
            visibleOverlayType={visibleOverlayType}
            setVisibleOverlayType={setVisibleOverlayType}
            toggleVisibleOverlayType={toggleVisibleOverlayType}
          />
        )}
        {loggedIn && (
          <OLogin
            loggedIn={loggedIn}
            userData={userData}
            userMenuVisible={userMenuVisible}
            setUserMenuVisible={setUserMenuVisible}
            visibleOverlayType={visibleOverlayType}
            setVisibleOverlayType={setVisibleOverlayType}
          />
        )}
        {visibleOverlayType === "search" && <OSearchResults />}
      </div>
    </>
  )
}

export default ONavigation
