import MArticleCard from "./molecules/m-article-card"
import MProductCard from "./molecules/m-product-card"
import MGalleryCard from "./molecules/m-gallery-card"
import MVideoCard from "./molecules/m-video-card"
import MNewsflashHeadlineCard from "./molecules/m-newsflash-headline-card"
import MNewsflashKickerHeadlineCard from "./molecules/m-newsflash-kicker-headline-card"
import MNewsflashHeadlineSublineCard from "./molecules/m-newsflash-headline-subline-card"
import MNewsflashKickerHeadlineSublineCard from "./molecules/m-newsflash-kicker-headline-subline-card"
import MNewsflashImageHeadlineCard from "./molecules/m-newsflash-image-headline-card"
import MNewsflashImageKickerHeadlineCard from "./molecules/m-newsflash-image-kicker-headline-card"
import MTeaserCard from "./molecules/m-teaser-card"
import MMagazineCard from "./molecules/m-magazine-card"
import MNewsflashKickerHeadlineExternalCard from "./molecules/m-newsflash-kicker-headline-card-external"

export default {
  article_card: MArticleCard,
  product_card: MProductCard,
  gallery_card: MGalleryCard,
  video_card: MVideoCard,
  teaser_card: MTeaserCard,
  headline_card: MNewsflashHeadlineCard,
  kicker_headline_card: MNewsflashKickerHeadlineCard,
  headline_subline_card: MNewsflashHeadlineSublineCard,
  kicker_headline_subline_card: MNewsflashKickerHeadlineSublineCard,
  image_headline_card: MNewsflashImageHeadlineCard,
  image_kicker_headline_card: MNewsflashImageKickerHeadlineCard,
  kicker_headline_external_card: MNewsflashKickerHeadlineExternalCard,
  magazine_card: MMagazineCard,
}
