import React, { useState, useContext } from "react"
import Modal from "react-modal"
import AImage from "../atoms/a-image"
import ALink from "../atoms/a-link"

import Axios from "axios"
import { ScriptContext, ScriptServices } from "../technical/t-script-tags"
import { useCookies } from "react-cookie"
import { SESSION_URL } from "./../molecules/m-video-detail-header"

const OFullscreenVideoHeroBlock = props => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const scriptContext = useContext(ScriptContext)

  const [cookies] = useCookies(["yum_jwt"])
  // const [authenticated, setAuthenticated] = useState(false)

  const openModal = e => {
    e.preventDefault()
    setIsOpen(true)
  }

  const closeModal = e => {
    e.preventDefault()
    setIsOpen(false)
  }

  const afterOpenModal = () => {
    const [successPlayer, errorPlayer] = scriptContext[ScriptServices.AVP]

    if (props.item.videoUID && successPlayer && !errorPlayer) {
      let headers = null
      if (cookies && cookies.yum_jwt) {
        headers = {
          headers: {
            Authorization: "Bearer " + cookies.yum_jwt,
          },
        }
        // setAuthenticated(true)
      }

      let config = {
        id: "playercontainer2",
        configUrl: `https://eintracht-tv.spott2.sportradar.com/api/v2/content/${props.item.videoUID}/player-setting?portal=at&autoplay=true&enableProgressBar=true&enableTime=true&enableSeekForward=true&enableSeekBehind=true&language=de&showTitle=true`,
        allowDVR: true,
      }

      Axios.post(SESSION_URL, null, headers)
        .then(function(response) {
          const data = response.data
          const desiredEndTime = data.desired_end_time

          if (data.heartbeat === true) {
            config.heartbeat = {
              enabled: true,
              time: 300,
              url: data.heartbeat_url,
              method: "GET",
            }
          }

          // authorization for registered users
          if (headers) {
            config.streamAccessQueryParameters = `authorization_code=${data.jwt_token}`
          }

          // 30 seconds preview for anonymous
          if (desiredEndTime) {
            // desiredEndTime for pay-videos, only
            if( props.item.payment === "pay" ) {
              config.desiredEndTime = desiredEndTime
            }
            config.streamAccessQueryParameters = `authorization_code=${data.jwt_token}`
          }

          //eslint-disable-next-line no-undef
          new avvpl.setupPlayer(config)
        })
        .catch(error => {
          console.log("error", error)
        })
    }
  }

  return (
    <>
      <header className="ef-fullscreen-hero">
        <div className="ef-fullscreen-hero__media">
          {props.data.image && (
            <AImage
              add_class="ef-fullscreen-hero__image"
              image_size="l"
              image={props.data.image}
              aspectRatio="16:7"
            />
          )}
        </div>
        <div className="ef-fullscreen-hero__content">
          {props.data.main_link && (
            <ALink
              className="ef-fullscreen-hero__main-link"
              link={props.data.main_link ? props.data.main_link : ""}
            ></ALink>
          )}
          <div className="ef-fullscreen-hero__title">
            <h1 className="ef-heading ef-heading--1 ef-heading--has-kicker ef-heading--on-image ef-fullscreen-hero__heading">
              {props.data.kicker && (
                <div className="ef-kicker ef-fullscreen-hero__kicker">
                  {props.data.kicker}
                </div>
              )}
              {props.data.headline && (
                <div className="ef-fullscreen-hero__heading-text">
                  {props.data.headline}
                </div>
              )}
            </h1>
          </div>
          <div className="ef-fullscreen-hero__text">
            {props.data.intro && (
              <p className="ef-paragraph ef-paragraph--lead ef-fullscreen-hero__paragraph">
                {props.data.intro}
              </p>
            )}
            <a
              className="ef-fullscreen-hero__play"
              href="#"
              onClick={e => openModal(e)}
            >
              <svg className="ef-icon" width="64" height="64">
                <use
                  href="#ef-icon--play-64"
                  xlinkHref="#ef-icon--play-64"
                ></use>
              </svg>
            </a>
            {props.data.links && (
              <div className="ef-link-buttons">
                {props.data.links.map((item, index) => (
                  <ALink
                    className="ef-link-button ef-link-button--more ef-fullscreen-hero__link-button"
                    link={item.link}
                    key={index}
                  >
                    {item.link_text}
                  </ALink>
                ))}
              </div>
            )}
          </div>
        </div>
      </header>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        className="ef-gallery-modal"
        overlayClassName="ef-gallery-modal"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.75)",
          },
          content: {
            backgroundColor: "transparent",
          },
        }}
      >
        <div className="ef-gallery-modal__close" onClick={e => closeModal(e)}>
          <svg className="ef-icon" width="16" height="16">
            <use href="#ef-icon--x-16" xlinkHref="#ef-icon--x-16"></use>
          </svg>
        </div>
        <div className="ef-gallery-modal__inner">
          <div className="ef-container ef-container--l">
            <div className="ef-media ef-media--16x9">
              <div id="playercontainer2"></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default OFullscreenVideoHeroBlock
