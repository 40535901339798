import React, { createContext, useEffect } from "react"
import { Helmet } from "react-helmet/es/Helmet"
import useScript from "../../hooks/useScript"

// enum
export const ScriptServices = {
  SAS: "SAS", // smart ad server
  AVP: "AVP", // sportradar video player
}

let formats = [
  { id: 88118 },
  { id: 90875 },
  { id: 59836 },
  { id: 88124 },
  { id: 27457 },
  { id: 88120 },
  { id: 90625 },
  { id: 90627 },
  { id: 90626 },
  { id: 90614 },
  { id: 88123 },
  { id: 90615 },
  { id: 90622 },
  { id: 90623 },
  { id: 90624 },
  { id: 90628 },
  { id: 90630 },
  { id: 90629 },
  { id: 90632 },
  { id: 90633 },
  { id: 90631 },
  { id: 73760 },
  { id: 82556 },
  { id: 73759 },
  { id: 73761 },
  { id: 88119 },
  { id: 88301 },
]

const SCRIPTS = Object.create({})
SCRIPTS[ScriptServices.SAS] = "https://ced.sascdn.com/tag/1023/smart.js"
SCRIPTS[ScriptServices.AVP] =
  "https://avplayer-cdn.sportradar.com/dist/latest/avvpl-player.js"

export const ScriptContext = createContext(null)

let initialized = false
const initialSASCode = () => {
  initialized = true
  const sas = window.sas || {}
  sas.cmd = sas.cmd || []
  sas.cmd.push(function() {
    sas.setup({
      networkid: 1023,
      domain: "https://www3.smartadserver.com",
      async: true,
    })
  })
  sas.cmd.push(function() {
    sas.call("onecall", {
      siteId: 340796,
      pageId: 1192254,
      formats: formats,
      target: "",
    })
  })
  window.sas = sas
}

const TScriptTags = ({ children }) => {
  const loaded = {}
  loaded[ScriptServices.SAS] = useScript(SCRIPTS[ScriptServices.SAS])
  loaded[ScriptServices.AVP] = useScript(SCRIPTS[ScriptServices.AVP])

  useEffect(() => {
    // since this is rerendering multiple times we need to manage
    // to run the following code only once initially
    if (initialized) return

    // SAS
    initialSASCode()
  }, [])

  return (
    <ScriptContext.Provider value={loaded}>
      <Helmet>
        {Object.keys(SCRIPTS).forEach(key => {
          return (
            <script
              type="application/javascript"
              async={true}
              src={SCRIPTS[key]}
            />
          )
        })}
      </Helmet>
      {children}
    </ScriptContext.Provider>
  )
}

export default TScriptTags
