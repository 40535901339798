import React from "react"
import { Link } from "gatsby"

const SEPERATOR = "..."

function pagination(c, m) {
  var current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push(SEPERATOR)
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

const OCustomPagination = ({
  currentPage,
  numPages,
  isFirst,
  isLast,
  prevPage,
  nextPage,
  currentPath,
}) => {
  const paginationPages = pagination(currentPage, numPages)

  return (
    <section className="ef-content__block ef-content__block--pagination">
      <div className="ef-pagination">
        <Link
          className={`ef-pagination__arrow ${isFirst ? "--is-disabled" : ""}`}
          to={prevPage}
        >
          <svg
            className="ef-icon ef-icon--front ef-pagination__icon"
            width="16"
            height="16"
          >
            <use xlinkHref="#ef-icon--chevron-left-16" />
          </svg>
        </Link>

        <div className="ef-pagination__pages">
          {paginationPages.map((value, index) => {
            if (value === SEPERATOR) {
              return <span key={index}>{SEPERATOR}</span>
            } else {
              return (
                <Link
                  key={index}
                  className={`ef-pagination__page ${
                    currentPage === value ? "--is-current" : ""
                  }`}
                  to={`${currentPath}${value === 1 ? "" : value + "/"}`}
                >
                  {value}
                </Link>
              )
            }
          })}
        </div>

        <Link
          className={`ef-pagination__arrow ${isLast ? "--is-disabled" : ""}`}
          to={nextPage}
        >
          <svg
            className="ef-icon ef-icon--front ef-pagination__icon"
            width="16"
            height="16"
          >
            <use xlinkHref="#ef-icon--chevron-right-16" />
          </svg>
        </Link>
      </div>
    </section>
  )
}

export default OCustomPagination
