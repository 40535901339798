import React from "react"

const OLoggedInMenu = props => {
  const userData = props.userData || {}

  return (
    <div className="ef-account --is-active" data-theme="light">
      <div
        className="ef-account__close"
        onClick={() => props.setUserMenuVisible(!props.userMenuVisible)}
      >
        <svg className="ef-icon" width="16" height="16">
          <use href="#ef-icon--x-16" xlinkHref="#ef-icon--x-16"></use>
        </svg>
      </div>
      <div className="ef-account__content --is-active">
        <div className="ef-account__inner">
          <header className="ef-account__header">
            <h2 className="ef-heading ef-heading--2">
              Hallo, {userData.title}
            </h2>
          </header>
          <div className="ef-account__section">
            <h6 className="ef-heading ef-heading--6">
              Benutzer{" "}
              <span className="ef-text--regular">(im Online-Shop)</span>
            </h6>
            <div className="ef-card-grid ef-account__grid">
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card" href={userData.account_url}>
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="48" height="48">
                      <use
                        href="#ef-icon--account-48"
                        xlinkHref="#ef-icon--account-48"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Konto</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card">
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="48" height="48">
                      <use
                        href="#ef-icon--order-48"
                        xlinkHref="#ef-icon--order-48"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Bestellungen</div>
                </a>
              </div>
            </div>
          </div>
          <div className="ef-account__section">
            <h6 className="ef-heading ef-heading--6">Forum</h6>
            <div className="ef-card-grid ef-account__grid">
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card" href={userData.messages_url}>
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--mail-24"
                        xlinkHref="#ef-icon--mail-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Nachrichten</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card" href={userData.notices_url}>
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--chat-24"
                        xlinkHref="#ef-icon--chat-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Gästebuch</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card" href={userData.friendships_url}>
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--users-wm-24"
                        xlinkHref="#ef-icon--users-wm-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Freundschaften</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card" href={userData.profile_url}>
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--profile-24"
                        xlinkHref="#ef-icon--profile-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Profil</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card" href={userData.settings_url}>
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--settings-24"
                        xlinkHref="#ef-icon--settings-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Einstellungen</div>
                </a>
              </div>
            </div>
          </div>
          <div className="ef-account__section">
            <h6 className="ef-heading ef-heading--6">Moderation</h6>
            <div className="ef-card-grid ef-account__grid">
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card">
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--alert-24"
                        xlinkHref="#ef-icon--alert-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Böse Beiträge</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card ef-icon-card--s">
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--members-24"
                        xlinkHref="#ef-icon--members-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Mitglieder</div>
                </a>
              </div>
              <div className="ef-card-grid__item ef-account__grid-item">
                <a className="ef-icon-card">
                  <div className="ef-icon-card__icon">
                    <svg className="ef-icon" width="24" height="24">
                      <use
                        href="#ef-icon--folder-24"
                        xlinkHref="#ef-icon--folder-24"
                      ></use>
                    </svg>
                  </div>
                  <div className="ef-icon-card__text">Dateien</div>
                </a>
              </div>
            </div>
          </div>
          <div className="ef-account__section">
            <a href={userData.logout_url}>
              <button className="ef-button ef-button--secondary ef-button--small">
                Abmelden
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OLoggedInMenu
